import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { API_ROOT } from '../../config/config';

class Contact extends Component {

  state = {
    submitClicked: false, // submit button clicked at least once?
    formValid: false, // true if the form has been submitted
    submitSucceeded: false, // true if post to backend was successful

    email: "",
    message: "",
    consent: false,

    errors: {
      email: null,
      message: null,
      consent: null
    }
  }

  validateInputs = (state) => {
    // Returns a dictionary with booleans for each input variable.
    // In case of an error, the corresponding variable is true.
    const errors = {};
    errors.count = 0;

    if ("email" in state) {
      var emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!emailPattern.test(state.email)) {
        errors.email = true;
        errors.count += 1;
      }
    }

    if ("message" in state) {
      if (!state.message) {
        errors.message = true;
        errors.count += 1;
      }
    }

    if ("consent" in state) {
      if (!state.consent) {
        errors.consent = true;
        errors.count += 1;
      }
    }

    return errors;
  }

  handleChange = (e) => {
    const newState = {};
    newState.formValid = false; // form has changed
    newState.submitSucceeded = false; // form has changed

    // Checkbox value must be handled differently
    if (e.target.id === "consent") {
      newState[e.target.id] = e.target.checked;
    }
    else if (e.target.id === "email") {
      newState[e.target.id] = e.target.value.trim();
    }
    else {
      newState[e.target.id] = e.target.value;
    }

    // If the submit button has been clicked at least once but there were errors,
    // reevaluate the new inputs for potential errors.
    if (this.state.submitClicked) {
      const newErrors = this.validateInputs({...this.state, ...newState});
      newState.errors = newErrors;
    }

    this.setState(newState);
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = this.validateInputs(this.state);
    if (newErrors.count === 0) {
      // Call backend api
      axios.post(API_ROOT + '/contact/submit', {
        email: this.state.email,
        subject: this.props.subject ? this.props.subject : "",
        message: this.state.message,
        consent: this.state.consent
      })
      .then((res) => {
        // Reset form
        this.setState({
          submitClicked: false, // reset state because the form is resetted to a fresh state
          formValid: true,
          submitSucceeded: true,

          email: "",
          message: "",
          consent: false
        })
      })
      .catch((err) => {
        console.log("Error:", err)
        this.setState({
          submitClicked: true,
          formValid: true,
          submitSucceeded: false,
        })
      });
    }
    else {
      console.log("There were errors.", newErrors)
      this.setState({
        submitClicked: true,
        formValid: false,
        submitSucceeded: false,

        errors: newErrors
      })
    }


  }

  // TODO: input validation

  // TODO: backend integration

  render() {
    return (
      <div>
        <form action="" onSubmit={this.handleSubmit}>

          <div className="input-field">
            <i className="material-icons prefix">email</i>
            <input id="email" type="text" value={this.state.email} onChange={this.handleChange} />
            <label htmlFor="email">E-Mail</label>
            { this.state.errors.email ? <span className="helper-text" style={{color: "red"}}>Bitte überprüfen Sie Ihre E-Mail Adresse.</span> : null }
          </div>

          <div className="input-field">
            <i className="material-icons prefix">message</i>
            <textarea className="materialize-textarea" id="message" value={this.state.message} style={{height: "70px"}} onChange={this.handleChange} ></textarea>
            <label htmlFor="message">Ihre Nachricht</label>
            { this.state.errors.message ? <span className="helper-text" style={{color: "red"}}>Bitte fügen Sie Ihre Nachricht ein.</span> : null }
          </div>

          <div className="input-field">
            <p>
              <label>
                <input id="consent" type="checkbox" checked={this.state.consent} onChange={this.handleChange} />
                <span>Ich stimme den <Link to="/datenschutz" target="_blank">Datenschutzbestimmungen</Link> zu.</span>
                { this.state.errors.consent ? <span className="helper-text" style={{color: "red"}}>Bitte bestätigen Sie, dass Sie mit unseren Datenschutzbestimmungen einverstanden sind.</span> : null }
              </label>
            </p>

          </div>

          <div className="input-field center">
            <button className="btn blue darken-1">Senden</button>
            { this.state.formValid && this.state.submitSucceeded ? <h4 style={{color: "green"}}>Vielen Dank für Ihre Nachricht!</h4> : null }
          </div>
          <div className="center">
            { this.state.submitClicked && this.state.formValid && !this.state.submitSucceeded ? <p className="text-floating" style={{color: "red"}}>Es gab einen Fehler bei der Übermittlung. Bitte versuchen Sie es noch einmal.</p> : null }
          </div>

        </form>
      </div>
    )
  }
}

export default Contact;
