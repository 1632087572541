import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import M from 'materialize-css';

import Spacer from './Spacer';
import InsightsEvalForm from './Insights/InsightsEvalForm';
import NewsletterSignupForm from './Newsletter/NewsletterSignupForm';

import BuildingsImage from '../assets/images/architecture-846087_1920_blue_cropped.jpg'
//import BuildingsImage from '../assets/images/architecture-846087_1920_blue.jpg'
import ImageFirstCard from '../assets/images/money-2180330_640_white-min.jpg'
import ImageSecondCard from '../assets/images/architecture-846087_640_white-min.jpg'
import ImageThirdCard from '../assets/images/pexels-pixabay-280221_640_white-min.jpg'

const headerStyle = {
  width: "100%",
  height: "100%",
  backgroundImage: "url(" + BuildingsImage + ")",
}

const darkOverlay = {
  //backgroundColor: "rgba(0, 0, 0, 0.5)",
  //backgroundColor: "rgba(123, 123, 123, 0.4)",
  //backgroundColor: "rgba(30, 136, 229, 0.4)",
  //backgroundColor: "rgba(255, 255, 255, 0.7)",

  //backgroundColor: "rgba(0, 123, 255, 0.4)",
  position: "relative",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
}

const gradientOverlay = {
  //background: "linear-gradient(90deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.4) 50%, rgba(255,255,255,0.6) 100%)",

  background: "linear-gradient(90deg, rgba(0,0,0,0.3) 0%, rgba(123,123,123,0.3) 50%, rgba(255,255,255,0.3) 100%)",
  position: "relative",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
}

class Home extends Component {
  componentDidMount() {
    let elements = document.querySelectorAll('.parallax');
    M.Parallax.init(elements);

    // Activate scrollspy
    var elems = document.querySelectorAll('.scrollspy');
    M.ScrollSpy.init(elems);
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Smarte Investments in Immobilien durch Immobilienbewertungen | Immovestics</title>
          <meta name="description" content="Immobilienbewertung in wenigen Sekunden mit unseren Tools für smarte Immobilien Investments." />
        </Helmet>

        <div style={headerStyle}>
          <div style={darkOverlay}>
            <div style={gradientOverlay}>

              <div className="container">

                <div className="row">
                  <div className="col s12 center-align">
                    <h1 className="white-text">Immovestics</h1>
                    {/*
                    <h3 className="white-text">Immobilien. Investment. Analytics.</h3>
                    <h3 className="white-text">Immobilien Investments schnell und zuverlässig analysieren</h3>
                    <h3 className="white-text">Immobilien schnell und zuverlässig analysieren</h3>
                    <h3 className="white-text">Investments schnell und zuverlässig analysieren</h3>
                    */}
                    <h3 className="white-text hide-on-small-only">Immobilien Investments schnell und zuverlässig analysieren</h3>
                    <h5 className="white-text hide-on-med-and-up">Immobilien Investments schnell und zuverlässig analysieren</h5>
                  </div>
                </div>

                <div className="row">
                  <div className="col s12 center-align">
                    {/*
                    <a href="#newsletter" className="btn-large red" style={{ fontWeight: "bold" }}>Mehr erfahren</a>
                    */}
                    <a href="#insights" className="btn-large red" style={{ fontWeight: "bold" }}>Jetzt kostenlos testen</a>
                  </div>
                </div>

                <Spacer height="80px" />

              </div>


            </div>
          </div>
        </div>



        <div className="container">
          <Spacer height="50px" />
          <Spacer height="50px" />
        </div>



        <section>
          <div className="center">
            <div className="row" style={{"margin":"0px"}}>
              <div className="col s12 m8 offset-m2 l8 offset-l2 xl4 offset-xl4">
              {/*<div className="col s12 m8 offset-m2 l8 offset-l2 xl6 offset-xl3">*/}
                <div className="insights-container white scrollspy" id="insights">
                  <InsightsEvalForm />
                </div>
              </div>
            </div>
          </div>
        </section>



        <div className="container">
          <Spacer height="50px" />
          <div className="divider"></div>
          <Spacer height="50px" />
        </div>
{/*
*/}

        <div className="container">
          <div className="row" >

            <div className="col s12 l4">
              <div className="card">
                <div className="card-image">
                  <img src={ImageFirstCard} alt=""/>
                </div>
                <div className="card-content">
                  <div className="card-title">Der Gewinn liegt im Einkauf</div>
                  <p className="flex-text">Diese alte Kaufmannsregel gilt insbesondere bei Immobilien, wo wenige Prozent bereits zehntausende Euro ausmachen können. Aus diesem Grund ist es besonders wichtig, einen Angebotspreis schnell bewerten zu können.</p>
                </div>
                <div className="card-action">
                  <a href="#newsletter">Mehr erfahren</a>
                </div>
              </div>
            </div>

            <div className="col s12 l4">
              <div className="card">
                <div className="card-image">
                  <img src={ImageSecondCard} alt=""/>
                </div>
                <div className="card-content">
                  <div className="card-title">Bewertung sofort und kostenlos</div>
                  <p className="flex-text">Sie erhalten das Bewertungsergebnis sofort nach Eingabe aller Daten, unverbindlich und kostenlos. Sie müssen uns keine persönlichen Daten zur Verfügung stellen. Und wir melden uns nur dann bei Ihnen, wenn Sie dies ausdrücklich wünschen.</p>
                </div>
                <div className="card-action">
                  <a href="#newsletter">Mehr erfahren</a>
                </div>
              </div>
            </div>

            <div className="col s12 l4">
              <div className="card">
                <div className="card-image">
                  <img src={ImageThirdCard} alt=""/>
                </div>
                <div className="card-content">
                  <div className="card-title">Lage, Lage, Lage ...</div>
                  <p className="flex-text">... und Baujahr, Ausstattung, Objektart, Stockwerk, Fahrstuhl, Energieverbrauch, Zustand und vieles mehr. Unsere Algorithmen wurden mit über einer Million Datensätzen trainiert und zeigen Ihnen sogar die Auswirkung der einzelnen Faktoren auf den Preis.</p>
                </div>
                <div className="card-action">
                  <a href="#newsletter">Mehr erfahren</a>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="container">
          <Spacer height="50px" />
          <div className="divider"></div>
          <Spacer height="50px" />
        </div>

        <section className="container section scrollspy" id="newsletter">
          <div className="row">
            <div className="col s12 l6 offset-l3 insights-container white" style={{"padding":"40px"}}>
              <h4>Nichts mehr verpassen!</h4>
              <p className="flex-text">Mit unserem Newsletter bleiben Sie immer auf dem Laufenden und erfahren als erstes, wenn wir neue Funktionen freigeschaltet haben.</p>
              <NewsletterSignupForm />
            </div>
          </div>
        </section>

        <div className="container">
          <Spacer height="50px" />
          <Spacer height="50px" />
        </div>

      </div>
    )
  }
}

export default Home;
