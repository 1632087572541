import Spacer from '../../components/Spacer';

function NewsletterThankYouPage(props) {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col s6 offset-s3">
            <div className="section">
              <div className="center">
                <Spacer height="100px" />
                <h4>Herzlich Willkommen!</h4>
                <p className="flex-text">Wir freuen uns, Sie mit unserem Newsletter immer auf dem Laufenden halten zu können.</p>
                <Spacer height="100px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsletterThankYouPage;
