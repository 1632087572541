import React from 'react'
import { Link } from 'react-router-dom';

import Spacer from '../../components/Spacer';



function LoginErrorPage() {

  return (
    <div>
      <div className="container">
        <Spacer height="50px" />
      </div>

      <div className="container">
        <div className="row">
          <div className="col s12 l6 offset-l3 white">
            <div className="immovestics-container">
              <h4>Fehler bei der Anmeldung</h4>
              <div style={{"marginTop":"30px"}}></div>
              <p className="" style={{"fontSize":"1.2rem"}}>Es gab leider einen Fehler bei der Anmeldung. Bitte versuchen Sie es noch einmal. Falls die Probleme anhalten sollten, dann <Link to="/kontakt">kontaktieren</Link> Sie uns bitte.</p>
              <div className="center">
                <a href="/login" className="btn-large blue darken-1">Zur Anmeldung</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <Spacer height="50px" />
      </div>
    </div>
  )
};

export default LoginErrorPage;
