import React, { Component } from 'react'

import InsightsEvalFormHeader from './InsightsEvalFormHeader';
import InsightsEvalFormFooter from './InsightsEvalFormFooter';
import InsightsEvalFormNavigationButtons from './InsightsEvalFormNavigationButtons';

import PostalCodeInput from './UiElements/PostalCodeInput';


class InsightsPostalCodeForm extends Component {
  state = {
    submitButtonClicked: false,
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      submitButtonClicked: true
    })

    // Only check the errors of inputs that are collected on this page
    if (this.props.errors.postalCode !== true && this.props.analysis.status !== 2) {
      this.props.nextStep();
    }
  }


  render() {
    const analysis = this.props.analysis;
    const postalCodeError = this.props.errors.postalCode;

    return (
      <div>

        <InsightsEvalFormHeader />

        <div className="container">


          <div className="row">
            <div className="col s12">
              <h5>Postleitzahl</h5>
            </div>
          </div>

          <div className="insights-eval-form-content">

            <div className="row">
              <div className="col s12">
                <PostalCodeInput
                  handleChange={this.props.handleChange}
                  handleSubmit={this.handleSubmit}
                  values={this.props.values}
                />
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                { this.state.submitButtonClicked && postalCodeError ? <span className="helper-text" style={{color: "red"}}>Bitte geben Sie eine gültige Postleitzahl ein.</span> : null }
                { this.props.analysis.status === 2 ? <span className="helper-text" style={{color: "red"}}>Leider liegen uns für diese Postleitzahl keine Vergleichswerte vor.</span> : null }
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col s12">
              <InsightsEvalFormNavigationButtons
                previousStep={this.props.previousStep}
                skipToEnd={this.props.skipToEnd}
                handleSubmit={this.handleSubmit}
                analysis={analysis}
                deactivateSubmitButton={this.props.errors.postalCode === true || this.props.analysis.status !== 1}
                />
            </div>
          </div>

        </div>

        <div className="divider" />
        <div className="row">
          <div className="col s12">
            <InsightsEvalFormFooter
              previousStep={this.props.previousStep}
              skipToEnd={this.props.skipToEnd}
              handleSubmit={this.handleSubmit}
              analysis={analysis}
              placeholderText={"Nach der Postleitzahl erfolgt die erste Analyse."}
              deactivateSubmitButton={this.props.errors.postalCode === true || this.props.analysis.status !== 1}
              />
          </div>
        </div>

      </div>
    )
  }
}

export default InsightsPostalCodeForm
