import React, { Component } from 'react'

import InsightsEvalFormHeader from './InsightsEvalFormHeader';
import InsightsEvalFormFooter from './InsightsEvalFormFooter';
import InsightsEvalFormNavigationButtons from './InsightsEvalFormNavigationButtons';

import FeatureImpact from './UiElements/FeatureImpact';

class InsightsFeaturesInnerForm extends Component {
  state = {
    submitButtonClicked: false,
  }


  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      submitButtonClicked: true
    })

    // Allow the next step if there are either no errors or the (optional) field is empty
    if (this.props.errors.objectState !== true || this.props.values.objectState === '') {
      this.props.nextStep();
    }
  }


  handleChange = (name) => {
  }


  renderCard = (name, text) => {
    return (
        <div className="col s12 m6">
          <label>
            <input type="checkbox" name={name} value={name} id={name} checked={this.props.values[name] === 1} className="insights-card-input-element" onClick={this.handleChange} onChange={() => {}}/>
            <div className="card insights-card-input" style={{"minHeight":"70px"}}>
              <div className="card-content insights-card-content">
                {/*<div className="card-title insights-card-title-text">{text}</div>*/}
                <p style={{"fontWeight":"normal", "fontSize":"1.2rem"}}>{text}</p>
              </div>
              { this.props.values[name] === 1 ? (
                <FeatureImpact
                  impact={this.props.analysis.purchase_analysis[name].impact}
                />
              ) : (
                null
              )}
            </div>
          </label>
        </div>
    )
  }


  renderObjectStates = () => {
    return (
      <div>
        { this.renderCard("kitchen", "Einbauküche") }
        { this.renderCard("guest_toilet", "Gäste WC") }
        { this.renderCard("garden", "Garten") }
        { this.renderCard("balcony_or_terrace", "Balkon, Terrasse") }
        { this.renderCard("cellar", "Keller") }
        { this.renderCard("stepless_access", "Barrierefrei") }
        { this.renderCard("heritage_listed_object", "Erbpachtrecht") }
        { this.renderCard("granny_flat", "Einliegerwohnung") }
      </div>
    );
  }


  toggleBinaryFeature = (name, value) => {
    let newValue = "";
    if (this.props.values[name] !== "" && this.props.values[name] === value) {
      newValue = "";
    }
    else {
      newValue = value;
    }

    const syntheticEvent = {
      target: {
        id: name,
        value: newValue
      }
    }
    this.props.handleChange(syntheticEvent);
  }

  renderFeatureRow = (name, text) => {
    const noButtonStyle = this.props.values[name] === 0 ? " insights-binary-feature-button-selected" : "";
    const yesButtonStyle = this.props.values[name] === 1 ? " insights-binary-feature-button-selected" : "";

    return (
      <div className="row" style={{"marginTop":"10px", "marginBottom":"10px"}}>
        <div className="col s5 left">
          <span className="left" style={{"fontWeight":"normal", "fontSize":"1.2rem", "marginTop":"2px"}}>{text}</span>
        </div>
        <div className="col s5">
          <button className={"btn btn-small white grey-text insights-binary-feature-button right" + yesButtonStyle} onClick={() => this.toggleBinaryFeature(name, 1)}>Ja</button>
          <button className={"btn btn-small white grey-text insights-binary-feature-button right" + noButtonStyle} onClick={() => this.toggleBinaryFeature(name, 0)}>Nein</button>
        </div>
        <div className="col s2 right" style={{"marginTop":"2px"}}>
          <FeatureImpact
            impact={this.props.analysis.purchase_analysis[name].impact}
            showIconOnly={true}
          />
        </div>
      </div>
    )
  }
  renderFeatureRows = () => {
    return (
      <div>
        { this.renderFeatureRow("kitchen", "Küche") }
        <div className="divider" />
        { this.renderFeatureRow("guest_toilet", "Gäste WC") }
        <div className="divider" />
        { this.renderFeatureRow("cellar", "Keller") }
        <div className="divider" />
        { this.renderFeatureRow("stepless_access", "Barrierefrei") }
      </div>
    )
  }



  render() {
    //const { } = this.props.values;
    const analysis = this.props.analysis;
    //const objectStateError = this.props.errors.objectState;

    return (
      <div>

        <InsightsEvalFormHeader />

        <div className="container">

          <div className="row">
            <div className="col s12">
              <h5>Merkmale, innen</h5>
            </div>
          </div>

          <div className="insights-eval-form-content">

            <div className="row">
              {
                //this.renderObjectStates()
                this.renderFeatureRows()
              }
            </div>
          
          </div>

          <div className="row">
            <div className="col s12">
              <InsightsEvalFormNavigationButtons
                previousStep={this.props.previousStep}
                skipToEnd={this.props.skipToEnd}
                handleSubmit={this.handleSubmit}
                analysis={analysis}
                />
            </div>
          </div>

        </div>

        <div className="divider" />
        <div className="row">
          <div className="col s12">
            <InsightsEvalFormFooter
              previousStep={this.props.previousStep}
              skipToEnd={this.props.skipToEnd}
              handleSubmit={this.handleSubmit}
              analysis={analysis}
              />
          </div>
        </div>

      </div>
    )
  }
}

export default InsightsFeaturesInnerForm;
