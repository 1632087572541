import React from 'react'
import { useParams } from 'react-router-dom';

import PasswordReset from '../../components/User/PasswordReset'
import Spacer from '../../components/Spacer';

function PasswordResetPage(props) {
  // Get the url parameters
  const { userId, resetKey } = useParams();


  return (
    <div>
      <div className="container">
        <Spacer height="50px" />
      </div>

      <div className="container">
        <div className="row">
          <div className="col s12 l6 offset-l3 white">
            <div className="immovestics-container">
              <h4>Neues Passwort eingeben</h4>
              <p className="flex-text">Bitte geben Sie hier Ihr neues Passwort ein.</p>
              <div style={{"marginTop":"30px"}}>
                <PasswordReset userId={userId} resetKey={resetKey} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <Spacer height="50px" />
      </div>
    </div>
  )
};

export default PasswordResetPage;
