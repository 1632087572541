import React, { Component } from 'react'

import Textfit from '@namhong2001/react-textfit';
//import Textfit from "react-textfit/lib/Textfit";

import InsightsEvalFormHeader from './InsightsEvalFormHeader';
import InsightsEvalFormFooter from './InsightsEvalFormFooter';
import InsightsEvalFormNavigationButtons from './InsightsEvalFormNavigationButtons';

import InsightsDropdown from './UiElements/InsightsDropdown';
import FeatureImpact from './UiElements/FeatureImpact';

class InsightsHeatingTypeForm extends Component {
  state = {
    submitButtonClicked: false,
  }


  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      submitButtonClicked: true
    })

    // Allow the next step if there are either no errors or the (optional) field is empty
    if (this.props.errors.heating_type !== true || this.props.values.heating_type === '') {
      this.props.nextStep();
    }
  }


  handleChange = (e) => {
    // NOTE: DELETE in case the cards are not needed anymore

    // Check if the value is valid
    let value = null;
    if (!e?.target?.value) return;
    try {
      value = e.target.value;
      const errors = this.props.validateInput(value);
      if (errors.heating_type) return;
    }
    catch {
      return;
    }

    // Check if the value has already been selected and should now be unselected.
    if (String(value) === String(this.props.values.heating_type)) {
      value = "";
    }

    // The input is valid.
    // Create a synthetic event and emit it.
    const syntheticEvent = {
      target: {
        id: e.target.name,
        value: value
      }
    }
    this.props.handleChange(syntheticEvent);
  }


  renderCard = (value, text) => {
    return (
        <div className="col s6">
          <label>
            <input type="radio" name="heating_type" id={"heating_type_" + value} value={value} checked={this.props.values.heating_type === value} className="insights-card-input-element" onClick={this.handleChange} onChange={() => {}}/>
            <div className="card insights-card-input">
              <div className="card-content insights-card-content">
                <Textfit max={19} className="insights-card-text">{text}</Textfit>
              </div>
            </div>
          </label>
        </div>
    )
  }


  renderHeatingTypes = () => {
    return (
      <div>
        <div className="row insights-card-row">
          { this.renderCard("GAS", "Gasheizung") }
          { this.renderCard("ÖL", "Ölheizung") }
        </div>

        <div className="row insights-card-row">
          { this.renderCard("FERNWÄRME", "Fernwärme") }
          { this.renderCard("ELEKTRO", "Elektroheizung") }
        </div>

        <div className="row insights-card-row">
          { this.renderCard("SOLAR", "Solarheizung") }
          { this.renderCard("WÄRMEPUMPE", "Wärmepumpe") }
        </div>

        <div className="row insights-card-row">
          { this.renderCard("BLOCKHEIZKRAFTWERK", "Blockheizkraftwerk") }
          { this.renderCard("PELLETHEIZUNG", "Pelletheizung") }
        </div>


        {/*
        { this.renderCard("ETAGENHEIZUNG", "Etagenheizung") }
        { this.renderCard("ZENTRALHEIZUNG", "Zentralheizung") }
        { this.renderCard("OFENHEIZUNG", "Ofenheizung") }
        { this.renderCard("FUSSBODENHEIZUNG", "Fussbodenheizung") }
        { this.renderCard("NACHTSPEICHERÖFEN", "Nachtspeicheröfen") }
        */}
      </div>
    );
  }


  renderHeatingTypesDropdown = () => {
    const attributeId = "heating_type";
    const value = this.props.values.heating_type;
    const valuePairs = [
      { value: "", text: "Keine Angabe" },
      { value: "FERNWÄRME", text: "Fernwärme" },
      { value: "ETAGENHEIZUNG", text: "Etagenheizung" },
      { value: "GAS", text: "Gasheizung" },
      { value: "ZENTRALHEIZUNG", text: "Zentralheizung" },
      { value: "ELEKTRO", text: "Elektroheizung" },
      { value: "FUSSBODENHEIZUNG", text: "Fussbodenheizung" },
      { value: "BLOCKHEIZKRAFTWERK", text: "Blockheizkraftwerk" },
      { value: "WÄRMEPUMPE", text: "Wärmepumpe" },
      { value: "PELLETHEIZUNG", text: "Pelletheizung" },
      { value: "ÖL", text: "Ölheizung" },
      { value: "OFENHEIZUNG", text: "Ofenheizung" },
      { value: "NACHTSPEICHERÖFEN", text: "Nachtspeicheröfen" },
      { value: "SOLAR", text: "Solarheizung" },
    ];
    return (
      <InsightsDropdown
        attributeId={attributeId}
        valuePairs={valuePairs}
        value={value}
        handleChange={this.props.handleChange}
        validateInput={this.props.validateInput}
      />
    )
  }


  render() {
    //const { } = this.props.values;
    const analysis = this.props.analysis;

    return (
      <div>

        <InsightsEvalFormHeader />

        <div className="container">

          <div className="row">
            <div className="col s12">
              <h5>Heizungsart</h5>
            </div>
          </div>

          <div className="insights-eval-form-content">

            <div className="row">
              {
                this.renderHeatingTypes()
                //this.renderHeatingTypesDropdown()
              }
            </div>

            <div className="row">
              <div className="col s12">
                <FeatureImpact
                  impact={this.props.analysis.purchase_analysis.heating_type.impact}
                />
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col s12">
              <InsightsEvalFormNavigationButtons
                previousStep={this.props.previousStep}
                skipToEnd={this.props.skipToEnd}
                handleSubmit={this.handleSubmit}
                analysis={analysis}
                />
            </div>
          </div>

        </div>

        <div className="divider" />
        <div className="row">
          <div className="col s12">
            <InsightsEvalFormFooter
              previousStep={this.props.previousStep}
              skipToEnd={this.props.skipToEnd}
              handleSubmit={this.handleSubmit}
              analysis={analysis}
              />
          </div>
        </div>

      </div>
    )
  }
}

export default InsightsHeatingTypeForm;
