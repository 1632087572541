import React, { Component } from 'react'


class InsightsEvalFormNavigationButtons extends Component {

  render() {

    return (
      <div>
        <div>
          { this.props.hidePreviousStepButton ? null :
            (
              <div>
                <button type="button" className="btn left waves-effect white blue-text text-darken-1" onClick={() => this.props.previousStep(true)}><i className="material-icons">first_page</i></button>
                <button type="button" className="btn left waves-effect white blue-text text-darken-1" onClick={() => this.props.previousStep()} style={{"marginLeft":"10px"}}><i className="material-icons">keyboard_arrow_left</i></button>
                { this.props.hideSubmitButton ? null : // if the submit button is hidden, then so is the jump-to-last-page button
                  this.props.deactivateSubmitButton ?
                    <button type="button" className="btn-flat left grey lighten-2 white-text deactive" style={{"marginLeft":"10px"}}><i className="material-icons">last_page</i></button> :
                    <button type="button" className="btn left waves-effect white blue-text text-darken-1" onClick={this.props.skipToEnd} style={{"marginLeft":"10px"}}><i className="material-icons">last_page</i></button>
                }
              </div>
            )
          }
        </div>
        <div>
          {this.props.hideSubmitButton ? null : 
            this.props.deactivateSubmitButton ?
            <button type="button" className="btn-flat right grey lighten-2 white-text deactive">Weiter</button> :
            <div>
              <button type="button" className="btn right waves-effect blue darken-1" onClick={this.props.handleSubmit}>Weiter</button>
            </div>
          }
        </div>

      </div>
    )
  }
}

export default InsightsEvalFormNavigationButtons;
