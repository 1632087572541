import React from 'react'

import PasswordResetRequest from '../../components/User/PasswordResetRequest'
import Spacer from '../../components/Spacer';

function PasswordResetRequestPage(props) {
  return (
    <div>
      <div className="container">
        <Spacer height="50px" />
      </div>

      <div className="container">
        <div className="row">
          <div className="col s12 l6 offset-l3 white">
            <div className="immovestics-container">
              <h4>Passwort zurücksetzen</h4>
              <p className="flex-text">Bitte geben Sie Ihre E-Mail Adresse ein. Sie erhalten dann Informationen, wie Sie Ihr Passwort zurücksetzen können.</p>
              <div style={{"marginTop":"30px"}}>
                <PasswordResetRequest />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <Spacer height="50px" />
      </div>
    </div>
  )
};

export default PasswordResetRequestPage;
