import React, { Component } from 'react'

import InsightsEvalFormHeader from './InsightsEvalFormHeader';
import InsightsEvalFormFooter from './InsightsEvalFormFooter';
import InsightsEvalFormNavigationButtons from './InsightsEvalFormNavigationButtons';

import NRoomsSlider from './UiElements/NRoomsSlider';


class InsightsNRoomsForm extends Component {
  state = {
    submitButtonClicked: false,
  }


  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      submitButtonClicked: true
    })

    // Allow the next step if there are either no errors or the (optional) field is empty
    if (this.props.errors.nRooms !== true || this.props.values.nRooms === '') {
      this.props.nextStep();
    }
  }


  render() {
    const analysis = this.props.analysis;
    const nRoomsError = this.props.errors.nRooms;

    return (
      <div>

        <InsightsEvalFormHeader />

        <div className="container">

          <div className="row">
            <div className="col s12">
              <h5>Anzahl Zimmer</h5>
            </div>
          </div>

          <div className="insights-eval-form-content">

            <div className="row">
              <div className="col s10 offset-s1">
                <NRoomsSlider
                  handleChange={this.props.handleChange}
                  handleSubmit={this.handleSubmit}
                  values={this.props.values}
                  analysis={this.props.analysis}
                />
              </div>
              { this.state.submitButtonClicked && nRoomsError ? <div className="col s12"><span className="helper-text" style={{color: "red"}}>Bitte geben Sie eine gültige Zimmeranzahl ein.</span></div> : null }
            </div>

          </div>

            <div className="row">
              <div className="col s12">
                <InsightsEvalFormNavigationButtons
                  previousStep={this.props.previousStep}
                  skipToEnd={this.props.skipToEnd}
                  handleSubmit={this.handleSubmit}
                  analysis={analysis}
                  />
              </div>
            </div>

          </div>


          <div className="divider" />
          <div className="row">
            <div className="col s12">
              <InsightsEvalFormFooter
                previousStep={this.props.previousStep}
                skipToEnd={this.props.skipToEnd}
                handleSubmit={this.handleSubmit}
                analysis={analysis}
                />
            </div>
          </div>

      </div>
    )
  }
}

export default InsightsNRoomsForm;
