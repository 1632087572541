import React, { Component } from 'react';

import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { API_ROOT } from '../../config/config';

import InsightsObjectTypeForm from './InsightsObjectTypeForm';
import InsightsPostalCodeForm from './InsightsPostalCodeForm';
import InsightsObjectSubTypeForm from './InsightsObjectSubTypeForm';
import InsightsLivingSpaceForm from './InsightsLivingSpaceForm';
import InsightsPropertySizeForm from './InsightsPropertySizeForm';
import InsightsNRoomsForm from './InsightsNRoomsForm';
import InsightsFloorForm from './InsightsFloorForm';
import InsightsConstructionYearForm from './InsightsConstructionYearForm';
import InsightsParkingTypeForm from './InsightsParkingTypeForm';
import InsightsSummaryProForm from './InsightsSummaryProForm';
import InsightsSummaryForm from './InsightsSummaryForm';
import InsightsQualityLevelForm from './InsightsQualityLevelForm';
import InsightsObjectStateForm from './InsightsObjectStateForm';
import InsightsEnergyEfficiencyClassForm from './InsightsEnergyEfficiencyClassForm';
import InsightsEnergyCertificateTypeForm from './InsightsEnergyCertificateTypeForm';
import InsightsHeatingTypeForm from './InsightsHeatingTypeForm';
import InsightsFeaturesInnerForm from './InsightsFeaturesInnerForm';
import InsightsFeaturesOuterForm from './InsightsFeaturesOuterForm';
import InsightsPurchasePriceForm from './InsightsPurchasePriceForm';
import InsightsAdditionalCostsForm from './InsightsAdditionalCostsForm';

// The following constants represent the states of the form.
const OBJECT_TYPE_FORM = 2;
const POSTAL_CODE_FORM = 3;
const OBJECT_SUB_TYPE_FORM = 4;
const LIVING_SPACE_FORM = 5;
const PROPERTY_SIZE_FORM = 6;
const N_ROOMS_FORM = 7;
const FLOOR_FORM = 8;
const CONSTRUCTION_YEAR_FORM = 9;
const PARKING_TYPE_FORM = 10;
const QUALITY_LEVEL_FORM = 11;
const OBJECT_STATE_FORM = 12;
const ENERGY_EFFICIENCY_CLASS_FORM = 13;
const ENERGY_CERTIFICATE_TYPE_FORM = 14;
const HEATING_TYPE_FORM = 15;
const FEATURES_INNER_FORM = 20;
const FEATURES_OUTER_FORM = 21;
const PURCHASE_PRICE_FORM = 90;
const ADDITIONAL_COSTS_FORM = 91;
const SUMMARY_FORM = 100;

const nextStepsWK = [OBJECT_TYPE_FORM, POSTAL_CODE_FORM, OBJECT_SUB_TYPE_FORM, LIVING_SPACE_FORM, N_ROOMS_FORM, FLOOR_FORM, CONSTRUCTION_YEAR_FORM, PARKING_TYPE_FORM, QUALITY_LEVEL_FORM, OBJECT_STATE_FORM, ENERGY_EFFICIENCY_CLASS_FORM, ENERGY_CERTIFICATE_TYPE_FORM, HEATING_TYPE_FORM, FEATURES_INNER_FORM, FEATURES_OUTER_FORM, PURCHASE_PRICE_FORM, ADDITIONAL_COSTS_FORM, SUMMARY_FORM];
const nextStepsHK = [OBJECT_TYPE_FORM, POSTAL_CODE_FORM, OBJECT_SUB_TYPE_FORM, LIVING_SPACE_FORM, PROPERTY_SIZE_FORM, N_ROOMS_FORM, FLOOR_FORM, CONSTRUCTION_YEAR_FORM, PARKING_TYPE_FORM, QUALITY_LEVEL_FORM, OBJECT_STATE_FORM, ENERGY_EFFICIENCY_CLASS_FORM, ENERGY_CERTIFICATE_TYPE_FORM, HEATING_TYPE_FORM, FEATURES_INNER_FORM, FEATURES_OUTER_FORM, PURCHASE_PRICE_FORM, ADDITIONAL_COSTS_FORM, SUMMARY_FORM];


class InsightsEvalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: OBJECT_TYPE_FORM,
      ...this.getFreshImmoObjectState()
    };
  }


  getFreshImmoObjectState = () => {
    const newState = {
      insightsId: uuid(),
      objectType: '',
      postalCode: '',
      objectSubType: '',
      livingSpace: '',
      nRooms: '',
      propertySize: '',
      constructionYear: '',
      parkingType: '',
      qualityLevel: '',
      objectState: '',

      kitchen: '',
      guest_toilet: '',
      garden: '',
      balcony_or_terrace: '',
      cellar: '',
      stepless_access: '',
      heritage_listed_object: '',
      granny_flat: '',

      floor: '',
      floor_count: '',
      elevator: '',

      energy_efficiency_class: '',
      energy_certificate_type: '',
      heating_type: '',

      purchasePrice: '',
      estate_agent_pct: '',
      notary_pct: '',
      real_estate_transfer_tax_pct: '',
      land_register_pct: '',



      analysis: {
        status: 0, // success, not initialized, 
        statusMessage: '',
        estimatedPurchasePricePerSqm: null,
        estimatedLowerPurchasePricePerSqm: null,
        estimatedUpperPurchasePricePerSqm: null,

        estimatedNetColdRentPerSqm: {
          value: null,
          lower: null,
          upper: null
        },

        estimatedReturn: {
          value: null,
          lower: null,
          upper: null
        },

        estimatedFactor: {
          value: null,
          lower: null,
          upper: null
        }
      },

      errors: {
        objectType: false,
        postalCode: false,
        objectSubType: false,
        livingSpace: false,
        nRooms: false,
        propertySize: false,
        constructionYear: false,
        parkingType: false,
        qualityLevel: false,
        objectState: false,

        kitchen: false,
        guest_toilet: false,
        garden: false,
        balcony_or_terrace: false,
        cellar: false,
        stepless_access: false,
        heritage_listed_object: false,
        granny_flat: false,

        floor: false,
        floor_count: false,
        elevator: false,

        energy_efficiency_class: false,
        energy_certificate_type: false,
        heating_type: false,

        purchasePrice: false,
        estate_agent_pct: false,
        notary_pct: false,
        real_estate_transfer_tax_pct: false,
        land_register_pct: false,
      },
    };

    return newState;
  }


  componentDidMount = () => {

    // Get the preset attributes.
    // They can, for example, be set through a redirect from a browser plugin.
    const preset = this.props.preset;
    if (preset === null || preset === undefined) return;

    if (preset.object_type && preset.postal_code) {
      // Initialize the new state with the two core attributes
      const newState = {
        objectType: this.props.preset.object_type,
        postalCode: this.props.preset.postal_code
      };

      // Set the remaining attributes if available
      newState['objectSubType'] = preset.object_sub_type;
      newState['livingSpace'] = parseFloat(preset.living_space);
      newState['nRooms'] = parseFloat(preset.n_rooms);
      newState['propertySize'] = parseFloat(preset.property_size);
      newState['constructionYear'] = parseInt(preset.construction_year);
      newState['parkingType'] = preset.parking_type;
      newState['qualityLevel'] = parseInt(preset.quality_level);
      newState['objectState'] = preset.object_state;

      newState['kitchen'] = parseInt(preset.kitchen);
      newState['guest_toilet'] = parseInt(preset.guest_toilet);
      newState['garden'] = parseInt(preset.garden);
      newState['balcony_or_terrace'] = parseInt(preset.balcony_or_terrace);
      newState['cellar'] = parseInt(preset.cellar);
      newState['stepless_access'] = parseInt(preset.stepless_access);
      newState['heritage_listed_object'] = parseInt(preset.heritage_listed_object);
      newState['granny_flat'] = parseInt(preset.granny_flat);

      newState['floor'] = parseFloat(preset.floor);
      newState['floor_count'] = parseFloat(preset.floor_count);
      newState['elevator'] = parseInt(preset.elevator);

      newState['energy_efficiency_class'] = preset.energy_efficiency_class;
      newState['energy_certificate_type'] = preset.energy_certificate_type;
      newState['heating_type'] = preset.heating_type;


      newState['purchasePrice'] = parseFloat(preset.purchase_price);


      // In case of errors, terminate or delete the attributes
      const errors = this.validateInputs(newState);
      if (errors.objectType) return; // hard exit
      if (errors.postalCode) return; // hard exit
      if (errors.objectSubType) delete newState.objectSubType;
      if (errors.livingSpace) delete newState.livingSpace;
      if (errors.nRooms) delete newState.nRooms;
      if (errors.propertySize) delete newState.propertySize;
      if (errors.constructionYear) delete newState.constructionYear;
      if (errors.parkingType) delete newState.parkingType;
      if (errors.qualityLevel) delete newState.qualityLevel;
      if (errors.objectState) delete newState.objectState;

      if (errors.kitchen) delete newState.kitchen;
      if (errors.guest_toilet) delete newState.guest_toilet;
      if (errors.garden) delete newState.garden;
      if (errors.balcony_or_terrace) delete newState.balcony_or_terrace;
      if (errors.cellar) delete newState.cellar;
      if (errors.stepless_access) delete newState.stepless_access;
      if (errors.heritage_listed_object) delete newState.heritage_listed_object;
      if (errors.granny_flat) delete newState.granny_flat;

      if (errors.floor) delete newState.floor;
      if (errors.floor_count) delete newState.floor_count;
      if (errors.elevator) delete newState.elevator;

      if (errors.energy_efficiency_class) delete newState.energy_efficiency_class;
      if (errors.energy_certificate_type) delete newState.energy_certificate_type;
      if (errors.heating_type) delete newState.heating_type;

      if (errors.purchasePrice) delete newState.purchasePrice;
      if (errors.estate_agent_pct) delete newState.estate_agent_pct;
      if (errors.notary_pct) delete newState.notary_pct;
      if (errors.real_estate_transfer_tax_pct) delete newState.real_estate_transfer_tax_pct;
      if (errors.land_register_pct) delete newState.land_register_pct;


      // Update the state
      this.setState(newState, () => {
        this.updateInsightsEvaluation(()=>{}, ()=>{
          // Because the core attributes are available,
          // we can jump directly to the summary form.
          this.setState({step:SUMMARY_FORM})
        })
      })
    }
  }


  validateObjectType = (objectType) => {
    const errors = {}
    if (typeof(objectType) !== 'string') {
      errors.objectType = true;
    }
    if (objectType !== 'WK' &&
        objectType !== 'HK') {
          errors.objectType = true;
    }
    return errors;
  }

  validatePostalCode = (postalCode) => {
    const errors = {}
    if (typeof(postalCode) !== 'string' ||
        postalCode.length !== 5) {
          errors.postalCode = true;
    }
    else {
      const onlyNumbers = /^\d+$/.test(postalCode);
      if (!onlyNumbers) {
        errors.postalCode = true;
      }
    }
    return errors;
  }

  validateObjectSubType = (value) => {
    const errors = {}
    const validValues = ["OTHER", "ERDGESCHOSSWOHNUNG", "ETAGENWOHNUNG", "DACHGESCHOSS", "MAISONETTE", "HOCHPARTERRE", "PENTHOUSE", "LOFT", "SOUTERRAIN", "REIHENMITTELHAUS", "REIHENECKHAUS", "MEHRFAMILIENHAUS", "EINFAMILIENHAUS", "DOPPELHAUSHÄLFTE", "BUNGALOW"];
    if (validValues.indexOf(value) === -1) {
      errors.objectSubType = true;
    }
    return errors;
  }

  validateLivingSpace = (livingSpace) => {
    const errors = {}
    if (isNaN(livingSpace)) {
      errors.livingSpace = true;
    }
    else if (livingSpace < 1) {
      errors.livingSpace = true;
    }
    else if (livingSpace > 1000) {
      errors.livingSpace = true;
    }
    //else if (livingSpace > 300) {
      //errors.livingSpace = true;
    //}
    return errors;
  }

  validateNRooms = (nRooms) => {
    const errors = {}
    if (isNaN(nRooms)) {
      errors.nRooms = true;
    }
    else if (nRooms < 1) {
      errors.nRooms = true;
    }
    else if (nRooms > 11) {
      errors.nRooms = true;
    }
    return errors;
  }

  validatePropertySize = (propertySize) => {
    const errors = {}
    if (isNaN(propertySize)) {
      errors.propertySize = true;
    }
    else if (propertySize < 1) {
      errors.propertySize = true;
    }
    else if (propertySize > 10000) {
      errors.propertySize = true;
    }
    //else if (propertySize > 3000) {
      //errors.propertySize = true;
    //}
    return errors;
  }

  validateConstructionYear = (constructionYear) => {
    const errors = {}
    if (isNaN(constructionYear)) {
      errors.constructionYear = true;
    }
    else if (constructionYear < 1849) { // 1850, reset
      errors.constructionYear = true;
    }
    else if (constructionYear > 2024) {
      errors.constructionYear = true;
    }
    return errors;
  }

  validateParkingType = (value) => {
    const errors = {}
    const validValues = ["NO_PARKING", "GARAGE", "UNDERGROUND", "OUTSIDE_SPACE", "DUPLEX", "PARKING_SPACE", "PARKING_DECK", "CARPORT"];
    if (validValues.indexOf(value) === -1) {
      errors.parkingType = true;
    }
    return errors;
  }

  validateQualityLevel = (qualityLevel) => {
    const errors = {}
    if (isNaN(qualityLevel)) {
      errors.qualityLevel = true;
    }
    else if (qualityLevel < 1) {
      errors.qualityLevel = true;
    }
    else if (qualityLevel > 4) {
      errors.qualityLevel = true;
    }
    return errors;
  }

  validateObjectState = (value) => {
    const errors = {}
    const validValues = ["GEPFLEGT", "MODERNISIERT", "RENOVIERUNGSBEDÜRFTIG", "RENOVIERT", "ERSTBEZUG", "NEUWERTIG", "SANIERT", "ABBRUCHREIF"];
    if (validValues.indexOf(value) === -1) {
      errors.objectState = true;
    }
    return errors;
  }

  validateFloor = (value) => {
    const errors = {}
    if (isNaN(value)) {
      errors.floor = true;
    }
    else if (value < 0) {
      errors.floor = true;
    }
    else if (value > 21) {
      errors.floor = true;
    }
    else if (value === "") {
      errors.floor = true;
    }
    return errors;
  }

  validateFloorCount = (value) => {
    const errors = {}
    if (isNaN(value)) {
      errors.floor_count = true;
    }
    else if (value < 0) {
      errors.floor_count = true;
    }
    else if (value > 21) {
      errors.floor_count = true;
    }
    else if (value === "") {
      errors.floor_count = true;
    }
    return errors;
  }

  validateEnergyEfficiencyClass = (value) => {
    const errors = {}
    const validValues = ["A+", "A", "B", "C", "D", "E", "F", "G", "H"];
    if (validValues.indexOf(value) === -1) {
      errors.energy_efficiency_class = true;
    }
    return errors;
  }

  validateEnergyCertificateType = (value) => {
    const errors = {}
    const validValues = ["VERBRAUCHSAUSWEIS", "BEDARFSAUSWEIS"];
    if (validValues.indexOf(value) === -1) {
      errors.energy_certificate_type = true;
    }
    return errors;
  }

  validateHeatingType = (value) => {
    const errors = {}
    //const validValues = ["FERNWÄRME", "ETAGENHEIZUNG", "GAS", "ZENTRALHEIZUNG", "ELEKTRO", "FUSSBODENHEIZUNG", "BLOCKHEIZKRAFTWERK", "WÄRMEPUMPE", "PELLETHEIZUNG", "ÖL", "OFENHEIZUNG", "NACHTSPEICHERÖFEN", "SOLAR"];
    const validValues = ["FERNWÄRME", "GAS", "ELEKTRO", "BLOCKHEIZKRAFTWERK", "WÄRMEPUMPE", "PELLETHEIZUNG", "ÖL", "SOLAR"];
    if (validValues.indexOf(value) === -1) {
      errors.heating_type = true;
    }
    return errors;
  }

  validatePurchasePrice = (purchasePrice) => {
    const errors = {}
    if (isNaN(purchasePrice)) {
      errors.purchasePrice = true;
    }
    else if (purchasePrice <= 0) {
      errors.purchasePrice = true;
    }
    else if (purchasePrice > 99999999) {
      errors.purchasePrice = true;
    }
    return errors;
  }

  validateAdditionalCostsPercentage = (pct) => {
    if (isNaN(pct)) return true;
    if (pct < 0.0) return true;
    return false;
  }
  validateEstateAgentPct = (pct) => {
    const errors = {};
    const error = this.validateAdditionalCostsPercentage(pct);
    if (error) errors.estate_agent_pct = error;
    return errors;
  }
  validateNotaryPct = (pct) => {
    const errors = {};
    const error = this.validateAdditionalCostsPercentage(pct);
    if (error) errors.notary_pct = error;
    return errors;
  }
  validateRealEstateTransferTaxPct = (pct) => {
    const errors = {};
    const error = this.validateAdditionalCostsPercentage(pct);
    if (error) errors.real_estate_transfer_tax_pct = error;
    return errors;
  }
  validateLandRegisterPct = (pct) => {
    const errors = {};
    const error = this.validateAdditionalCostsPercentage(pct);
    if (error) errors.land_register_pct = error;
    return errors;
  }


  validateBinaryCategory = (value, categoryName) => {
    // value is either true or we interpret it as not set
    const errors = {}
    if (value >= 0) {
      // all good
    }
    else if (value === '') {
      // also all good
      // TODO yes? truly good?
    }
    else {
      errors[categoryName] = true;
    }
    return errors;
  }


  validateInputs = (state) => {
    // Length can later be retrieved with:
    // Object.keys(errors).length;

    const objectTypeErrors = this.validateObjectType(state.objectType);
    const postalCodeErrors = this.validatePostalCode(state.postalCode);
    const objectSubTypeErrors = this.validateObjectSubType(state.objectSubType);
    const livingSpaceErrors = this.validateLivingSpace(state.livingSpace);
    const nRoomsErrors = this.validateNRooms(state.nRooms);
    const propertySizeErrors = this.validatePropertySize(state.propertySize);
    const constructionYearErrors = this.validateConstructionYear(state.constructionYear);
    const parkingTypeErrors = this.validateParkingType(state.parkingType);
    const qualityLevelErrors = this.validateQualityLevel(state.qualityLevel);
    const objectStateErrors = this.validateObjectState(state.objectState);

    const kitchen_errors = this.validateBinaryCategory(state.kitchen, 'kitchen');
    const guest_toilet_errors = this.validateBinaryCategory(state.guest_toilet, 'guest_toilet');
    const garden_errors = this.validateBinaryCategory(state.garden, 'garden');
    const balcony_or_terrace_errors = this.validateBinaryCategory(state.balcony_or_terrace, 'balcony_or_terrace');
    const cellar_errors = this.validateBinaryCategory(state.cellar, 'cellar');
    const stepless_access_errors = this.validateBinaryCategory(state.stepless_access, 'stepless_access');
    const heritage_listed_object_errors = this.validateBinaryCategory(state.heritage_listed_object, 'heritage_listed_object');
    const granny_flat_errors = this.validateBinaryCategory(state.granny_flat, 'granny_flat');

    const floor_errors = this.validateFloor(state.floor);
    const floor_count_errors = this.validateFloorCount(state.floor_count);
    const elevator_errors = this.validateBinaryCategory(state.elevator, 'elevator');

    const energy_efficiency_class_errors = this.validateEnergyEfficiencyClass(state.energy_efficiency_class);
    const energy_certificate_type_errors = this.validateEnergyCertificateType(state.energy_certificate_type);
    const heating_type_errors = this.validateHeatingType(state.heating_type);

    const purchasePriceErrors = this.validatePurchasePrice(state.purchasePrice);
    const estateAgentPctErrors = this.validateEstateAgentPct(state.estate_agent_pct);
    const notaryPctErrors = this.validateNotaryPct(state.notary_pct);
    const realEstateTransferTaxPctErrors = this.validateRealEstateTransferTaxPct(state.real_estate_transfer_tax_pct);
    const landRegisterPctErrors = this.validateLandRegisterPct(state.land_register_pct);

    const errors = {
      ...objectTypeErrors,
      ...postalCodeErrors,
      ...objectSubTypeErrors,
      ...livingSpaceErrors,
      ...nRoomsErrors,
      ...propertySizeErrors,
      ...constructionYearErrors,
      ...parkingTypeErrors,
      ...qualityLevelErrors,
      ...objectStateErrors,

      ...kitchen_errors,
      ...guest_toilet_errors,
      ...garden_errors,
      ...balcony_or_terrace_errors,
      ...cellar_errors,
      ...stepless_access_errors,
      ...heritage_listed_object_errors,
      ...granny_flat_errors,

      ...floor_errors,
      ...floor_count_errors,
      ...elevator_errors,

      ...energy_efficiency_class_errors,
      ...energy_certificate_type_errors,
      ...heating_type_errors,

      ...purchasePriceErrors,
      ...estateAgentPctErrors,
      ...notaryPctErrors,
      ...realEstateTransferTaxPctErrors,
      ...landRegisterPctErrors,
    }

    return errors;
  }

  getInsightsPostBody = (state, errors) => {
    const body = {
      insights_id: state.insightsId,
      region_identifier: 'postal_code',
      source: 'IMMOVESTICS',
      source_meta: 'FORM',
      step: state.step,
      object_type: null,
      postal_code: null,
      object_sub_type: null,
      living_space: null,
      n_rooms: null,
      property_size: null,
      construction_year: null,
      parking_type: null,
      quality_level: null,
      object_state: null,

      kitchen: null,
      guest_toilet: null,
      garden: null,
      balcony_or_terrace: null,
      cellar: null,
      stepless_access: null,
      heritage_listed_object: null,
      granny_flat: null,

      floor: null,
      floor_count: null,
      elevator: null,

      energy_efficiency_class: null,
      energy_certificate_type: null,
      heating_type: null,

      purchase_price: null,
      estate_agent_pct: null,
      notary_pct: null,
      real_estate_transfer_tax_pct: null,
      land_register_pct: null,
    };

    if (errors.objectType !== true) { body.object_type = state.objectType };
    if (errors.postalCode !== true) { body.postal_code = state.postalCode };
    if (errors.objectSubType !== true) { body.object_sub_type = state.objectSubType };
    if (errors.livingSpace !== true) { body.living_space = state.livingSpace };
    if (errors.nRooms !== true) { body.n_rooms = state.nRooms };
    if (errors.propertySize !== true) { body.property_size = state.propertySize };
    if (errors.constructionYear !== true) { body.construction_year = state.constructionYear };
    if (errors.parkingType !== true) { body.parking_type = state.parkingType };
    if (errors.qualityLevel !== true) { body.quality_level = state.qualityLevel };
    if (errors.objectState !== true) { body.object_state = state.objectState };

    if (errors.kitchen !== true) { body.kitchen = state.kitchen };
    if (errors.guest_toilet !== true) { body.guest_toilet = state.guest_toilet };
    if (errors.garden !== true) { body.garden = state.garden };
    if (errors.balcony_or_terrace !== true) { body.balcony_or_terrace = state.balcony_or_terrace };
    if (errors.cellar !== true) { body.cellar = state.cellar };
    if (errors.stepless_access !== true) { body.stepless_access = state.stepless_access };
    if (errors.heritage_listed_object !== true) { body.heritage_listed_object = state.heritage_listed_object };
    if (errors.granny_flat !== true) { body.granny_flat = state.granny_flat };

    if (errors.floor !== true) { body.floor = state.floor };
    if (errors.floor_count !== true) { body.floor_count = state.floor_count };
    if (errors.elevator !== true) { body.elevator = state.elevator };

    if (errors.energy_efficiency_class !== true) { body.energy_efficiency_class = state.energy_efficiency_class };
    if (errors.energy_certificate_type !== true) { body.energy_certificate_type = state.energy_certificate_type };
    if (errors.heating_type !== true) { body.heating_type = state.heating_type };

    if (errors.purchasePrice !== true) { body.purchase_price = state.purchasePrice };
    if (errors.estate_agent_pct !== true) { body.estate_agent_pct = state.estate_agent_pct };
    if (errors.notary_pct !== true) { body.notary_pct = state.notary_pct };
    if (errors.real_estate_transfer_tax_pct !== true) { body.real_estate_transfer_tax_pct = state.real_estate_transfer_tax_pct };
    if (errors.land_register_pct !== true) { body.land_register_pct = state.land_register_pct };

    // Derive additional attributes
    if (errors.livingSpace !== true && errors.purchasePrice !== true) {
      if (state.livingSpace > 0) {
        const purchasePricePerSqm = state.purchasePrice / state.livingSpace;
        body.purchase_price_per_sqm = purchasePricePerSqm;
      }
    }

    //console.log(body)

    return body;
  }

  // Next step
  nextStep = () => {
    //const errors = this.validateInputs(this.state);
    //console.log("errors", errors)

    const nextSteps = this.state.objectType === "HK" ?
      nextStepsHK :
      nextStepsWK;

    let currentPosition = nextSteps.findIndex(element => element === this.state.step);
    let nextPosition = currentPosition + 1;
    if (nextPosition >= nextSteps.length) {
      nextPosition = 0; // back to start in case of an invalid condition
    }

    const nextStep = nextSteps[nextPosition];
    this.setState({
      step: nextStep
    })
  }

  skipToEnd = () => {
    if (this.state.analysis.status === 1) {
      const newStep = SUMMARY_FORM;
      this.setState({
        step: newStep
      })
    }
  }

  // Previous step
  previousStep = (jumpToStart) => {
    const nextSteps = this.state.objectType === "HK" ?
      nextStepsHK :
      nextStepsWK;

    let currentPosition = nextSteps.findIndex(element => element === this.state.step);
    let nextPosition = currentPosition - 1;
    if (nextPosition < 0) {
      nextPosition = 0; // back to start in case of an invalid condition
    }

    // Check if we should go right back to the start.
    // In this case, ignore the previous index computation.
    if (jumpToStart === true) nextPosition = 0;

    // Lookup the next step in the steps array
    const nextStep = nextSteps[nextPosition];

    // In case we are at the object selection screen, reset all data
    const newState = nextStep === OBJECT_TYPE_FORM ? {
      step: nextStep,
      ...this.getFreshImmoObjectState()
    } : {
      step: nextStep
    }
    this.setState(newState);
  }


  updateInsightsEvaluation = (err, cb) => {
    const errors = this.validateInputs(this.state);
    const insightsPostBody = this.getInsightsPostBody(this.state, errors);
    //console.log(insightsPostBody);

    // Check if minimum requirements are met (object_type and postal_code available)
    if (insightsPostBody.object_type !== null && insightsPostBody.postal_code !== null) {
        axios.post(API_ROOT + '/insights/object', insightsPostBody)
        .then((res) => {

          //console.log(res.data);

          if (res.data.status === 2) {
            this.setState({
              analysis: {
                status: res.data.status,
                statusMessage: res.data.status_message
              },
              errors: errors
            }, () => {
              return err();
            })
          }
          else {
            //console.log(res.data)
            this.setState({
              analysis: {
                status: 1,
                statusMessage: 'success',

                ...res.data
              },
              errors: errors
            }, () => {
              return cb();
            })
          }
        })
        .catch((err) => {
          if (err.response) {
            //console.log(`Error with status code ${err.response.status}`);
            const insightsStatusCode = err.response.status;
            if (insightsStatusCode === 404) {
              this.setState({
                analysis: {
                  status: 2,
                  statusMessage: 'UNKNOWN_ERROR'
                },
                errors: errors
              }, () => {
                return err();
              })
            }
          }
          else {
            //console.log("Error:", err)
          }
        });
    }
    else {
      //console.log('Error in handleChange:', errors);
      this.setState({
        analysis: {
          ...this.state.analysis,
          status: 0, // object_type or postal_code is invalid
          statusMessage: 'UNKNOWN_ERROR'
        },
        errors: errors
      }, () => {
        //console.log('Error in handleChange:', this.state.errors)
      });
    }

  }

  handleChange = (e) => {
    //console.log(`handleChange: ${e.target.id} ${e.target.value}`);

    const newState = {};
    newState[e.target.id] = e.target.value;

    this.setState(newState, () => {
      return this.updateInsightsEvaluation(()=> {}, ()=>{});
    })
  }

  render() {
    const { step } = this.state;
    // Get the immo object attributes
    const { objectType, postalCode, objectSubType, livingSpace, nRooms, propertySize, constructionYear, parkingType, qualityLevel, objectState,
            kitchen, guest_toilet, garden, balcony_or_terrace, cellar, stepless_access, heritage_listed_object, granny_flat,
            floor, floor_count, elevator, energy_efficiency_class, energy_certificate_type, heating_type,
            purchasePrice, estate_agent_pct, notary_pct, real_estate_transfer_tax_pct, land_register_pct } = this.state;

      
    const values = { objectType, postalCode, objectSubType, livingSpace, nRooms, propertySize, constructionYear, parkingType, qualityLevel, objectState,
                     kitchen, guest_toilet, garden, balcony_or_terrace, cellar, stepless_access, heritage_listed_object, granny_flat,
                     floor, floor_count, elevator, energy_efficiency_class, energy_certificate_type, heating_type,
                     purchasePrice, estate_agent_pct, notary_pct, real_estate_transfer_tax_pct, land_register_pct };
    const analysis = this.state.analysis;


    switch(step) {
      case OBJECT_TYPE_FORM:
        return (
          <div>
            <InsightsObjectTypeForm 
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              handleChange={this.handleChange}
              validateInput={this.validateObjectType}
              values={values}
              analysis={analysis}
            />
          </div>
        )
      
      case POSTAL_CODE_FORM:
        return (
          <div>
            <InsightsPostalCodeForm 
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              skipToEnd={this.skipToEnd}
              handleChange={this.handleChange}
              validateInput={this.validatePostalCode}
              values={values}
              analysis={analysis}
              errors={this.state.errors}
            />
          </div>
        )

      case OBJECT_SUB_TYPE_FORM:
        return (
          <div>
            <InsightsObjectSubTypeForm
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              skipToEnd={this.skipToEnd}
              handleChange={this.handleChange}
              validateInput={this.validateObjectSubType}
              values={values}
              analysis={analysis}
              errors={this.state.errors}
            />
          </div>
        )

      case LIVING_SPACE_FORM:
        return (
          <div>
            <InsightsLivingSpaceForm 
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              skipToEnd={this.skipToEnd}
              handleChange={this.handleChange}
              validateInput={this.validateLivingSpace}
              values={values}
              analysis={analysis}
              errors={this.state.errors}
            />
          </div>
        )

      case PROPERTY_SIZE_FORM:
        return (
          <div>
            <InsightsPropertySizeForm 
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              skipToEnd={this.skipToEnd}
              handleChange={this.handleChange}
              validateInput={this.validatePropertySize}
              values={values}
              analysis={analysis}
              errors={this.state.errors}
            />
          </div>
        )

      case FLOOR_FORM:
        return (
          <div>
            <InsightsFloorForm
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              skipToEnd={this.skipToEnd}
              handleChange={this.handleChange}
              validateInput={this.validateFloor}
              values={values}
              analysis={analysis}
              errors={this.state.errors}
            />
          </div>
        )

      case N_ROOMS_FORM:
        return (
          <div>
            <InsightsNRoomsForm
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              skipToEnd={this.skipToEnd}
              handleChange={this.handleChange}
              validateInput={this.validateNRooms}
              values={values}
              analysis={analysis}
              errors={this.state.errors}
            />
          </div>
        )

      case CONSTRUCTION_YEAR_FORM:
        return (
          <div>
            <InsightsConstructionYearForm
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              skipToEnd={this.skipToEnd}
              handleChange={this.handleChange}
              validateInput={this.validateConstructionYear}
              values={values}
              analysis={analysis}
              errors={this.state.errors}
            />
          </div>
        )

      case PARKING_TYPE_FORM:
        return (
          <div>
            <InsightsParkingTypeForm
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              skipToEnd={this.skipToEnd}
              handleChange={this.handleChange}
              validateInput={this.validateParkingType}
              values={values}
              analysis={analysis}
              errors={this.state.errors}
            />
          </div>
        )

      case QUALITY_LEVEL_FORM:
        return (
          <div>
            <InsightsQualityLevelForm
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              skipToEnd={this.skipToEnd}
              handleChange={this.handleChange}
              validateInput={this.validateQualityLevel}
              values={values}
              analysis={analysis}
              errors={this.state.errors}
            />
          </div>
        )

      case OBJECT_STATE_FORM:
        return (
          <div>
            <InsightsObjectStateForm
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              skipToEnd={this.skipToEnd}
              handleChange={this.handleChange}
              validateInput={this.validateObjectState}
              values={values}
              analysis={analysis}
              errors={this.state.errors}
            />
          </div>
        )

      case ENERGY_EFFICIENCY_CLASS_FORM:
        return (
          <div>
            <InsightsEnergyEfficiencyClassForm
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              skipToEnd={this.skipToEnd}
              handleChange={this.handleChange}
              validateInput={this.validateEnergyEfficiencyClass}
              values={values}
              analysis={analysis}
              errors={this.state.errors}
            />
          </div>
        )

      case ENERGY_CERTIFICATE_TYPE_FORM:
        return (
          <div>
            <InsightsEnergyCertificateTypeForm
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              skipToEnd={this.skipToEnd}
              handleChange={this.handleChange}
              validateInput={this.validateEnergyCertificateType}
              values={values}
              analysis={analysis}
              errors={this.state.errors}
            />
          </div>
        )

      case HEATING_TYPE_FORM:
        return (
          <div>
            <InsightsHeatingTypeForm
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              skipToEnd={this.skipToEnd}
              handleChange={this.handleChange}
              validateInput={this.validateHeatingType}
              values={values}
              analysis={analysis}
              errors={this.state.errors}
            />
          </div>
        )

      case FEATURES_INNER_FORM:
        return (
          <div>
            <InsightsFeaturesInnerForm
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              skipToEnd={this.skipToEnd}
              handleChange={this.handleChange}
              validateInput={this.validateBinaryCategory}
              values={values}
              analysis={analysis}
              errors={this.state.errors}
            />
          </div>
        )

      case FEATURES_OUTER_FORM:
        return (
          <div>
            <InsightsFeaturesOuterForm
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              skipToEnd={this.skipToEnd}
              handleChange={this.handleChange}
              validateInput={this.validateBinaryCategory}
              values={values}
              analysis={analysis}
              errors={this.state.errors}
            />
          </div>
        )

      case PURCHASE_PRICE_FORM:
        return (
          <div>
            <InsightsPurchasePriceForm
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              skipToEnd={this.skipToEnd}
              handleChange={this.handleChange}
              validateInput={this.validatePurchasePrice}
              values={values}
              analysis={analysis}
              errors={this.state.errors}
            />
          </div>
        )

      case ADDITIONAL_COSTS_FORM:
        return (
          <div>
            <InsightsAdditionalCostsForm
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              skipToEnd={this.skipToEnd}
              handleChange={this.handleChange}
              validateInput={{
                validateEstateAgentPct: this.validateEstateAgentPct,
                validateNotaryPct: this.validateNotaryPct,
                validateRealEstateTransferTaxPct: this.validateRealEstateTransferTaxPct,
                validateLandRegisterPct: this.validateLandRegisterPct
              }}
              values={values}
              analysis={analysis}
              errors={this.state.errors}
            />
          </div>
        )

      case SUMMARY_FORM:
        if (this.props.isPro === true) {
          return (
            <div>
              <InsightsSummaryProForm 
                previousStep={this.previousStep}
                nextStep={this.nextStep}
                handleChange={this.handleChange}
                validateInput={this.validateInputs}
                values={values}
                analysis={analysis}
                errors={this.state.errors}
                isPro={this.props.isPro}
              />
            </div>
          )
        }
        else {
          return (
            <div>
              <InsightsSummaryForm 
                previousStep={this.previousStep}
                nextStep={this.nextStep}
                handleChange={this.handleChange}
                validateInput={this.validateInputs}
                values={values}
                analysis={analysis}
                errors={this.state.errors}
                isPro={this.props.isPro}
              />
            </div>
          )
        }

      default:
        return (
          <div>Default</div>
        )
    }
  }
}

export default InsightsEvalForm;
