import React, { useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";

import InsightsEvalForm from '../../components/Insights/InsightsEvalForm';
import Spacer from '../../components/Spacer';

const InsightsEvalPage = ({ isPro }) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // In case the browser params have been set, we would like to delete them.
    // Otherwise, they would persist through page refreshes.
    if (location.state?.browser) {
      const stateCopy = { ...location.state };
      delete stateCopy.browser;
      navigate(location.pathname, { state: stateCopy, replace: true });
    }
  }, [location, navigate]);

  // Get the browser parameters if they have been set
  const browserParams = location.state?.browser;

  // In case of a redirect, the query parameters in the URL are reset.
  // However, in this case, the pro parameter will be available in the props.location.state variable.
  const useProFunctionality = isPro || location.state?.isPro ? true : false;

  return (
    <div>
      <Helmet>
        <title>Blitzschnelle Immobilienbewertungen mit Immovestics Insights | Immovestics</title>
        <meta name="description" content="Bewerten Sie Immobilien interaktiv und in wenigen Sekunden für Ihre Immobilien Investments" />
      </Helmet>

      <div className="row">
        <div className="col s12">

          <Spacer height="20px" />

          <section>
            <div className="center">
              <div className="row">
                <div className="col s12 m8 offset-m2 l8 offset-l2 xl4 offset-xl4">
                  <div className="insights-container">
                    <InsightsEvalForm isPro={useProFunctionality} preset={browserParams} />
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div>
    </div>
  );
};

export default InsightsEvalPage;
