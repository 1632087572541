import React, { Component } from 'react'
import M from 'materialize-css';

import Textfit from '@namhong2001/react-textfit';
//import Textfit from "react-textfit/lib/Textfit";

import InsightsEvalFormHeader from './InsightsEvalFormHeader';
import InsightsEvalFormFooter from './InsightsEvalFormFooter';
import InsightsEvalFormNavigationButtons from './InsightsEvalFormNavigationButtons';

import InsightsDropdown from './UiElements/InsightsDropdown';
import FeatureImpact from './UiElements/FeatureImpact';

class InsightsObjectSubTypeForm extends Component {
  state = {
    submitButtonClicked: false,
  }


  componentDidMount = () => {
    const elems = document.querySelectorAll('select');
    M.FormSelect.init(elems);
  }


  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      submitButtonClicked: true
    })

    // Allow the next step if there are either no errors or the (optional) field is empty
    if (this.props.errors.objectSubType !== true || this.props.values.objectSubType === '') {
      this.props.nextStep();
    }
  }


  handleChange = (e) => {
    // NOTE: DELETE in case the cards are not needed anymore

    // Check if the value is valid
    let value = null;
    if (!e?.target?.value) return;
    try {
      value = e.target.value;
      const errors = this.props.validateInput(value);
      if (errors.objectSubType) return;
    }
    catch {
      return;
    }

    // Check if the value has already been selected and should now be unselected.
    if (String(value) === String(this.props.values.objectSubType)) {
      value = "";
    }

    // The input is valid.
    // Create a synthetic event and emit it.
    const syntheticEvent = {
      target: {
        id: e.target.name,
        value: value
      }
    }
    this.props.handleChange(syntheticEvent);
  }


  renderCard = (value, text) => {
    return (
        <div className="col s6">
          <label>
            <input type="radio" name="objectSubType" id={"object_sub_type_" + value} value={value} checked={this.props.values.objectSubType === value} className="insights-card-input-element" onClick={this.handleChange} onChange={() => {}}/>
            <div className="card insights-card-input">
              <div className="card-content insights-card-content">
                <Textfit max={19} className="insights-card-text">{text}</Textfit>
              </div>
            </div>
          </label>
        </div>
    )

    /*
    // Larger cards
    return (
        <div className="col s12 m6">
          <label>
            <input type="radio" name="objectSubType" id={"object_sub_type_" + value} value={value} checked={this.props.values.objectSubType === value} className="insights-card-input-element" onClick={this.handleChange} onChange={() => {}}/>
            <div className="card insights-card-input">
              <div className="card-content insights-card-content">
                <div className="card-title insights-card-title-text">{text}</div>
              </div>
            </div>
          </label>
        </div>
    )
    */
  }


  renderWKTypes = () => {
    return (
      <div>
        <div className="row insights-card-row">
          { this.renderCard("ERDGESCHOSSWOHNUNG", "Erdgeschoss") }
          { this.renderCard("ETAGENWOHNUNG", "Etagenwohnung") }
        </div>

        <div className="row insights-card-row">
          { this.renderCard("SOUTERRAIN", "Souterrain") }
          { this.renderCard("HOCHPARTERRE", "Hochparterre") }
        </div>

        <div className="row insights-card-row">
          { this.renderCard("MAISONETTE", "Maisonette") }
          { this.renderCard("DACHGESCHOSS", "Dachgeschoss") }
        </div>

        <div className="row insights-card-row">
          { this.renderCard("LOFT", "Loft") }
          { this.renderCard("PENTHOUSE", "Penthouse") }
        </div>
      </div>
    );
  }


  renderHKTypes = () => {
    return (
      <div>
        <div className="row insights-card-row">
          { this.renderCard("EINFAMILIENHAUS", "Einfamilienhaus") }
          { this.renderCard("MEHRFAMILIENHAUS", "Mehrfamilienhaus") }
        </div>

        <div className="row insights-card-row">
          { this.renderCard("BUNGALOW", "Bungalow") }
          { this.renderCard("DOPPELHAUSHÄLFTE", "Doppelhaushälfte") }
        </div>

        <div className="row insights-card-row">
          { this.renderCard("REIHENMITTELHAUS", "Reihenmittelhaus") }
          { this.renderCard("REIHENECKHAUS", "Reiheneckhaus") }
        </div>
      </div>
    );
  }


  getInputFieldOption = (value, text) => {
    return (
      <option value={value}>{text}</option>
    )
  }
  renderWKTypesDropdown = () => {
    const attributeId = "objectSubType";
    const value = this.props.values.objectSubType;
    const valuePairs = [
      { value: "", text: "Keine Angabe" },
      { value: "ERDGESCHOSSWOHNUNG", text: "Erdgeschoss"},
      { value: "ETAGENWOHNUNG", text: "Etagenwohnung"},
      { value: "DACHGESCHOSS", text: "Dachgeschoss"},
      { value: "MAISONETTE", text: "Maisonette"},
      { value: "HOCHPARTERRE", text: "Hochparterre"},
      { value: "PENTHOUSE", text: "Penthouse"},
      { value: "LOFT", text: "Loft"},
      { value: "SOUTERRAIN", text: "Souterrain"},
    ];
    return (
      <InsightsDropdown
        attributeId={attributeId}
        valuePairs={valuePairs}
        value={value}
        handleChange={this.props.handleChange}
        validateInput={this.props.validateInput}
      />
    )
  }

  renderHKTypesDropdown = () => {
    const attributeId = "objectSubType";
    const value = this.props.values.objectSubType;
    const valuePairs = [
      { value: "", text: "Keine Angabe" },
      { value: "EINFAMILIENHAUS", text: "Einfamilienhaus"},
      { value: "MEHRFAMILIENHAUS", text: "Mehrfamilienhaus"},
      { value: "BUNGALOW", text: "Bungalow"},
      { value: "DOPPELHAUSHÄLFTE", text: "Doppelhaushälfte"},
      { value: "REIHENMITTELHAUS", text: "Reihenmittelhaus"},
      { value: "REIHENECKHAUS", text: "Reiheneckhaus"},
    ];
    return (
      <InsightsDropdown
        attributeId={attributeId}
        valuePairs={valuePairs}
        value={value}
        handleChange={this.props.handleChange}
        validateInput={this.props.validateInput}
      />
    )
  }


  render() {
    //const { } = this.props.values;
    const analysis = this.props.analysis;
    const { objectType } = this.props.values;
    //const objectSubTypeError = this.props.errors.objectSubType;

    return (
      <div>

        <InsightsEvalFormHeader />

        <div className="container">

          <div className="row">
            <div className="col s12">
              <h5>Objekttyp</h5>
            </div>
          </div>

          <div className="insights-eval-form-content">

            <div className="row">
              {
                //objectType === "WK" ? this.renderWKTypes() : null
                //objectType === "WK" ? this.renderWKTypesDropdown() : null
              }
              {
                //objectType === "HK" ? this.renderHKTypes() : null
                //objectType === "HK" ? this.renderHKTypesDropdown() : null
              }

              {
                objectType === "WK" ? this.renderWKTypes() : this.renderHKTypes()
                //objectType === "WK" ? this.renderWKTypesDropdown() : this.renderHKTypesDropdown()
              }

            </div>

            <div className="row">
              <div className="col s12">
                <FeatureImpact
                  impact={this.props.analysis.purchase_analysis.object_sub_type.impact}
                />
              </div>
            </div>

          </div>


          <div className="row">
            <div className="col s12">
              <InsightsEvalFormNavigationButtons
                previousStep={this.props.previousStep}
                skipToEnd={this.props.skipToEnd}
                handleSubmit={this.handleSubmit}
                analysis={analysis}
                />
            </div>
          </div>

        </div>

        <div className="divider" />
        <div className="row">
          <div className="col s12">
            <InsightsEvalFormFooter
              previousStep={this.props.previousStep}
              skipToEnd={this.props.skipToEnd}
              handleSubmit={this.handleSubmit}
              analysis={analysis}
              />
          </div>
        </div>

      </div>
    )
  }
}

export default InsightsObjectSubTypeForm;
