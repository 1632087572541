import React, { useEffect, useRef, useState } from 'react';
import M from 'materialize-css';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import wNumb from 'wnumb';
import FeatureImpact from './FeatureImpact';

const FloorSlider = (props) => {
  const sliderRef = useRef(null);
  const [floor, setFloor] = useState(props.values.floor ? props.values.floor : "");

  useEffect(() => {
    const floorSlider = sliderRef.current;
    if (floorSlider) {
      noUiSlider.create(floorSlider, {
        start: [props.values.floor ? props.values.floor : -1],
        connect: [true, false],
        tooltips: [true],
        range: {
          min: -1,
          max: 21,
        },
        step: 1,
        format: wNumb({
          decimals: 0,
          edit: (value) => {
            if (value < 0) return '-';
            if (value > 20) return '> 20';
            return String(value);
          },
        }),
      });

      floorSlider.noUiSlider.on('change', (value) => {
        let changedValue = value[0];
        if (changedValue === '-' || changedValue === '-1') changedValue = ''; // reset state
        if (changedValue === '> 20') changedValue = '21';

        setFloor(String(changedValue));
        props.handleChange({
          target: {
            id: 'floor',
            value: changedValue,
          },
        });
        M.updateTextFields();
      });

      M.updateTextFields();

      // Cleanup on unmount
      return () => {
        if (floorSlider.noUiSlider) {
          floorSlider.noUiSlider.destroy();
        }
      };
    }
  }, [props]);

  useEffect(() => {
    if (sliderRef.current && sliderRef.current.noUiSlider) {
      const newValue = floor === "" ? -1 : floor;
      sliderRef.current.noUiSlider.set(newValue);
    }
  }, [floor]);

  const handleFloorChange = (e) => {
    let textValue = e.target.value;
    textValue = textValue.replace(/[^\d]/g, '');
    
    let returnValue = textValue;
    if (returnValue === '') {
      // Do nothing
    } else if (returnValue === '> 20') {
      returnValue = 21;
    } else {
      try {
        returnValue = parseInt(returnValue, 10);
        if (isNaN(returnValue)) returnValue = '';
        if (returnValue < 0) returnValue = -1;
        if (returnValue > 20) returnValue = 21;
      } catch {
        returnValue = '';
      }
    }

    setFloor(String(returnValue));
    if (sliderRef.current) {
      sliderRef.current.noUiSlider.set(returnValue === "" ? -1 : returnValue);
    }
    e.target.value = returnValue;
    props.handleChange(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(e);
  };

  const getTextBoxValue = (value) => {
    if (value > 20) return '> 20';
    //if (value < 0) return '-';
    return String(value);
  };

  const fullSliderLayout = () => (
    <div>
      <div ref={sliderRef} className="insights-slider"></div>
      <form action="" onSubmit={handleSubmit}>
        <div className="input-field">
          <input
            type="text"
            id="floor"
            value={getTextBoxValue(floor)}
            onChange={handleFloorChange}
            style={{ textAlign: 'center', paddingRight: '0px' }}
            className="no-arrows-input"
          />
        </div>
      </form>
      <FeatureImpact impact={props.analysis.purchase_analysis.floor.impact} />
    </div>
  );

  const minifiedSliderLayout = () => (
    <div>
      <div ref={sliderRef} className="insights-slider"></div>
      <form action="" onSubmit={handleSubmit}>
        <input
          type="text"
          id="floor"
          value={getTextBoxValue(floor)}
          onChange={handleFloorChange}
          style={{ textAlign: 'center', paddingRight: '0px' }}
          className="no-arrows-input"
        />
      </form>
    </div>
  );

  return props.minifiedLayout ? minifiedSliderLayout() : fullSliderLayout();
};

export default FloorSlider;
