import React, { Component } from 'react'

import InsightsEvalFormHeader from './InsightsEvalFormHeader';
import InsightsEvalFormFooter from './InsightsEvalFormFooter';
import InsightsEvalFormNavigationButtons from './InsightsEvalFormNavigationButtons';

import FeatureImpact from './UiElements/FeatureImpact';


class InsightsEnergyCertificateTypeForm extends Component {
  state = {
    submitButtonClicked: false,
  }


  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      submitButtonClicked: true
    })

    // Allow the next step if there are either no errors or the (optional) field is empty
    if (this.props.errors.energy_certificate_type !== true || this.props.values.energy_certificate_type === '') {
      this.props.nextStep();
    }
  }


  handleChange = (e) => {
    // Check if the value is valid
    let value = null;
    if (!e?.target?.value) return;
    try {
      value = e.target.value;
      const errors = this.props.validateInput(value);
      if (errors.energy_certificate_type) return;
    }
    catch {
      return;
    }

    // Check if the value has already been selected and should now be unselected.
    if (String(value) === String(this.props.values.energy_certificate_type)) {
      value = "";
    }

    // The input is valid.
    // Create a synthetic event and emit it.
    const syntheticEvent = {
      target: {
        id: e.target.name,
        value: value
      }
    }
    this.props.handleChange(syntheticEvent);
  }


  renderCard = (value, text) => {
    return (
        <div className="col s12">
          <label>
            <input type="radio" name="energy_certificate_type" id={"energy_certificate_type_" + value} value={value} checked={this.props.values.energy_certificate_type === value} className="insights-card-input-element" onClick={this.handleChange} onChange={() => {}}/>
            <div className="card insights-card-input">
              <div className="card-content insights-card-content">
                <p style={{"fontWeight":"normal", "fontSize":"1.4rem"}}>{text}</p>
              </div>
            </div>
          </label>
        </div>
    )
  }


  render() {
    const analysis = this.props.analysis;

    return (
      <div>

        <InsightsEvalFormHeader />

        <div className="container">

          <div className="row">
            <div className="col s12">
              <h5>Energieausweis</h5>
            </div>
          </div>

          <div className="insights-eval-form-content">

            <div className="row">
              <div className="col s12">
                { this.renderCard("VERBRAUCHSAUSWEIS", "Verbrauchsausweis") }
                { this.renderCard("BEDARFSAUSWEIS", "Bedarfsausweis") }
              </div>
            </div>

            <div className="row">
              <div className="col s12">
                <FeatureImpact
                  impact={this.props.analysis.purchase_analysis.energy_certificate_type.impact}
                />
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col s12">
              <InsightsEvalFormNavigationButtons
                previousStep={this.props.previousStep}
                skipToEnd={this.props.skipToEnd}
                handleSubmit={this.handleSubmit}
                analysis={analysis}
                />
            </div>
          </div>

        </div>

        <div className="divider" />
        <div className="row">
          <div className="col s12">
            <InsightsEvalFormFooter
              previousStep={this.props.previousStep}
              skipToEnd={this.props.skipToEnd}
              handleSubmit={this.handleSubmit}
              analysis={analysis}
              />
          </div>
        </div>

      </div>
    )
  }
}

export default InsightsEnergyCertificateTypeForm;
