import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBuilding } from "@fortawesome/free-solid-svg-icons";

import InsightsEvalFormHeader from './InsightsEvalFormHeader';
import InsightsEvalFormFooter from './InsightsEvalFormFooter';

import Spacer from '../../components/Spacer';

//import BadgeImage from '../../assets/images/badges/insights-badge-no-contact-data.png';

class InsightsObjectTypeForm extends Component {

  handleButtonClickHK = (e) => {
    // Simulate event
    this.props.handleChange({
      target: {
        id: "objectType",
        value: "HK",
      }
    })
    this.props.nextStep();
  }
  handleButtonClickWK = (e) => {
    // Simulate event
    this.props.handleChange({
      target: {
        id: "objectType",
        value: "WK"
      }
    })
    this.props.nextStep();
  }

  render() {
    const analysis = this.props.analysis;

    return (
      <div>

        <InsightsEvalFormHeader />

        <div className="container">

          <div className="row">
            <div className="col s12 insights-badge-container">
              <h5>Haus oder Wohnung?</h5>
            </div>
          </div>





          <div className="insights-eval-form-content">

            <div className="row">
              <div className="col s12 insights-badge-container">
                <div style={{"height":"100px"}}>
                  {/*
                  <img src={BadgeImage} alt="Jetzt Immobilien bewerten" className="responsive rotate10" style={{"width":"50%"}}/>
                  */}

                  {/*
                  */}
                  <div className="insights-badge rotate10 red white-text">Keine Kontaktdaten.<br></br>Ergebnis sofort.</div>

                </div>
              </div>
            </div>

            <div className="row">
              <div className="col s6">
                <button id="objectTypeWK" className="btn waves-effect center grey lighten-5 blue-text text-darken-1 objectTypeButton" onClick={this.handleButtonClickHK}><FontAwesomeIcon icon={faHome} style={{"fontSize":"3rem"}}>home</FontAwesomeIcon><br></br><span className="black-text">Haus</span></button>
              </div>
              <div className="col s6">
                <button id="objectTypeHK" className="btn waves-effect center grey lighten-4 blue-text text-darken-1 objectTypeButton" onClick={this.handleButtonClickWK}><FontAwesomeIcon icon={faBuilding} style={{"fontSize":"3rem"}}>home</FontAwesomeIcon><br></br><span className="black-text">Wohnung</span></button>
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col s12">
              <Spacer height="21px" />
            </div>
          </div>

        </div>


        <div className="divider" />
        <div className="row">
          <div className="col s12">
            <InsightsEvalFormFooter
              hidePreviousStepButton={true}
              hideSubmitButton={true}
              analysis={analysis}
              placeholderText={"Erstes Ergebnis in nur zwei Schritten."}
            />
          </div>
        </div>


      </div>
    )
  }
}

export default InsightsObjectTypeForm
