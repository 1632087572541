import NewsletterSignupForm from '../../components/Newsletter/NewsletterSignupForm';

import Spacer from '../../components/Spacer';

function NewsletterSignupPage(props) {
  return (
    <div>
      <div className="container">
        <Spacer height="50px" />
      </div>

      <div className="container">
        <div className="row">
          <div className="col s12 l6 offset-l3 insights-container white" style={{"padding":"40px"}}>
            <div className="section">
              <h4>Newsletter</h4>
              <p className="flex-text">Mit unserem Newsletter bleiben Sie immer auf dem Laufenden und erfahren als erstes, wenn wir neue Funktionen freigeschaltet haben.</p>
              <NewsletterSignupForm />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <Spacer height="50px" />
      </div>
    </div>
  )
}

export default NewsletterSignupPage;
