// Config file
// NODE_ENV is set by the react build process
switch(process.env.NODE_ENV) {

  /************
   * PRODUCTION
   ***********/
  case 'production':
    module.exports = {
      API_ROOT: 'https://www.immovestics.com/api/v1.0',
      API_BACKUP_ROOT: 'https://api2.immovara.com/api/v1.0'
    };
    break;
  
  /************************
   * Development or not set
   ***********************/
 default:
    module.exports = {
      API_ROOT: 'http://localhost:5003/api/v1.0',
      //API_ROOT: 'http://localhost:8000/api/v1.0',
      API_BACKUP_ROOT: 'http://localhost:5003/api/v1.0',

      AXIOS_CONFIG: {
        withCredentials: true,
        headers: {
          'content-type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        }
      }
    };
    break;
}
