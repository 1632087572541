import React, { Component } from 'react'
import M from 'materialize-css'

export default class InsightsDropdown extends Component {

  componentDidMount() {
    const elems = document.querySelectorAll('select');
    M.FormSelect.init(elems);
  }


  handleChange = (e) => {
    // Check if the value is valid
    let value = null;
    if (!e?.target?.value && e?.target?.value !== "") return; // !"" is true - so ignore it in case of resetting the attribute
    try {
      value = e.target.value;
      if (value !== "") { // do not check the empty state as this is used to reset the attribute
        const errors = this.props.validateInput(value);
        if (errors[this.props.attributeId]) return;
      }
    }
    catch {
      return;
    }

    // The input is valid.
    // Create a synthetic event and emit it.
    const syntheticEvent = {
      target: {
        id: e.target.name,
        value: value
      }
    }
    this.props.handleChange(syntheticEvent);
  }


  getInputFieldOption = (value, text) => {
    return (
      <option key={text} value={value}>{text}</option>
    )
  }

  renderDropdown = () => {
    return (
      <div>
        <div className="input-field insights-input-field-select">
          <select id={this.props.attributeId} name={this.props.attributeId} onChange={this.handleChange} defaultValue={this.props.value}>
            { this.props.valuePairs.map(valuePair => {
              return this.getInputFieldOption(valuePair.value, valuePair.text)
            })}
          </select>
        </div>
      </div>
    )
  }

  render() {
    return this.renderDropdown();
  }
}
