import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_ROOT } from '../../config/config';

class NewsletterSignupForm extends Component {

  state = {
    submitClicked: false, // submit button clicked at least once?
    formValid: false, // true if the form has been submitted
    submitSucceeded: false, // true if post to backend was successful

    email: "",
    consent: false,

    errors: {
      email: null,
      consent: null
    }
  }

  validateInputs = (state) => {
    // Returns a dictionary with booleans for each input variable.
    // In case of an error, the corresponding variable is true.
    const errors = {};
    errors.count = 0;

    if ("email" in state) {
      var emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!emailPattern.test(state.email)) {
        errors.email = true;
        errors.count += 1;
      }
    }

    if ("consent" in state) {
      if (!state.consent) {
        errors.consent = true;
        errors.count += 1;
      }
    }

    return errors;
  }

  handleChange = (e) => {
    const newState = {};
    newState.formValid = false; // form has changed
    newState.submitSucceeded = false; // form has changed

    // Checkbox value must be handled differently
    if (e.target.id === "consent") {
      newState[e.target.id] = e.target.checked;
    }
    else if (e.target.id === "email") {
      newState[e.target.id] = e.target.value.trim();
    }
    else { // default
      newState[e.target.id] = e.target.value;
    }

    // If the submit button has been clicked at least once but there were errors,
    // reevaluate the new inputs for potential errors.
    if (this.state.submitClicked) {
      const newErrors = this.validateInputs({...this.state, ...newState});
      newState.errors = newErrors;
    }

    this.setState(newState);
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = this.validateInputs(this.state);
    if (newErrors.count === 0) {
      // TODO Call api
      axios.post(API_ROOT + '/newsletter/subscribe', {
        email: this.state.email,
        consent: this.state.consent
      })
        .then((res) => {
          // Reset form
          this.setState({
            submitClicked: false, // reset state because the form is resetted to a fresh state
            formValid: true,
            submitSucceeded: true,

            email: "",
            consent: false
          })
        })
        .catch((err) => {
          console.log("Error:", err)
          this.setState({
            submitClicked: true,
            formValid: true,
            submitSucceeded: false,
          })
        });

    }
    else { // errors in form validation
      this.setState({
        submitClicked: true,
        formValid: false,
        submitSucceeded: false,

        errors: newErrors
      })
    }
  }

  render() {
    const form = (
      <div>
        <form action="" onSubmit={this.handleSubmit}>

          <div className="input-field">
            <i className="material-icons prefix">email</i>
            <input id="email" type="text" value={this.state.email} onChange={this.handleChange} />
            <label htmlFor="email">E-Mail</label>
            { this.state.errors.email ? <span className="helper-text" style={{color: "red"}}>Bitte überprüfen Sie Ihre E-Mail Adresse.</span> : null }
          </div>

          <div className="input-field">
            <p>
              <label>
                <input id="consent" type="checkbox" checked={this.state.consent} onChange={this.handleChange} />
                <span>Ich möchte Ihren Newsletter erhalten und akzeptiere die <Link to="/datenschutz" target="_blank">Datenschutzerklärung</Link>.</span>
                { this.state.errors.consent ? <span className="helper-text" style={{color: "red"}}>Bitte stimmen Sie den Datenschutzbestimmungen zu.</span> : null }
              </label>
            </p>
            <p>
              <span style={{fontSize: "small"}}>Sie können den Newsletter jederzeit über den Link in unserem Newsletter abbestellen.</span>
            </p>
            <p>
              <span style={{fontSize: "small"}}>Wir verwenden Sendinblue als unsere Marketing-Plattform. Wenn Sie das Formular ausfüllen und absenden, bestätigen Sie, dass die von Ihnen angegebenen Informationen an Sendinblue zur Bearbeitung gemäß den <a href="https://de.sendinblue.com/legal/termsofuse/" target="_blank" rel="noreferrer">Nutzungsbedingungen</a> übertragen werden.</span>
            </p>

          </div>

          <div className="input-field center">
            <button className="btn-large blue darken-1">Anmelden</button>
          </div>
          <div className="center">
            { this.state.submitClicked && this.state.formValid && !this.state.submitSucceeded ? <p className="text-floating" style={{color: "red"}}>Es gab einen Fehler bei der Übermittlung. Bitte versuchen Sie es noch einmal.</p> : null }
          </div>

        </form>
      </div>
    )
    
    const thankYou = (
      <div className="center">
        <h4 style={{color: "green"}}>Herzlich Willkommen!</h4>
        <h5>Wir freuen uns, dass Sie dabei sind</h5>
      </div>
    )

    return this.state.submitSucceeded ?
      (
        thankYou
      ) : (
        form
      )
  }
}

export default NewsletterSignupForm;
