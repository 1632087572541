import React, { Component } from 'react'
import M from 'materialize-css'

import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_ROOT } from '../../config/config';
import withUser from './withUser';


class PasswordReset extends Component {
  state = {
    submitClicked: false, // submit button clicked at least once?
    callFailed: false,
    callSucceeded: false, // true if the API call was successful

    userId: '',
    resetKey: '',
    password: '',

    errors: {
      count: null,
      password: null,
    }
  };

  componentDidMount = () => {
    // Ensure that label does not overlap with input when prefilled
    M.updateTextFields();

    // Redirect away from login page if user is already logged in
    if (this.props.user) {
      this.props.history.push('/');
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      callFailed: false,
      submitClicked: true
    }, async () => {

      // If there are no errors, call the api
      if (this.state.errors.count === 0) {
        const config = {
          headers: {
            'content-type': 'application/json'
          }
        };

        try {
          const res = await axios.post(API_ROOT + '/user/password_reset', {
            user_id: this.props.userId,
            reset_key: this.props.resetKey,
            password: this.state.password
          }, config);
          if (res) {
            // Trigger the message that the password reset email has been sent
            this.setState({
              callSucceeded: true
            })
          }
        }
        catch (err) {
          this.setState({
            callFailed: true
          })
        };

      }

    });
  }

  logout = () => {
    const config = {
      headers: {
        'content-type': 'application/json'
      }
    };
    axios.post(API_ROOT + '/user/logout', {}, config)
    .then((res) => {
      console.log(res)
      localStorage.removeItem('user');
    })
    .catch((err) => {
      console.log("Error:", err)
    });
  }

  handleChange = (e) => {
    const newState = {};
    newState[e.target.id] = e.target.value

    const newErrors = this.validateInputs({...this.state, ...newState});
    newState.errors = newErrors;

    this.setState(newState, ()=>{
      //console.log(this.state)
    });
  }

  validateInputs = (state) => {
    const errors = {};
    errors.count = 0;

    if ("password" in state) {
      if (state["password"].length === 0) {
        errors.password = true;
        ++errors.count;
      }
    }

    // Also validate the props
    if (this.props.userId === undefined || this.props.userId === null || this.props.userId === "") {
      ++errors.count;
    }
    if (this.props.resetKey === undefined || this.props.resetKey === null || this.props.resetKey === "") {
      ++errors.count;
    }

    return errors;
  };


  showForm = () => {
    return(
      <div>
        <form action="" onSubmit={this.handleSubmit} >

          <div className="input-field">
            <label htmlFor="password">Passwort</label>
            <input id="password" name="password" type="password" onChange={this.handleChange} />
            { this.state.submitClicked && this.state?.errors?.password ? <span className="helper-text" style={{color: "red"}}>Bitte überprüfen Sie Ihr Passwort.</span> : null }
          </div>

          <div className="center" style={{"marginTop":"20px"}}>
          </div>

          <div className="center">
            <button className="btn-large blue darken-1">Passwort ändern</button>
          </div>

          <div className="center">
            { this.state.submitClicked && this.state.callFailed ? (
              <div style={{"paddingTop":"20px", "paddingBottom":"20px"}}>
                <span className="helper-text" style={{color: "red"}}>Es gab leider einen Fehler. Bitte versuchen Sie es noch einmal oder fordern Sie ein neues Passwort an.</span>
                <div style={{"paddingTop":"20px"}}>
                  <Link to="/passwordresetrequest" className="btn-large red lighten-1">Passwort zurücksetzen</Link>
                </div>
              </div>
            ) : null }
          </div>

        </form>

      </div>
    )
  }


  showSuccessMessage = () => {
    return(
      <div>
        <p className="flex-text green-text" style={{"fontWeight":"bold"}}>Ihr Passwort wurde erfolgreich geändert.</p>
        <div className="center">
          <Link to="/login" className="btn-large blue darken-1">Jetzt einloggen</Link>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.callSucceeded) {
      return (
        this.showSuccessMessage()
      )
    }
    else {
      return (
        this.showForm()
      )
    }
  }
};

export default withUser(PasswordReset);
