import React, { Component } from 'react'

import InsightsEvalFormHeader from './InsightsEvalFormHeader';
import InsightsEvalFormFooter from './InsightsEvalFormFooter';
import InsightsEvalFormNavigationButtons from './InsightsEvalFormNavigationButtons';

import EnergyEfficiencyClassSlider from './UiElements/EnergyEfficiencyClassSlider';


class InsightsEnergyEfficiencyClassForm extends Component {
  state = {
    submitButtonClicked: false,
  }


  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      submitButtonClicked: true
    })

    // Allow the next step if there are either no errors or the (optional) field is empty
    if (this.props.errors.energy_efficiency_class !== true || this.props.values.energy_efficiency_class === '') {
      this.props.nextStep();
    }
  }


  handleChange = (e) => {
    // Check if the value is valid
    let value = null;
    if (!e?.target?.value) return;
    try {
      value = e.target.value;
      const errors = this.props.validateInput(value);
      if (errors.energy_efficiency_class) return;
    }
    catch {
      return;
    }

    // The input is valid.
    // Create a synthetic event and emit it.
    const syntheticEvent = {
      target: {
        id: e.target.name,
        value: value
      }
    }
    this.props.handleChange(syntheticEvent);
  }


  render() {
    const analysis = this.props.analysis;

    return (
      <div>

        <InsightsEvalFormHeader />

        <div className="container">

          <div className="row">
            <div className="col s12">
              <h5>Energieeffizienzklasse</h5>
            </div>
          </div>

          <div className="insights-eval-form-content">

            <div className="row">
              <div className="col s10 offset-s1">
                <EnergyEfficiencyClassSlider
                  handleChange={this.props.handleChange}
                  handleSubmit={this.handleSubmit}
                  values={this.props.values}
                  analysis={this.props.analysis}
                />
                {/* this.state.submitButtonClicked && this.props.errors.energy_efficiency_class ? <div className="col s11"><span className="helper-text" style={{color: "red"}}>Bitte geben Sie einen gültigen Wert ein.</span></div> : null */}
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col s12">
              <InsightsEvalFormNavigationButtons
                previousStep={this.props.previousStep}
                skipToEnd={this.props.skipToEnd}
                handleSubmit={this.handleSubmit}
                analysis={analysis}
                />
            </div>
          </div>

        </div>

        <div className="divider" />
        <div className="row">
          <div className="col s12">
            <InsightsEvalFormFooter
              previousStep={this.props.previousStep}
              skipToEnd={this.props.skipToEnd}
              handleSubmit={this.handleSubmit}
              analysis={analysis}
              />
          </div>
        </div>

      </div>
    )
  }
}

export default InsightsEnergyEfficiencyClassForm;
