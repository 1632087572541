import React from 'react'
import LoginVerification from '../../components/User/LoginVerification';

function LoginVerificationPage(props) {
  return (
    <div>
      <LoginVerification />
    </div>
  )
};

export default LoginVerificationPage;
