import React, { Component } from 'react'

import Textfit from '@namhong2001/react-textfit';
//import Textfit from "react-textfit/lib/Textfit";

import InsightsEvalFormHeader from './InsightsEvalFormHeader';
import InsightsEvalFormFooter from './InsightsEvalFormFooter';
import InsightsEvalFormNavigationButtons from './InsightsEvalFormNavigationButtons';

import InsightsDropdown from './UiElements/InsightsDropdown';
import FeatureImpact from './UiElements/FeatureImpact';

class InsightsObjectStateForm extends Component {
  state = {
    submitButtonClicked: false,
  }


  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      submitButtonClicked: true
    })

    // Allow the next step if there are either no errors or the (optional) field is empty
    if (this.props.errors.objectState !== true || this.props.values.objectState === '') {
      this.props.nextStep();
    }
  }


  handleChange = (e) => {
    // NOTE: DELETE in case the cards are not needed anymore
    // Check if the value is valid
    let value = null;
    if (!e?.target?.value) return;
    try {
      value = e.target.value;
      const errors = this.props.validateInput(value);
      if (errors.objectState) return;
    }
    catch {
      return;
    }

    // Check if the value has already been selected and should now be unselected.
    if (String(value) === String(this.props.values.objectState)) {
      value = "";
    }

    // The input is valid.
    // Create a synthetic event and emit it.
    const syntheticEvent = {
      target: {
        id: e.target.name,
        value: value
      }
    }
    this.props.handleChange(syntheticEvent);
  }


  renderCard = (value, text, fullSpan=false) => {
    const col = fullSpan === true ? "col s12" : "col s6";
    return (
        <div className={"col " + col}>
          <label>
            <input type="radio" name="objectState" id={"object_state_" + value} value={value} checked={this.props.values.objectState === value} className="insights-card-input-element" onClick={this.handleChange} onChange={() => {}}/>
            <div className="card insights-card-input">
              <div className="card-content insights-card-content">
                <Textfit max={19} className="insights-card-text">{text}</Textfit>
              </div>
            </div>
          </label>
        </div>
    )
  }


  renderObjectStates = () => {
    return (
      <div>
        <div className="row insights-card-row">
          { this.renderCard("ERSTBEZUG", "Erstbezug") }
          { this.renderCard("NEUWERTIG", "Neuwertig") }
        </div>

        <div className="row insights-card-row">
          { this.renderCard("GEPFLEGT", "Gepflegt") }
          { this.renderCard("RENOVIERT", "Renoviert") }
        </div>

        <div className="row insights-card-row">
          { this.renderCard("SANIERT", "Saniert") }
          { this.renderCard("MODERNISIERT", "Modernisiert") }
        </div>

        <div className="row insights-card-row">
          { this.renderCard("RENOVIERUNGSBEDÜRFTIG", "Renovierungsbedürftig", true) }
          {/* this.renderCard("ABBRUCHREIF", "Abbruchreif") */}
        </div>
      </div>
    );
  }



  renderObjectStatesDropdown = () => {
    const attributeId = "objectState";
    const value = this.props.values.objectState;
    const valuePairs = [
      { value: "", text: "Keine Angabe" },
      { value: "ERSTBEZUG", text: "Erstbezug" },
      { value: "NEUWERTIG", text: "Neuwertig" },
      { value: "MODERNISIERT", text: "Modernisiert" },
      { value: "RENOVIERT", text: "Renoviert" },
      { value: "SANIERT", text: "Saniert" },
      { value: "GEPFLEGT", text: "Gepflegt" },
      { value: "RENOVIERUNGSBEDÜRFTIG", text: "Benötigt Renovierung" },
      //{ value: "ABBRUCHREIF", text: "Abbruchreif" },
    ];
    return (
      <InsightsDropdown
        attributeId={attributeId}
        valuePairs={valuePairs}
        value={value}
        handleChange={this.props.handleChange}
        validateInput={this.props.validateInput}
      />
    )
  }



  render() {
    //const { } = this.props.values;
    const analysis = this.props.analysis;
    //const objectStateError = this.props.errors.objectState;

    return (
      <div>

        <InsightsEvalFormHeader />

        <div className="container">

          <div className="row">
            <div className="col s12">
              <h5>Zustand</h5>
            </div>
          </div>

          <div className="insights-eval-form-content">

            <div className="row">
              {
                this.renderObjectStates()
                //this.renderObjectStatesDropdown()
              }
            </div>


            {/* this.state.submitButtonClicked && objectStateError ? <div className="col s12"><span className="helper-text" style={{color: "red"}}>Bitte geben Sie eine gültige Angabe ein.</span></div> : null */}

            <div className="row">
              <div className="col s12">
                <FeatureImpact
                  impact={this.props.analysis.purchase_analysis.object_state.impact}
                />
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col s12">
              <InsightsEvalFormNavigationButtons
                previousStep={this.props.previousStep}
                skipToEnd={this.props.skipToEnd}
                handleSubmit={this.handleSubmit}
                analysis={analysis}
                />
            </div>
          </div>

        </div>

        <div className="divider" />
        <div className="row">
          <div className="col s12">
            <InsightsEvalFormFooter
              previousStep={this.props.previousStep}
              skipToEnd={this.props.skipToEnd}
              handleSubmit={this.handleSubmit}
              analysis={analysis}
              />
          </div>
        </div>

      </div>
    )
  }
}

export default InsightsObjectStateForm;
