import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import Spacer from '../../components/Spacer';

import Chrome1 from '../../assets/images/browser/chrome/browser_step_1.jpg';
import Chrome2 from '../../assets/images/browser/chrome/browser_step_2.jpg';
import Chrome3 from '../../assets/images/browser/chrome/browser_step_3.jpg';

export default function Browser() {
  return (
    <div>
      <Helmet>
        <title>Immobilienbewertung direkt im Browser | Immovestics</title>
        <meta name="description" content="Bewerten Sie Immobilien interaktiv und in wenigen Sekunden für Ihre Immobilien Investments" />
      </Helmet>

      <Spacer height="50px" />

      <div className="container browser-page">

        <div className="row">
          <div className="col s12 m10 offset-m1">
            <h2>Browser Extension</h2>
          </div>
        </div>

        <div className="row">
          <div className="col s12 m10 offset-m1">
            <h4>Installieren</h4>
            <p><a href="https://chrome.google.com/webstore/detail/immovestics-insights/eicoigpidifefdbbijhbgcmabnbjnfai" target="_blank" rel="noopener noreferrer" className="blue-text text-darken-1">Installieren</a> Sie die Chrome Browser Extension kostenlos im Chrome Web Store. Welcher Browser soll nach Chrome als nächstes <Link to="/kontakt">unterstützt</Link> werden?</p>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m10 offset-m1 center">
            <img src={Chrome1} alt="Installation im Chrome Web Store" className="responsive" />
          </div>
        </div>
        <div className="row">
          <div className="col s12 m10 offset-m1 center">
            <div className="center">
              <a href="https://chrome.google.com/webstore/detail/immovestics-insights/eicoigpidifefdbbijhbgcmabnbjnfai" target="_blank" rel="noopener noreferrer" className="btn btn-large blue darken-1">Zum Chrome Web Store</a>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col s12 m10 offset-m1">
            <h4>Einrichten</h4>
            <p>Die Erweiterung sollte sichtbar sein, so dass Sie von den Schnell-Analysen direkt profitieren können und nichts übersehen.</p>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m10 offset-m1 center">
            <img src={Chrome2} alt="Konfiguration der Erweiterung" className="responsive" />
          </div>
        </div>

        <div className="row">
          <div className="col s12 m10 offset-m1">
            <h4>Nutzen</h4>
            <p>Und los geht es! Aktuell unterstützen wir <a href="https://www.immobilienscout24.de/" target="_blank" rel="noopener noreferrer">ImmoScout24</a>. Welche weiteren Seiten <Link to="/kontakt">wünschen</Link> Sie sich?</p>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m10 offset-m1 center">
            <img src={Chrome3} alt="Nutzung der Erweiterung auf Immobilien Seiten" className="responsive" />
          </div>
        </div>

        <div className="row">
          <div className="col s12 m10 offset-m1">
            <h4>Fragen?</h4>
            <p>Haben Sie Fragen oder Anregungen zur Nutzung? Wir freuen uns über Ihre Nachricht!</p>
            <div className="center">
              <Link to="/kontakt" className="btn blue darken-1">Zum Kontaktformular</Link>
            </div>
          </div>
        </div>

      </div>

      <Spacer height="50px" />

    </div>
  )
}
