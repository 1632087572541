import React, { useEffect, useRef } from 'react';
import M from 'materialize-css';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import wNumb from 'wnumb';
import FeatureImpact from './FeatureImpact';

const PropertySizeSlider = (props) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    const propertySizeSlider = sliderRef.current;
    if (propertySizeSlider) {
      const initialValue = props.values.propertySize ? props.values.propertySize : 0;
      
      noUiSlider.create(propertySizeSlider, {
        start: [initialValue],
        connect: [true, false],
        tooltips: [true],
        range: {
          min: 0,
          max: 3000,
        },
        step: 1,
        format: wNumb({
          decimals: 0,
          edit: (value) => {
            if (value < 1) return '-';
            return String(value);
          },
        }),
      });

      propertySizeSlider.noUiSlider.on('change', (value) => {
        let changedValue = value[0];
        if (changedValue === '-') changedValue = ''; // reset state
        props.handleChange({
          target: {
            id: 'propertySize',
            value: changedValue,
          },
        });
        M.updateTextFields();
      });

      M.updateTextFields();

      // Cleanup on unmount
      return () => {
        if (propertySizeSlider.noUiSlider) {
          propertySizeSlider.noUiSlider.destroy();
        }
      };
    }
  }, [props]);

  useEffect(() => {
    if (sliderRef.current && sliderRef.current.noUiSlider) {
      const newValue = props.values.propertySize ? props.values.propertySize : 0;
      sliderRef.current.noUiSlider.set(newValue);
    }
  }, [props.values.propertySize]);

  const handlePropertySizeChange = (e) => {
    let newValue = e.target.value;
    if (newValue === '') {
      // Do nothing
    } else if (newValue < 1) {
      newValue = 1;
    } else if (newValue > 10000) {
      newValue = 10000;
    }
    e.target.value = newValue;

    if (sliderRef.current) {
      sliderRef.current.noUiSlider.set(newValue);
    }

    props.handleChange(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(e);
  };

  const fullSliderLayout = () => (
    <div>
      <div ref={sliderRef} className="insights-slider"></div>
      <form action="" onSubmit={handleSubmit}>
        <div className="input-field">
          <input
            type="number"
            step="1"
            id="propertySize"
            value={props.values.propertySize}
            onChange={handlePropertySizeChange}
            style={{ textAlign: 'center', paddingRight: '0px' }}
            className="no-arrows-input"
          />
          <span style={{ marginLeft: '-20px' }}>
          m²</span>
        </div>
      </form>
      <FeatureImpact impact={props.analysis.purchase_analysis.property_size.impact} />
    </div>
  );

  const minifiedSliderLayout = () => (
    <div>
      <div ref={sliderRef} className="insights-slider"></div>
      <form action="" onSubmit={handleSubmit}>
        <input
          type="number"
          step="1"
          id="propertySize"
          value={props.values.propertySize}
          onChange={handlePropertySizeChange}
          style={{ textAlign: 'center', paddingRight: '0px' }}
          className="no-arrows-input"
        />
        <span style={{ marginLeft: '-20px' }}>m²</span>
      </form>
    </div>
  );

  return props.minifiedLayout ? minifiedSliderLayout() : fullSliderLayout();
};

export default PropertySizeSlider;
