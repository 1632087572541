import React, { Component } from 'react'
import M from 'materialize-css';

export default class AdditionalCostsInput extends Component {
  constructor(props) {
    super(props);

    const value = this.props.value !== "" ? this.props.value * 100. : "";
    this.state = {
      localCosts: value
    }

    this.timer = null;
  }

  componentDidMount() {
    // Ensure that label does not overlap with input when prefilled
    M.updateTextFields();
  }

  handleChange = (e) => {

    if (e?.target?.value === undefined || e?.target?.value === null || typeof(e?.target?.value) !== 'string') return;

    // Input can only be numbers. Ensure now that it is not negative and that the length fits for a purchase price.
    e.target.value = e.target.value.replace('-', '');
    if (e.target.value.length > 8) e.target.value = e.target.value.slice(0,8);

    let value = "";
    let value_pct = ""; // same as value but divided by 100
    try {
      value = parseFloat(e.target.value);
      value_pct = value / 100.;
      if (isNaN(value)) {
        value = "";
        value_pct = "";
      }
    }
    catch {
      value = "";
        value_pct = "";
    }

    // In case of very fast inputs, e.g., many zeros, the input field will
    // show wrong values, potentially due to race conditions.
    // Therefore, use a local state to show in the input field and use a timeout
    // to avoid spamming the update function. As an additional benefit, this will
    // reduce load on the server.
    this.setState({
      localCosts: value
    }, () => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        return this.props.handleChange(value_pct);
      }, 500)
    });

  };


  render() {
    return (
      <div>
        <form action="" onSubmit={this.props.handleSubmit}>
          <div className="input-field">
            <input type="number" className="no-arrows-input" id={"pct_"+this.props.text} value={this.state.localCosts} onChange={this.handleChange} />
            <span style={{"marginLeft":"-20px"}}>%</span>
            <label htmlFor={"pct_"+this.props.text}>{this.props.text}</label>
          </div>
        </form>
      </div>
    )
  }
}
