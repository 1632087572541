import React, { Component } from 'react'
import M from 'materialize-css'
import wNumb from 'wnumb';

const returnFormat = wNumb({
  decimals: 1,
	mark: ',',
	thousand: '.',
	prefix: '',
	suffix: '',
  encoder: (value) => {
    return value * 100.;
  },
  decoder: (value) => {
    return value / 100.;
  }
});

const additionalCostsPctFormat = wNumb({
  decimals: 2,
	mark: ',',
	thousand: '.',
	prefix: '',
	suffix: '',
  encoder: (value) => {
    return value * 100.;
  },
  decoder: (value) => {
    return value / 100.;
  }
});

const factorFormat = wNumb({
  decimals: 0,
	mark: ',',
	thousand: '.',
	prefix: '',
	suffix: ''
});

const rentFormat = wNumb({
  decimals: 2,
	mark: ',',
	thousand: '.',
	prefix: '',
	suffix: ''
});

const purchasePriceFormat = wNumb({
  decimals: 0,
	mark: ',',
	thousand: '.',
	prefix: '',
	suffix: ''
});

class InsightsEvalFormFooter extends Component {

  componentDidMount() {
    // Initialize modal
    const options = {
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: true,
      startingTop: "5%",
      endingTop: "10%"
    }
    var elems = document.querySelectorAll('.modal');
    M.Modal.init(elems, options);

    // Initialize tooltips
    var tooltips = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltips);
  }

  componentDidUpdate() {
    // Initialize tooltips - suboptimal to have it here, but in componentDidMount it is not working
    var tooltips = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltips);
  }


  getWarnSign = (count) => {
    if (count >= 10) return null;
    if (count === 0 || count === null) return <span><i className="material-icons orange-text text-lighten-3 tooltipped" data-position="bottom" data-tooltip="Nicht genügend Daten verfügbar" style={{"verticalAlign":"bottom"}}>error</i></span>

    return <span><i className="material-icons orange-text text-lighten-3 tooltipped" data-position="bottom" data-tooltip="Schätzung möglicherweise ungenau" style={{"verticalAlign":"bottom"}}>error</i></span>
  }


  colorCodeFromDeviation = (value, lower, upper, actual) => {
    // Define the colors
    const greyColor = "#9e9e9e";
    const redStrongColor = "#f44336";
    const redLightColor = "#e57373";
    const greenStrongColor = "#4caf50";
    const greenLightColor = "#81c784";

    if (typeof(value) !== 'number' || isNaN(value)) {
      return null;
    }
    if (typeof(actual) !== 'number' || isNaN(actual)) {
      return null;
    }

    // Derive upper and lower in case it is not available
    if (lower === null || lower === undefined || lower === value ||
        upper === null || upper === undefined || upper === value) {
      lower = value * 0.90;
      upper = value * 1.10;
    }
    const halfBracketSize = (upper - lower) / (2.0 * 2.0); // take half because we are interested in which quarter it falls

    const bracketFactor = (value - actual) / halfBracketSize;


    if (bracketFactor < -1.0) return redStrongColor;
    if (bracketFactor < -0.5) return redLightColor;
    if (bracketFactor < 0.5) return greyColor;
    if (bracketFactor < 1.0) return greenLightColor;
    if (bracketFactor >= 1.0) return greenStrongColor;
    return null; // fail-safe
  }


  getPriceString = (prices, formatter, suffix, preferPlusMinus=true) => {
    const estimatedPurchasePricePerSqm = prices.value;
    const estimatedLowerPurchasePricePerSqm = prices.lower;
    const estimatedUpperPurchasePricePerSqm = prices.upper;

    let priceDiff = null;
    if(estimatedLowerPurchasePricePerSqm === null || estimatedUpperPurchasePricePerSqm === null) {
      priceDiff = null;
    }
    else if (estimatedLowerPurchasePricePerSqm === estimatedUpperPurchasePricePerSqm) {
      priceDiff = null;
    }
    else {
      priceDiff = (estimatedUpperPurchasePricePerSqm - estimatedLowerPurchasePricePerSqm) / 2.0;
    }

    if (estimatedPurchasePricePerSqm !== null && priceDiff !== null) {
      // Estimation available and true price diff exists (i.e., upper and lower exist and are different)
      if (preferPlusMinus) {
        const plusMinus = Math.abs(estimatedUpperPurchasePricePerSqm - estimatedLowerPurchasePricePerSqm) / 2.0;
        const text = `${formatter.to(estimatedPurchasePricePerSqm)} ± ${formatter.to(plusMinus)}${suffix}`;
        return text;
      }
      else {
        // In case the lower and upper formatted texts are the same, do not show a range but
        // only the value.
        const textLower = formatter.to(estimatedLowerPurchasePricePerSqm);
        const textUpper = formatter.to(estimatedUpperPurchasePricePerSqm);
        if (textLower === textUpper) {
          return `${textLower}${suffix}`;
        }
        else {
          return `${textLower} - ${textUpper}${suffix}`;
        }
      }
    }
    else if (estimatedPurchasePricePerSqm !== null && priceDiff === null) {
      const text = `${formatter.to(estimatedPurchasePricePerSqm)}${suffix}`;
      return text;
    }
    else { // fallback
      return "";
    }

  }


  getActualPriceString = () => {
    if (this.props.analysis.actual_price.total) {
      return `${purchasePriceFormat.to(this.props.analysis.actual_price.total)} €`
    }
  }
  getAdditionalCostsString = () => {
    if (this.props.analysis.actual_price.additional_costs_total) {
      return `${purchasePriceFormat.to(this.props.analysis.actual_price.additional_costs_total)} €`
    }
    else {
      return "";
    }
  }
  getAdditionalCostsPctString = () => {
    if (this.props.analysis.additional_costs.total_pct) {
      return `${additionalCostsPctFormat.to(this.props.analysis.additional_costs.total_pct - 1.0)} %`
    }
    else {
      return "";
    }
  }
  getPricePerSqmString = () => {
    return this.getPriceString(this.props.analysis.estimated_price_per_sqm, purchasePriceFormat, " €/m²");
  }
  getTotalPriceString = (preferPlusMinus=true) => {
    return this.getPriceString(this.props.analysis.estimated_price_total, purchasePriceFormat, " €", preferPlusMinus);
  }
  getNetColdRentPerSqmString = () => {
    return this.getPriceString(this.props.analysis.estimated_net_cold_rent_per_sqm, rentFormat, " €/m²");
  }
  getTotalNetColdRentString = (preferPlusMinus=true) => {
    return this.getPriceString(this.props.analysis.estimated_net_cold_rent_total, purchasePriceFormat, " €", preferPlusMinus);
  }
  getReturnString = (preferPlusMinus=true) => {
    return this.getPriceString(this.props.analysis.estimated_return, returnFormat, "%", preferPlusMinus);
  }
  getFactorString = (preferPlusMinus=true) => {
    return this.getPriceString(this.props.analysis.estimated_factor, factorFormat, "x", preferPlusMinus);
  }


  evaluationTableRow = (key, value) => {
    return (
        <tr>
          <td className="right insights-summary-table-category">{key}</td>
          <td>{value}</td>
        </tr>
    );
  }


  evaluationPriceRows = () => {
    const { value, lower, upper } = this.props.analysis.estimated_price_total;
    const actual = this.props.analysis.actual_price.total;
    let colorCode = "#000000";
    if (typeof(actual) === 'number' && isNaN(actual) === false) {
      colorCode = this.colorCodeFromDeviation(value, lower, upper, actual);
    }

    return (
      <div>

        <div className="row insights-analysis-main-row">
          <div className="col s4">
            <div className="insights-analysis-main-category" style={{"fontWeight":"bold"}}>
              Angebotspreis
            </div>
          </div>
          <div className="col s8">
            <div className="insights-analysis-main-value" style={{"fontWeight":"bold", "color":colorCode}}>
              {this.getActualPriceString()}
            </div>
          </div>
        </div>

        <div className="row insights-analysis-main-row">
          <div className="col s4">
            <div className="insights-analysis-main-category">
              Schätzung {this.getWarnSign(this.props.analysis.purchase_analysis.count)}
            </div>
          </div>
          <div className="col s8">
            <div className="insights-analysis-main-value">
              {this.getTotalPriceString(false)}
            </div>
          </div>
        </div>

        <div className="row insights-analysis-sub-row">
          <div className="col s4">
            <div className="insights-analysis-sub-category">
              pro m²
            </div>
          </div>
          <div className="col s8">
            <div className="insights-analysis-sub-value">
              {this.getPricePerSqmString()}
            </div>
          </div>
        </div>


      </div>
    );
  }

  additionCostsRows = () => {
    const additionalCostsString = this.getAdditionalCostsString();
    const additionalCostsPctString = this.getAdditionalCostsPctString();

    let categoryText = "Nebenkosten";
    if (additionalCostsPctString !== "" && this.props.analysis.additional_costs.total_pct !== 1.0) {
      categoryText += " (" + additionalCostsPctString + ")";
    }


    // Only show the row if there is data available.
    // Otherwise, too much space is consumed with no information.
    if (this.props.analysis.additional_costs.total_pct === 1.0) {
      return (
        <div></div>
      );
    }
    else {
      return (
        <div>
          <div className="divider"></div>

          <div className="row insights-analysis-main-row">
            <div className="col s4">
              <div className="insights-analysis-main-category">
                {categoryText}
              </div>
            </div>
            <div className="col s8">
              <div className="insights-analysis-main-value">
                {additionalCostsString}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  evaluationRentRows = () => {
    return (
      <div>
        <div className="row insights-analysis-main-row">
          <div className="col s4">
            <div className="insights-analysis-main-category">
              Miete
            </div>
          </div>
          <div className="col s8">
            <div className="insights-analysis-sub-value">
              {this.getTotalNetColdRentString(false)}
            </div>
          </div>
        </div>

        <div className="row insights-analysis-sub-row">
          <div className="col s4">
            <div className="insights-analysis-sub-category">
              pro m²
            </div>
          </div>
          <div className="col s8">
            <div className="insights-analysis-sub-value">
              {this.getNetColdRentPerSqmString()}
            </div>
          </div>
        </div>

      </div>
    );
  }

  evaluationReturnAndFactorRows = () => {
    return (
      <div>
        <div className="row insights-analysis-main-row">
          <div className="col s4">
            <div className="insights-analysis-main-category" style={{"fontWeight":"bold"}}>
              Rendite {this.getWarnSign(this.props.analysis.rent_analysis.count)}
            </div>
          </div>
          <div className="col s8">
            <div className="insights-analysis-main-value" style={{"fontWeight":"bold"}}>
              {this.getReturnString(false)}
            </div>
          </div>
        </div>

        <div className="row insights-analysis-sub-row">
          <div className="col s4">
            <div className="insights-analysis-sub-category">
              Faktor
            </div>
          </div>
          <div className="col s8">
            <div className="insights-analysis-sub-value">
              {this.getFactorString(false)}
            </div>
          </div>
        </div>

        <div className="row insights-analysis-sub-row">
          <div className="col s4">
            <div className="insights-analysis-sub-category">
              Miete (pot.)
            </div>
          </div>
          <div className="col s8">
            <div className="insights-analysis-sub-value">
              {this.getNetColdRentPerSqmString(true)}
            </div>
          </div>
        </div>

      </div>
    );
  }

  disclaimerRow = () => {
    return (
        <div className="row insights-analysis-main-row">
          <div className="col s12">
            <div className="center">
              unverbindliche Analyse <a className="modal-trigger small-text" href="#model-analysis-info"><span className="grey-text"><i className="material-icons" style={{"verticalAlign":"bottom"}}>info_outline</i></span></a>
            </div>

          </div>
        </div>

    );
  }



  evaluationTablePurchasePriceRow = () => {

    const getPurchasePriceSpan = (text) => {
      return (
        <span className="green-text" style={{"fontWeight":"bold"}}>{text}</span>
        //<span className="blue-text text-darken-1" style={{"fontWeight":"bold"}}>{text}</span>
      );
    }

    const rowKeyText = "Kaufpreis";
    return this.evaluationTableRow(rowKeyText, getPurchasePriceSpan(this.getPricePerSqmString()));

  }


  evaluationTableRentRow = () => {
    return this.evaluationTableRow("Miete", this.getNetColdRentPerSqmString());
  }

  evaluationTableReturnRow = () => {
    return this.evaluationTableRow("Rendite", this.getReturnString());
  }

  evaluationTableFactorRow = () => {
    return this.evaluationTableRow("Faktor", this.getFactorString());
  }


  evaluationTable = () => {
    //const { objectType, postalCode, livingSpace, propertySize, constructionYear } = this.props.values;
    //const noInfoText = "keine Angaben";

    return (
      <div className="container">
        <div className="row">
          <table className="highlight fixed insights-summary-table">
            <tbody>

              {this.evaluationTablePurchasePriceRow()}
              {this.evaluationTableRentRow()}
              {this.evaluationTableReturnRow()}
              {this.evaluationTableFactorRow()}

              <tr>
                <td colSpan="2" className="center">
                  unverbindliche Analyse <a className="modal-trigger small-text" href="#model-analysis-info"><span className="grey-text"><i className="material-icons" style={{"verticalAlign":"bottom"}}>info_outline</i></span></a>
                </td>
              </tr>

            </tbody>
          </table>
        </div>

      </div>
    )
  }


  evaluationRows = () => {
    return (
      <div className="container">
        {this.evaluationPriceRows()}
        {this.additionCostsRows()}
        <div className="divider"></div>
        {this.evaluationReturnAndFactorRows()}
        <div className="divider"></div>
        {this.disclaimerRow()}
        {/*
        <div className="divider"></div>
        {this.evaluationRentRows()}
        */}
      </div>
    )
  }
  renderFooterBody = () => {
    if (this.props.analysis.status === 2) { // no evaluation available for postal code (special status code)
      return (
        <div className="container">
          <div className="row">
            <div className="col s12">
              <div>
                <p>Leider liegen uns für diese Postleitzahl keine Vergleichswerte vor. Bitte <a href="/kontakt">kontaktieren</a> Sie uns bei Fragen und Anregungen!</p>
              </div>
            </div>
          </div>
        </div>
      )
    }

    else if (this.props.analysis.status === 1) { // Evaluation is available - show it
      return (
        <div>
          {this.evaluationRows()}
          {/*this.evaluationTable()*/}
        </div>
      )
    }

    else if (this.props.placeholderText) { // there was no error and the evaluation is not available: show the placeholder text if it was provided
      return(
        <div className="container">
          <div className="row">
            <span className="flex-text grey-text">{this.props.placeholderText}</span>
          </div>
        </div>
      )
    }
  }



  render() {

    return (
      <div>


        <div className="row">
          <div className="col s12">
            <div className="divider" />
          </div>
        </div>

        {this.renderFooterBody()}

        {this.props.showNewsletterButton === true ?
          (
            <div className="row">
              <div className="col s12">
                <p className="flex-text" style={{"fontSize":"large"}}>Bald mit noch mehr Funktionen!</p>
                <a href="/newsletter/anmelden" target="_blank" className="btn center waves-effect blue darken-1">Nicht verpassen</a>
              </div>
            </div>
          ) : ( null )
        }


        <div id="model-analysis-info" className="modal">
          <div className="modal-content">
            <h5>Zur Analyse</h5>
            <p>Die hier angezeigten Analysen basieren auf realen Angebotspreisen für Verkauf und Vermietung. Mit Hilfe von über einer Million Datensätzen berechnen wir mögliche Angebotspreise sowie Miete und Mietrendite für das beschriebene Objekt.</p>
            <p>Auf diese Weise können Sie Objekte deutlich besser bewerten und einschätzen, ob sich ein Angebotspreis in einem üblichen Rahmen bewegt. Je mehr Daten Sie zu einem Objekt eingeben, desto genauer wird das Ergebnis, da mehr Faktoren für die Analyse herangezogen werden können.</p>
            <p>Sie können außerdem erkennen, welche Faktoren potentiell zu einem höheren und welche zu einem niedrigeren Preis beitragen. Diese Informationen lassen sich anschließend beispielsweise in Preisverhandlungen verwenden.</p>
            <p>Bitte beachten Sie, dass es sich hierbei nicht um tatsächlich erzielte Kaufpreise oder Preisempfehlungen handelt und Angebotspreise davon abweichen können. Die angezeigten Ergebnisse sind unverbindlich und ohne Gewähr.</p>
          </div>
          <div className="modal-footer">
            <button className="modal-close waves-effect btn-flat">Ok</button>
          </div>
        </div>

      </div>
    )
  }
}

export default InsightsEvalFormFooter;
