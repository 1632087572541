import React from 'react';
import { Navigate } from 'react-router-dom';
import withUser from './withUser';

function ProtectedRoute({ component: Component, user, redirectLink = '/login', roles, ...rest }) {
  // If the users are not even logged in, directly redirect them.
  if (user === null) {
    return <Navigate to={redirectLink} replace />;
  }

  // The user is logged in. Check if the roles property has been specified.
  // If not, grant access to the component.
  if (!roles) {
    return <Component {...rest} />;
  }

  // The user is logged in and the roles property has been set.
  // Check if the user's role is sufficient to access this route.
  const hasRole = roles.some(role => user.roles.includes(role));

  if (hasRole) {
    return <Component {...rest} />;
  } else {
    return <Navigate to={redirectLink} replace />;
  }
}

export default withUser(ProtectedRoute);
