import React, { Component } from 'react'
import M from 'materialize-css'

import InsightsEvalFormHeader from './InsightsEvalFormHeader';
import InsightsEvalFormFooter from './InsightsEvalFormFooter';
import InsightsEvalFormNavigationButtons from './InsightsEvalFormNavigationButtons';

import FeatureImpact from './UiElements/FeatureImpact';

import LivingSpaceSlider from './UiElements/LivingSpaceSlider';
import PropertySizeSlider from './UiElements/PropertySizeSlider';
import NRoomsSlider from './UiElements/NRoomsSlider';
import ConstructionYearSlider from './UiElements/ConstructionYearSlider';


class InsightsSummaryForm extends Component {

  componentDidMount() {
    // Initialize select inputs
    var elems = document.querySelectorAll('select');
    M.FormSelect.init(elems);
  }

  handleObjectTypeRadioButton = (e) => {
    // Simulate event
    this.props.handleChange({
      target: {
        id: "objectType",
        value: e.target.value
      }
    })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.nextStep();
  }



  getMissingInputIcon = () => {
    return (
      <i className="material-icons orange-text" style={{"fontWeight":"normal", "fontSize":"2rem", "verticalAlign":"middle"}}>error_outline</i>
    )
  }
  getErrorIcon = () => {
    return (
      <i className="material-icons red-text" style={{"fontWeight":"normal", "fontSize":"2rem", "verticalAlign":"middle"}}>error_outline</i>
    )
  }



  summaryRows = () => {
    const { objectType, postalCode } = this.props.values;

    return (
      <div className="container">

        <div className="row insights-summary-row">
          <div className="col s4 insights-summary-col-main">Objektart</div>
          <div className="col s7 insights-summary-col-sub">
            <form action="#">
              <label style={{"paddingRight":"20px"}}>
                <input name="objectType" type="radio" checked={objectType==="WK"} value="WK" onChange={this.handleObjectTypeRadioButton}/>
                <span>Wohnung</span>
              </label>
              <label>
                <input name="objectType" type="radio" checked={objectType==="HK"} value="HK" onChange={this.handleObjectTypeRadioButton}/>
                <span>Haus</span>
              </label>
            </form>
          </div>
          <div className="col s1 insights-summary-col-impact"></div>
        </div>
        <div className="divider"></div>

        <div className="row insights-summary-row">
          <div className="col s4 insights-summary-col-main">Postleitzahl</div>
          <div className="col s7 insights-summary-col-sub">
            <input type="text" id="postalCode" value={postalCode} onChange={this.props.handleChange} style={{"textAlign":"center"}} />
          </div>
          <div className="col s1 insights-summary-col-impact"></div>
        </div>
        <div className="divider"></div>

        {/*
        <div className="row insights-summary-row">
          <div className="col s4 insights-summary-col-main">Wohnfläche</div>
          <div className="col s7 insights-summary-col-sub">
            <input type="number" step="1" id="livingSpace" value={this.props.values.livingSpace} onChange={this.props.handleChange} style={{"textAlign":"center", "paddingRight": "0px"}} className="input-field no-arrows-input" />
            <span style={{"marginLeft":"-20px"}}>m²</span>
          </div>
          <div className="col s1 insights-summary-col-impact">
            {
              this.props.errors.livingSpace !== true ? (
                <FeatureImpact
                  impact={this.props.analysis.purchase_analysis.living_space.impact}
                  showIconOnly={true}
                />
              ) : (
                this.props.values.livingSpace === "" ? (null) : (this.getErrorIcon())
              )
            }
          </div>
        </div>
        <div className="divider"></div>
        */}

        <div className="row insights-summary-row">
          <div className="col s4 insights-summary-col-main">Wohnfläche</div>
          <div className="col s7 insights-summary-col-sub">
            <LivingSpaceSlider
              handleChange={this.props.handleChange}
              handleSubmit={this.handleSubmit}
              values={this.props.values}
              analysis={this.props.analysis}
              minifiedLayout={true}
            />
          </div>
          <div className="col s1 insights-summary-col-impact">
            {
              this.props.errors.livingSpace !== true ? (
                <FeatureImpact
                  impact={this.props.analysis.purchase_analysis.living_space.impact}
                  showIconOnly={true}
                />
              ) : (
                this.props.values.livingSpace === "" ? (null) : (this.getErrorIcon())
              )
            }
          </div>
        </div>
        <div className="divider"></div>

        {/*
        {
          objectType === 'HK' ?
          (
            <div>
              <div className="row insights-summary-row">
                <div className="col s4 insights-summary-col-main">Grundstück</div>
                <div className="col s7 insights-summary-col-sub">
                  <input type="number" step="1" id="propertySize" value={this.props.values.propertySize} onChange={this.props.handleChange} style={{"textAlign":"center", "paddingRight": "0px"}} className="no-arrows-input" />
                  <span style={{"marginLeft":"-20px"}}>m²</span>
                </div>
                <div className="col s1 insights-summary-col-impact">
                  {
                    this.props.errors.propertySize !== true ? (
                      <FeatureImpact
                        impact={this.props.analysis.purchase_analysis.property_size.impact}
                        showIconOnly={true}
                      />
                    ) : (
                      this.props.values.propertySize === "" ? (null) : (this.getErrorIcon())
                    )
                  }
                </div>
              </div>
              <div className="divider"></div>
            </div>
          ) : (
            null
          )
        }
        */}

        {
          objectType === 'HK' ?
          (
            <div>
              <div className="row insights-summary-row">
                <div className="col s4 insights-summary-col-main">Grundstück</div>
                <div className="col s7 insights-summary-col-sub">
                  <PropertySizeSlider
                    handleChange={this.props.handleChange}
                    handleSubmit={this.handleSubmit}
                    values={this.props.values}
                    analysis={this.props.analysis}
                    minifiedLayout={true}
                  />
                </div>
                <div className="col s1 insights-summary-col-impact">
                  {
                    this.props.errors.propertySize !== true ? (
                      <FeatureImpact
                        impact={this.props.analysis.purchase_analysis.property_size.impact}
                        showIconOnly={true}
                      />
                    ) : (
                      this.props.values.propertySize === "" ? (null) : (this.getErrorIcon())
                    )
                  }
                </div>
              </div>
              <div className="divider"></div>
            </div>
          ) : (
            null
          )
        }

        {/*
        <div className="row insights-summary-row">
          <div className="col s4 insights-summary-col-main">Zimmer</div>
          <div className="col s7 insights-summary-col-sub">
            <input type="number" step="1" id="nRooms" value={this.props.values.nRooms} onChange={this.props.handleChange} style={{"textAlign":"center", "paddingRight": "0px"}} className="no-arrows-input" />
          </div>
          <div className="col s1 insights-summary-col-impact">
            {
              this.props.errors.nRooms !== true ? (
                <FeatureImpact
                  impact={this.props.analysis.purchase_analysis.n_rooms.impact}
                  showIconOnly={true}
                />
              ) : (
                this.props.values.nRooms === "" ? (null) : (this.getErrorIcon())
              )
            }
          </div>
        </div>
        <div className="divider"></div>
        */}

        <div className="row insights-summary-row">
          <div className="col s4 insights-summary-col-main">Zimmer</div>
          <div className="col s7 insights-summary-col-sub">
            <NRoomsSlider
              handleChange={this.props.handleChange}
              handleSubmit={this.handleSubmit}
              values={this.props.values}
              analysis={this.props.analysis}
              minifiedLayout={true}
            />
          </div>
          <div className="col s1 insights-summary-col-impact">
            {
              this.props.errors.nRooms !== true ? (
                <FeatureImpact
                  impact={this.props.analysis.purchase_analysis.n_rooms.impact}
                  showIconOnly={true}
                />
              ) : (
                this.props.values.nRooms === "" ? (null) : (this.getErrorIcon())
              )
            }
          </div>
        </div>
        <div className="divider"></div>

        {/*
        <div className="row insights-summary-row">
          <div className="col s4 insights-summary-col-main">Baujahr</div>
          <div className="col s7 insights-summary-col-sub">
            <input type="number" step="1" id="constructionYear" value={this.props.values.constructionYear} onChange={this.props.handleChange} style={{"textAlign":"center", "paddingRight": "0px"}} className="no-arrows-input" />
          </div>
          <div className="col s1 insights-summary-col-impact">
            {
              this.props.errors.constructionYear !== true ? (
                <FeatureImpact
                  impact={this.props.analysis.purchase_analysis.construction_year.impact}
                  showIconOnly={true}
                />
              ) : (
                this.props.values.constructionYear === "" ? (null) : (this.getErrorIcon())
              )
            }
          </div>
        </div>
        */}

        <div className="row insights-summary-row">
          <div className="col s4 insights-summary-col-main">Baujahr</div>
          <div className="col s7 insights-summary-col-sub">
            <ConstructionYearSlider
              handleChange={this.props.handleChange}
              handleSubmit={this.handleSubmit}
              values={this.props.values}
              analysis={this.props.analysis}
              minifiedLayout={true}
            />
          </div>
          <div className="col s1 insights-summary-col-impact">
            {
              this.props.errors.constructionYear !== true ? (
                <FeatureImpact
                  impact={this.props.analysis.purchase_analysis.construction_year.impact}
                  showIconOnly={true}
                />
              ) : (
                this.props.values.constructionYear === "" ? (null) : (this.getErrorIcon())
              )
            }
          </div>
        </div>

      </div>
    )

  }



  renderObjectTable = () => {

    const { objectType, postalCode } = this.props.values;

    return (
      <div className="container">
        <div className="row">
          <table className="highlight fixed insights-summary-table" style={{"zIndex":-1}}>
            <tbody>
              <tr>
                <th colSpan="3"><h5>Informationen zum Objekt</h5></th>
              </tr>

              <tr>
                <td>Objektart</td>
                <td colSpan="2">
                  <form action="#">
                    <label style={{"paddingRight":"20px"}}>
                      <input name="objectType" type="radio" checked={objectType==="WK"} value="WK" onChange={this.handleObjectTypeRadioButton}/>
                      <span>Wohnung</span>
                    </label>
                    <label>
                      <input name="objectType" type="radio" checked={objectType==="HK"} value="HK" onChange={this.handleObjectTypeRadioButton}/>
                      <span>Haus</span>
                    </label>
                  </form>
                </td>
              </tr>

              <tr>
                <td>Postleitzahl</td>
                <td><input type="text" id="postalCode" value={postalCode} onChange={this.props.handleChange} style={{"textAlign":"center"}} /></td>
                <td>
                  {
                    this.props.errors.postalCode !== true ? (
                      <span></span>
                    ) : (
                      this.getErrorIcon()
                    )
                  }
                </td>
              </tr>

              <tr>
                <td>Wohnfläche</td>
                <td>
                  <input type="number" step="1" id="livingSpace" value={this.props.values.livingSpace} onChange={this.props.handleChange} style={{"textAlign":"center", "paddingRight": "0px"}} className="no-arrows-input" />
                  <span style={{"marginLeft":"-20px"}}>m²</span>
                </td>
                <td>
                  {
                    this.props.errors.livingSpace !== true ? (
                      <FeatureImpact
                        impact={this.props.analysis.purchase_analysis.living_space.impact}
                        showIconOnly={true}
                      />
                    ) : (
                      this.props.values.livingSpace === "" ? (null) : (this.getErrorIcon())
                    )
                  }
                </td>
              </tr>
                  
              { this.props.values.objectType === "HK" ?
                (
                  <tr>
                    <td>Grundstück</td>
                    <td>
                      <input type="number" step="1" id="propertySize" value={this.props.values.propertySize} onChange={this.props.handleChange} style={{"textAlign":"center", "paddingRight": "0px"}} className="no-arrows-input" />
                      <span style={{"marginLeft":"-20px"}}>m²</span>
                    </td>
                    <td>
                      {
                        this.props.errors.propertySize !== true ? (
                          <FeatureImpact
                            impact={this.props.analysis.purchase_analysis.property_size.impact}
                            showIconOnly={true}
                          />
                        ) : (
                          this.props.values.propertySize === "" ? (null) : (this.getErrorIcon())
                        )
                      }
                    </td>
                  </tr>
                ) : (null)
              }

              <tr>
                <td>Zimmer</td>
                <td>
                  <input type="number" step="1" id="nRooms" value={this.props.values.nRooms} onChange={this.props.handleChange} style={{"textAlign":"center", "paddingRight": "0px"}} className="no-arrows-input" />
                </td>
                <td>
                  {
                    this.props.errors.nRooms !== true ? (
                      <FeatureImpact
                        impact={this.props.analysis.purchase_analysis.n_rooms.impact}
                        showIconOnly={true}
                      />
                    ) : (
                      this.props.values.nRooms === "" ? (null) : (this.getErrorIcon())
                    )
                  }
                </td>
              </tr>

              <tr>
                <td>Baujahr</td>
                <td>
                  <input type="number" step="1" id="constructionYear" value={this.props.values.constructionYear} onChange={this.props.handleChange} style={{"textAlign":"center", "paddingRight": "0px"}} className="no-arrows-input" />
                </td>
                <td>
                  {
                    this.props.errors.constructionYear !== true ? (
                      <FeatureImpact
                        impact={this.props.analysis.purchase_analysis.construction_year.impact}
                        showIconOnly={true}
                      />
                    ) : (
                      this.props.values.constructionYear === "" ? (null) : (this.getErrorIcon())
                    )
                  }
                </td>
              </tr>

              <tr>
                <td>Zimmer</td>
                <td>
                <NRoomsSlider
                  handleChange={this.props.handleChange}
                  handleSubmit={this.handleSubmit}
                  values={this.props.values}
                  analysis={this.props.analysis}
                  minifiedLayout={true}
                />
                </td>
                <td>
                  {
                    this.props.errors.nRooms !== true ? (
                      <FeatureImpact
                        impact={this.props.analysis.purchase_analysis.n_rooms.impact}
                        showIconOnly={true}
                      />
                    ) : (
                      this.props.values.nRooms === "" ? (null) : (this.getErrorIcon())
                    )
                  }
                </td>

              </tr>

            </tbody>
          </table>
        </div>

      </div>
    )
  }

  renderInteriorTable = () => {
    return (
      <div></div>
    )
  }



  renderTables = () => {
    return (
      <div>
        {this.renderObjectTable()}
        {this.renderInteriorTable()}
        

      </div>
    )
  }

  render() {
    const analysis = this.props.analysis;

    return (
      <div>
        <div>

          <InsightsEvalFormHeader />


          <div className="row">
            <div className="col s12">
              {this.summaryRows()}
              {/*this.renderTables()*/}
            </div>
          </div>


          <div className="container">
            <div className="row">
              <div className="col s12">
                <InsightsEvalFormNavigationButtons
                  previousStep={this.props.previousStep}
                  skipToEnd={this.props.skipToEnd}
                  handleSubmit={this.handleSubmit}
                  hideSubmitButton={true}
                  analysis={analysis}
                  />
              </div>
            </div>
          </div>

          <div className="divider" />
          <div className="row">
            <div className="col s12">
              <InsightsEvalFormFooter
                previousStep={this.props.previousStep}
                handleSubmit={this.handleSubmit}
                hideSubmitButton={true}
                analysis={analysis}
                showNewsletterButton={true}
                />
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default InsightsSummaryForm;
