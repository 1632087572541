import React, { useEffect, useRef, useState } from 'react';
import M from 'materialize-css';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import wNumb from 'wnumb';
import FeatureImpact from './FeatureImpact';

const EnergyEfficiencyClassSlider = (props) => {
  const sliderRef = useRef(null);
  const [energyEfficiencyClass, setEnergyEfficiencyClass] = useState(props.values.energy_efficiency_class ? props.values.energy_efficiency_class : "");

  useEffect(() => {
    const energyEfficiencyClassSlider = sliderRef.current;
    const arr = ["A+", "A", "B", "C", "D", "E", "F", "G", "H", ""].reverse();
    const index = arr.indexOf(props.values.energy_efficiency_class);

    if (energyEfficiencyClassSlider) {
      noUiSlider.create(energyEfficiencyClassSlider, {
        start: [index],
        connect: [true, false],
        tooltips: [true],
        range: {
          min: 0,
          max: 9,
        },
        step: 1,
        format: wNumb({
          decimals: 0,
          edit: (value) => {
            try {
              const pos = parseInt(value);
              return arr[pos];
            } catch {
              return value;
            }
          },
        }),
      });

      energyEfficiencyClassSlider.noUiSlider.on('change', (value) => {
        let changedValue = value[0];
        if (changedValue === '-') changedValue = ''; // reset state

        setEnergyEfficiencyClass(String(changedValue));
        props.handleChange({
          target: {
            id: 'energy_efficiency_class',
            value: changedValue,
          },
        });
        M.updateTextFields();
      });

      M.updateTextFields();

      return () => {
        if (energyEfficiencyClassSlider.noUiSlider) {
          energyEfficiencyClassSlider.noUiSlider.destroy();
        }
      };
    }
  }, [props]);

  useEffect(() => {
    if (sliderRef.current && sliderRef.current.noUiSlider) {
      const arr = ["A+", "A", "B", "C", "D", "E", "F", "G", "H", ""].reverse();
      const index = arr.indexOf(energyEfficiencyClass);
      sliderRef.current.noUiSlider.set(index);
    }
  }, [energyEfficiencyClass]);

  const handleEnergyEfficiencyClassChange = (e) => {
    let newValue = String(e.target.value).toUpperCase();
    const arr = ["A+", "A", "B", "C", "D", "E", "F", "G", "H", ""].reverse();
    const index = arr.indexOf(newValue);
    if (newValue === "" || index === -1) {
      newValue = "";
    }

    setEnergyEfficiencyClass(newValue);
    if (sliderRef.current) {
      sliderRef.current.noUiSlider.set(index);
    }
    e.target.value = newValue;
    props.handleChange(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(e);
  };

  const getTextBoxValue = (value) => {
    if (value === "-") return "";
    return String(value);
  };

  const fullSliderLayout = () => (
    <div>
      <div ref={sliderRef} className="insights-slider"></div>
      <form action="" onSubmit={handleSubmit}>
        <div className="input-field">
          <input
            type="text"
            id="energy_efficiency_class"
            value={getTextBoxValue(energyEfficiencyClass)}
            onChange={handleEnergyEfficiencyClassChange}
            style={{ textAlign: 'center', paddingRight: '0px' }}
            className="no-arrows-input"
          />
        </div>
      </form>
      <FeatureImpact impact={props.analysis.purchase_analysis.energy_efficiency_class.impact} />
    </div>
  );

  const minifiedSliderLayout = () => (
    <div>
      <div ref={sliderRef} className="insights-slider"></div>
      <form action="" onSubmit={handleSubmit}>
        <input
          type="text"
          id="energy_efficiency_class"
          value={getTextBoxValue(energyEfficiencyClass)}
          onChange={handleEnergyEfficiencyClassChange}
          style={{ textAlign: 'center', paddingRight: '0px' }}
          className="no-arrows-input"
        />
      </form>
    </div>
  );

  return props.minifiedLayout ? minifiedSliderLayout() : fullSliderLayout();
};

export default EnergyEfficiencyClassSlider;
