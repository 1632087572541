import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { API_ROOT } from '../../config/config';

import axios from 'axios';

import Spacer from '../../components/Spacer';



class BodenrichtwertPage extends Component {

  state = {
    address: "",
    landvalue_analysis: null,

    errors: {
      address: null,
    }
  }


  componentDidMount() {
  }



  handleChange = (e) => {
    const newState = {};
    //console.log(e.target);

    if (e.target.id === 'address_input') {
      newState['address'] = e.target.value;
    }

    this.setState(newState, () => {
    });

  }



  handleSubmit = (e) => {
    e.preventDefault();
    //console.log('submit')

    const object_data = {
      address: this.state.address,
    }

    axios.post(API_ROOT + '/report/bodenrichtwert', object_data)
    .then((res) => {
      console.log(res.data);
      this.setState({landvalue_analysis: res.data.landvalues}, () => {})
    })
    .catch((err) => {
      console.log("Error:", err)
    });

  }


  renderBodenrichtwert = () => {
    // In case there are no results, display a message
    if (this.state.landvalue_analysis.length === 0) {
      return (
        <div>Für diese Adresse wurde leider kein Ergebnis gefunden.</div>
      );
    }

    // Otherwise, display the results as a table
    return (
      <div>
        <table className="highlight fixed insights-summary-table" style={{"zIndex":-1}}>
          <thead>
            <tr>
              <th>Adresse</th>
              <th>BRW</th>
              <th>Lon, Lat</th>
            </tr>
          </thead>

          <tbody>
            {this.state.landvalue_analysis.map((landvalue, index) => (
              <tr key={'landvalue_analysis_' + index.toString()}>
                <td>
                  {landvalue.address}
                </td>
                <td>
                  {landvalue.brw} €
                </td>
                <td>
                  {landvalue.lon}, {landvalue.lat}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }



  render() {

    return (
      <div>
        <Helmet>
          <title>Bodenrichtwert Testseite | Immovestics</title>
          <meta name="description" content="Bewerten Sie Immobilien interaktiv und in wenigen Sekunden für Ihre Immobilien Investments" />
        </Helmet>

        <div className="row">
          <div className="col s12">

            <Spacer height="50px" />

            <section>
              <div className="">

                <div className="row">
                  <div className="col s6 offset-s3">
                    <h5 style={{textAlign:"left"}}>Bodenrichtwert</h5>
                    <form onSubmit={this.handleSubmit}>
                      <input
                        type="text"
                        id="address_input"
                        value={this.state.address}
                        onChange={this.handleChange}
                        placeholder="Bitte geben Sie hier die Adresse ein"
                      />
                      <button type="submit" className="btn blue darken-1">Senden</button>
                    </form>
                  </div>

                  <Spacer height="30px" />

                </div>

                <div className="row">
                  <div className="col s6 offset-s3">
                    {this.state.landvalue_analysis !== null ? this.renderBodenrichtwert() : null}
                  </div>
                </div>

              </div>
            </section>

          </div>
        </div>
      </div>
    )
  }

};

export default BodenrichtwertPage;
