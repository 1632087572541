import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const TimeSeriesGraph = ({ title, x_axis, tooltip_unit, rotate_x_axis, data_all_sets, feature, category, granularity, price_attr, isMonthly = true }) => {
  const graphRef = useRef(null);

  useEffect(() => {
    drawGraph();
  }, [title, x_axis, tooltip_unit, rotate_x_axis, data_all_sets, feature, category, granularity, price_attr, isMonthly]);

  const data1 = Object.keys(data_all_sets[feature])
    .filter(key => isMonthly ? /^\d{4}-\d{2}$/.test(key) : /^\d{4}-Q\d$/.test(key)) // Filter based on isMonthly
    .map(key => ({
      date: key,
      value: parseFloat((Math.round(data_all_sets[feature][key][category][price_attr] * 100) / 100).toFixed(2)),
      count: data_all_sets[feature][key][category]['count']
    }));


const drawGraph = () => {
    const svg = d3.select(graphRef.current);
    const margin = { top: 20, right: 30, bottom: 50, left: 50 }; // Increased bottom margin for rotated labels
    const width = svg.node().getBoundingClientRect().width - margin.left - margin.right;
    const height = svg.node().getBoundingClientRect().height - margin.top - margin.bottom;

    // Clear SVG to redraw
    svg.selectAll("*").remove();

    const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

    // Calculate y-axis scale extents with 10% padding
    const minValue = d3.min(data1, d => d.value);
    const maxValue = d3.max(data1, d => d.value);
    const yExtent = [minValue - 0.1 * Math.abs(minValue), maxValue + 0.1 * Math.abs(maxValue)];

    // Set up scales
    //const x = d3.scaleTime()
      //.domain(d3.extent(data1, d => d.date))
      //.range([0, width]);
    const x = d3.scalePoint()
      .domain(data1.map(d => d.date)) // Assuming 'date' is now a string
      .range([0, width])
      .padding(0.5); // Adjust padding to control the spacing between points

    const y = d3.scaleLinear()
      .domain(yExtent)
      .range([height, 0]);

    // Draw axes and rotate x-axis labels
    g.append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
        .attr("transform", "rotate(-45)")
        .attr("text-anchor", "end")
        .attr("dx", "-0.8em")
        .attr("dy", "0.15em");

    g.append("g")
      .call(d3.axisLeft(y));

    
    // Line generator for the data points
    const line = d3.line()
      .x(d => x(d.date))
      .y(d => y(d.value))
      .curve(d3.curveLinear);  // You can use different curves like curveBasis, curveCardinal

    // Draw line connecting points
    g.append("path")
      .datum(data1)  // Binds data to the line
      .attr("class", "line")  // Class for styling if needed
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 2)
      .attr("d", line);

    // Adding circles for each data point
    g.selectAll(".point")
      .data(data1)
      .enter().append("circle")
        .attr("class", "point")
        .attr("cx", d => x(d.date))
        .attr("cy", d => y(d.value))
        .attr("r", 5)
        .attr("fill", "steelblue")
        .on("mouseover", (event, d) => {
          showTooltip(event.pageX, event.pageY, `Count: ${d.count}`);
        })
        .on("mouseout", hideTooltip);

};


  const showTooltip = (x, y, content) => {
    const tooltip = document.getElementById('tooltip');
    tooltip.style.display = 'block';
    tooltip.align = 'left';
    tooltip.style.left = `${x}px`;
    tooltip.style.top = `${y}px`;
    tooltip.innerText = content;
  };

  const hideTooltip = () => {
    const tooltip = document.getElementById('tooltip');
    tooltip.style.display = 'none';
  };

  const containerStyle = {
    width: '100%',
    height: 0,
    paddingBottom: '60%', // You can adjust this to control the aspect ratio of the SVG
    position: 'relative',
  };

  return (
    <div>
      {/* Existing component HTML */}
      <h6 style={{ textAlign: 'center' }}>{title}</h6>
      <div style={containerStyle}>
        <svg
          ref={graphRef}
          width="100%"
          height="100%"
          style={{ position: 'absolute', top: 0, left: 0 }}
        />
      </div>
      <div id="tooltip" style={{ display: 'none', position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.7)', color: '#fff', padding: '4px', zIndex: 1 }}></div>
      <div style={{ textAlign: 'center', marginBottom: '0px' }}>
        <div>{x_axis}</div>
      </div>
      {/* Legend and other parts */}
    </div>
  );
};

export default TimeSeriesGraph;