import React, { Component } from 'react'

import InsightsEvalFormHeader from './InsightsEvalFormHeader';
import InsightsEvalFormFooter from './InsightsEvalFormFooter';
import InsightsEvalFormNavigationButtons from './InsightsEvalFormNavigationButtons';

import FeatureImpact from './UiElements/FeatureImpact';

import FloorSlider from './UiElements/FloorSlider';
import FloorCountSlider from './UiElements/FloorCountSlider';


class InsightsFloorForm extends Component {
  state = {
    submitButtonClicked: false,
  }


  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      submitButtonClicked: true
    })

    // Allow the next step if there are either no errors or the (optional) field is empty
    if ((this.props.errors.floor !== true || this.props.values.floor === '') &&
        (this.props.errors.floor_count !== true || this.props.values.floor_count === '') ) {
      this.props.nextStep();
    }
  }


  handleElevatorChange = (e) => {
    // Check if the value is valid
    let value = null;
    if (!e?.target?.value) return;
    try {
      value = parseInt(e.target.value);
      //const errors = this.props.validateInput(value);
      //if (errors.objectState) return;
    }
    catch {
      return;
    }

    // Check if the value has already been selected and should now be unselected.
    if (String(value) === String(this.props.values.elevator)) {
      value = "";
    }

    // The input is valid.
    // Create a synthetic event and emit it.
    const syntheticEvent = {
      target: {
        id: "elevator",
        value: value
      }
    }
    this.props.handleChange(syntheticEvent);
  }



  renderCard = (name, value, text) => {
    return (
        <div className="col s6">
          <label>
            <input type="radio" name={name} id={"elevator_" + value} value={value} checked={this.props.values.elevator === value} className="insights-card-input-element" onClick={this.handleElevatorChange} onChange={() => {}}/>
            <div className="card insights-card-input">
              <div className="card-content insights-card-content">
                <p style={{"fontWeight":"normal", "fontSize":"1.2rem"}}>{text}</p>
              </div>
            </div>
          </label>
        </div>
    )
  }



  toggleBinaryFeature = (name, value) => {
    let newValue = "";
    if (this.props.values[name] !== "" && this.props.values[name] === value) {
      newValue = "";
    }
    else {
      newValue = value;
    }

    const syntheticEvent = {
      target: {
        id: name,
        value: newValue
      }
    }
    this.props.handleChange(syntheticEvent);
  }
  renderElevator = () => {
    const name = "elevator";
    const text = "Aufzug";

    const noButtonStyle = this.props.values[name] === 0 ? " insights-binary-feature-button-selected" : "";
    const yesButtonStyle = this.props.values[name] === 1 ? " insights-binary-feature-button-selected" : "";

    return (
      <div>
        <div className="divider"></div>
        <div className="row" style={{"marginTop":"15px", "marginBottom":"10px"}}>
          <div className="col s6">
            <span className="right" style={{"fontWeight":"normal", "fontSize":"1.64rem", "marginTop":"0px"}}>{text}</span>
          </div>
          <div className="col s6">
            <button className={"btn btn-small white grey-text insights-binary-feature-button left" + yesButtonStyle} onClick={() => this.toggleBinaryFeature(name, 1)}>Ja</button>
            <button className={"btn btn-small white grey-text insights-binary-feature-button left" + noButtonStyle} onClick={() => this.toggleBinaryFeature(name, 0)}>Nein</button>
          </div>
        </div>
        <div className="row" style={{"marginTop":"10px", "marginBottom":"10px"}}>
          <div className="col s12">
            <FeatureImpact
              impact={this.props.analysis.purchase_analysis[name].impact}
            />
          </div>
        </div>
      </div>
    )




    //return (
      //<div>
        //<div className="row">
          //{ this.renderCard("elevator", 0, "kein Aufzug") }
          //{ this.renderCard("elevator", 1, "mit Aufzug") }
        //</div>
        //<div className="row">
          //<div className="col s12">
            //<FeatureImpact
              //impact={this.props.analysis.purchase_analysis.elevator.impact}
            ///>
          //</div>
        //</div>
      //</div>
    //);
  }

  render() {
    //const { } = this.props.values;
    const analysis = this.props.analysis;

    return (
      <div>

        <InsightsEvalFormHeader />

        <div className="container">
          { this.props.values.objectType === "WK" ? (
            <div className="row">
              <div className="col s6">
                <h5>Stockwerk</h5>
              </div>
              <div className="col s6">
                <h5>gesamt</h5>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col s12">
                <h5>Anzahl Stockwerke</h5>
              </div>
            </div>
          )}

          <div className="insights-eval-form-content">

            <div className="row">
              { this.props.values.objectType === "WK" ? (
                <div>
                  <div className="col s6">
                    <FloorSlider
                      handleChange={this.props.handleChange}
                      handleSubmit={this.handleSubmit}
                      values={this.props.values}
                      analysis={this.props.analysis}
                    />
                  </div>
                  <div className="col s6">
                    <FloorCountSlider
                      handleChange={this.props.handleChange}
                      handleSubmit={this.handleSubmit}
                      values={this.props.values}
                      analysis={this.props.analysis}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="col s10 offset-s1">
                    <FloorCountSlider
                      handleChange={this.props.handleChange}
                      handleSubmit={this.handleSubmit}
                      values={this.props.values}
                      analysis={this.props.analysis}
                    />
                  </div>
                </div>
              )}
            </div>

            { this.props.values.objectType === "WK" ? (
              this.renderElevator()
            ) :(
              null
            )}

          </div>

          <div className="row">
            <div className="col s12">
              <InsightsEvalFormNavigationButtons
                previousStep={this.props.previousStep}
                skipToEnd={this.props.skipToEnd}
                handleSubmit={this.handleSubmit}
                analysis={analysis}
                />
            </div>
          </div>

        </div>

        <div className="divider" />
        <div className="row">
          <div className="col s12">
            <InsightsEvalFormFooter
              previousStep={this.props.previousStep}
              skipToEnd={this.props.skipToEnd}
              handleSubmit={this.handleSubmit}
              analysis={analysis}
              />
          </div>
        </div>

      </div>
    )
  }
}

export default InsightsFloorForm;