import React, { Component } from 'react'

import InsightsEvalFormHeader from './InsightsEvalFormHeader';
import InsightsEvalFormFooter from './InsightsEvalFormFooter';
import InsightsEvalFormNavigationButtons from './InsightsEvalFormNavigationButtons';

import FeatureImpact from './UiElements/FeatureImpact';

class InsightsQualityLevelForm extends Component {
  state = {
    submitButtonClicked: false,
  }


  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      submitButtonClicked: true
    })

    // Allow the next step if there are either no errors or the (optional) field is empty
    if (this.props.errors.qualityLevel !== true || this.props.values.qualityLevel === '') {
      this.props.nextStep();
    }
  }


  handleChange = (e) => {
    // Check if the value is valid
    let value = null;
    if (!e?.target?.value) return;
    try {
      value = parseInt(e.target.value);
      const errors = this.props.validateInput(value);
      if (errors.qualityLevel) return;
    }
    catch {
      return;
    }

    // Check if the value has already been selected and should now be unselected.
    if (String(value) === String(this.props.values.qualityLevel)) {
      value = "";
    }

    // The input is valid.
    // Create a synthetic event and emit it.
    const syntheticEvent = {
      target: {
        id: e.target.name,
        value: value
      }
    }
    this.props.handleChange(syntheticEvent);
  }


  render() {
    //const { } = this.props.values;
    const analysis = this.props.analysis;
    const heightStarCard = "80px";
    //const qualityLevelError = this.props.errors.qualityLevel;

    return (
      <div>

        <InsightsEvalFormHeader />

        <div className="container">

          <div className="row">
            <div className="col s12">
              <h5>Qualität der Ausstattung</h5>
            </div>
          </div>

          <div className="insights-eval-form-content">

            <div className="row">
              <div className="col s6">
                <label>
                  <input type="radio" name="qualityLevel" id="quality_level_1" value="1" checked={this.props.values.qualityLevel === 1} className="insights-card-input-element" onClick={this.handleChange} onChange={() => {}}/>
                  <div className="card insights-card-input" style={{"minHeight":heightStarCard}}>
                    <div className="card-content insights-card-content">
                      <i className="material-icons">star</i>
                      <div className="insights-card-text">Einfach</div>
                    </div>
                  </div>
                </label>
              </div>

              <div className="col s6">
                <label>
                  <input type="radio" name="qualityLevel" id="quality_level_2" value="2" checked={this.props.values.qualityLevel === 2} className="insights-card-input-element" onClick={this.handleChange} onChange={() => {}} />
                  <div className="card insights-card-input" style={{"minHeight":heightStarCard}}>
                    <div className="card-content insights-card-content">
                      <i className="material-icons">star</i>
                      <i className="material-icons">star</i>
                      <div className="insights-card-text">Standard</div>
                    </div>
                  </div>
                </label>
              </div>

              <div className="col s6">
                <label>
                  <input type="radio" name="qualityLevel" id="quality_level_3" value="3" checked={this.props.values.qualityLevel === 3} className="insights-card-input-element" onClick={this.handleChange} onChange={() => {}} />
                  <div className="card insights-card-input" style={{"minHeight":heightStarCard}}>
                    <div className="card-content insights-card-content">
                      <i className="material-icons">star</i>
                      <i className="material-icons">star</i>
                      <i className="material-icons">star</i>
                      <div className="insights-card-text">Gehoben</div>
                    </div>
                  </div>
                </label>
              </div>

              <div className="col s6">
                <label>
                  <input type="radio" name="qualityLevel" id="quality_level_4" value="4" checked={this.props.values.qualityLevel === 4} className="insights-card-input-element" onClick={this.handleChange} onChange={() => {}} />
                  <div className="card insights-card-input" style={{"minHeight":heightStarCard}}>
                    <div className="card-content insights-card-content">
                      <i className="material-icons">star</i>
                      <i className="material-icons">star</i>
                      <i className="material-icons">star</i>
                      <i className="material-icons">star</i>
                      <div className="insights-card-text">Luxus</div>
                    </div>
                  </div>
                </label>
              </div>
              {/* this.state.submitButtonClicked && qualityLevelError ? <div className="col s12"><span className="helper-text" style={{color: "red"}}>Bitte geben Sie eine gültige Angabe ein.</span></div> : null */}
            </div>

            <div className="row">
              <div className="col s12">
                <FeatureImpact
                  impact={this.props.analysis.purchase_analysis.quality_level.impact}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col s12">
              <InsightsEvalFormNavigationButtons
                previousStep={this.props.previousStep}
                skipToEnd={this.props.skipToEnd}
                handleSubmit={this.handleSubmit}
                analysis={analysis}
                />
            </div>
          </div>

        </div>

        <div className="divider" />
        <div className="row">
          <div className="col s12">
            <InsightsEvalFormFooter
              previousStep={this.props.previousStep}
              skipToEnd={this.props.skipToEnd}
              handleSubmit={this.handleSubmit}
              analysis={analysis}
              />
          </div>
        </div>

      </div>
    )
  }
}

export default InsightsQualityLevelForm;
