import React, { Component } from 'react'

import Textfit from '@namhong2001/react-textfit';
//import Textfit from "react-textfit/lib/Textfit";

import InsightsEvalFormHeader from './InsightsEvalFormHeader';
import InsightsEvalFormFooter from './InsightsEvalFormFooter';
import InsightsEvalFormNavigationButtons from './InsightsEvalFormNavigationButtons';


import InsightsDropdown from './UiElements/InsightsDropdown';
import FeatureImpact from './UiElements/FeatureImpact';

class InsightsParkingTypeForm extends Component {
  state = {
    submitButtonClicked: false,
  }


  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      submitButtonClicked: true
    })

    // Allow the next step if there are either no errors or the (optional) field is empty
    if (this.props.errors.parkingType !== true || this.props.values.parkingType === '') {
      this.props.nextStep();
    }
  }


  handleChange = (e) => {
    // DELETE if cards are not needed anymore
    // Check if the value is valid
    let value = null;
    if (!e?.target?.value) return;
    try {
      value = e.target.value;
      const errors = this.props.validateInput(value);
      if (errors.parkingType) return;
    }
    catch {
      return;
    }

    // Check if the value has already been selected and should now be unselected.
    if (String(value) === String(this.props.values.parkingType)) {
      value = "";
    }

    // The input is valid.
    // Create a synthetic event and emit it.
    const syntheticEvent = {
      target: {
        id: e.target.name,
        value: value
      }
    }
    this.props.handleChange(syntheticEvent);
  }


  renderCard = (value, text) => {
    return (
        <div className="col s6">
          <label>
            <input type="radio" name="parkingType" id={"parking_type_" + value} value={value} checked={this.props.values.parkingType === value} className="insights-card-input-element" onClick={this.handleChange} onChange={() => {}}/>
            <div className="card insights-card-input">
              <div className="card-content insights-card-content">
                <Textfit max={19} className="insights-card-text">{text}</Textfit>
              </div>
            </div>
          </label>
        </div>
    )
  }


  renderParkingTypes = () => {
    return (
      <div>
        <div className="row insights-card-row">
          { this.renderCard("NO_PARKING", "Kein Parkplatz") }
          { this.renderCard("GARAGE", "Garage") }
        </div>

        <div className="row insights-card-row">
          { this.renderCard("PARKING_SPACE", "Stellplatz") }
          { this.renderCard("UNDERGROUND", "Tiefgarage") }
        </div>
        
        <div className="row insights-card-row">
          { this.renderCard("DUPLEX", "Duplex") }
          { this.renderCard("PARKING_DECK", "Parkhaus") }
        </div>
        
        <div className="row insights-card-row">
          { this.renderCard("CARPORT", "Carport") }
          { this.renderCard("OUTSIDE_SPACE", "Außenstellplatz") }
        </div>
      </div>
    );
  }


  renderParkingTypesDropdown = () => {
    const attributeId = "parkingType";
    const value = this.props.values.parkingType;
    const valuePairs = [
      { value: "", text: "Keine Angabe" },
      { value: "NO_PARKING", text: "Kein Parkplatz" },
      { value: "GARAGE", text: "Garage" },
      { value: "PARKING_SPACE", text: "Stellplatz" },
      { value: "UNDERGROUND", text: "Tiefgarage" },
      { value: "DUPLEX", text: "Duplex" },
      { value: "PARKING_DECK", text: "Parkhaus" },
      { value: "CARPORT", text: "Carport" },
      { value: "OUTSIDE_SPACE", text: "Außenstellplatz" },
    ];
    return (
      <InsightsDropdown
        attributeId={attributeId}
        valuePairs={valuePairs}
        value={value}
        handleChange={this.props.handleChange}
        validateInput={this.props.validateInput}
      />
    )
  }



  render() {
    const analysis = this.props.analysis;

    return (
      <div>

        <InsightsEvalFormHeader />

        <div className="container">

          <div className="row">
            <div className="col s12">
              <h5>Parken</h5>
            </div>
          </div>

          <div className="insights-eval-form-content">

            <div className="row">
              {
                this.renderParkingTypes()
                //this.renderParkingTypesDropdown()
              }
            </div>

            <div className="row">
              <div className="col s12">
                <FeatureImpact
                  impact={this.props.analysis.purchase_analysis.parking_type.impact}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col s12">
              <InsightsEvalFormNavigationButtons
                previousStep={this.props.previousStep}
                skipToEnd={this.props.skipToEnd}
                handleSubmit={this.handleSubmit}
                analysis={analysis}
                />
            </div>
          </div>

        </div>

        <div className="divider" />
        <div className="row">
          <div className="col s12">
            <InsightsEvalFormFooter
              previousStep={this.props.previousStep}
              skipToEnd={this.props.skipToEnd}
              handleSubmit={this.handleSubmit}
              analysis={analysis}
              />
          </div>
        </div>

      </div>
    )
  }
}

export default InsightsParkingTypeForm;
