import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_ROOT, AXIOS_CONFIG } from '../../config/config';
import { useNavigate } from 'react-router-dom';

// Get the user from local storage and return a JSON object.
// If the user has not been set, return null.
const getUserFromStorage = () => {
  const newUserString = localStorage.getItem('user');
  if (newUserString !== null) {
    try {
      const newUser = JSON.parse(newUserString);
      return newUser;
    } catch (error) {
      return null;
    }
  }
  return null;
};

const withUser = WrappedComponent => props => {
  const [user, setUser] = useState(getUserFromStorage);
  const navigate = useNavigate();

  useEffect(() => {
    const newUser = getUserFromStorage();
    setUser(newUser);

    window.addEventListener('storage', propagateStorageEvent);
    window.addEventListener('reloadUserEvent', reloadUserCallback);

    return () => {
      window.removeEventListener('storage', propagateStorageEvent);
      window.removeEventListener('reloadUserEvent', reloadUserCallback);
    };
  }, []);

  const reloadUserCallback = () => {
    const newUser = getUserFromStorage();
    setUser(newUser);
  };

  const propagateStorageEvent = () => {
    const event = new Event('reloadUserEvent');
    window.dispatchEvent(event);
  };

  const logout = async () => {
    try {
      await axios.post(`${API_ROOT}/user/logout`, {}, AXIOS_CONFIG);
      localStorage.setItem('user', null);
      propagateStorageEvent();
      navigate('/'); // Redirect to home page
    } catch (error) {
      localStorage.setItem('user', null);
      propagateStorageEvent();
      navigate('/'); // Redirect to home page
    }
  };

  const login = user => {
    localStorage.setItem('user', JSON.stringify(user));
    propagateStorageEvent();
  };

  return <WrappedComponent {...props} user={user} logoutUser={logout} loginUser={login} />;
};

export default withUser;
