import React, { Component } from 'react'
import M from 'materialize-css';
import wNumb from 'wnumb';

import InsightsEvalFormHeader from './InsightsEvalFormHeader';
import InsightsEvalFormFooter from './InsightsEvalFormFooter';
import InsightsEvalFormNavigationButtons from './InsightsEvalFormNavigationButtons';

import FeatureImpact from './UiElements/FeatureImpact';


const floatFormat = wNumb({
  //decimals: 2,
	mark: ',',
	thousand: '.',
	prefix: '',
	suffix: '',
});


class InsightsSummaryForm extends Component {

  componentDidMount = () => {
    const elems = document.querySelectorAll('.collapsible');
    M.Collapsible.init(elems);
  }


  handleSubmit = e => {
    e.preventDefault();
    this.props.nextStep();
  }


  getObjectSubTypeText = (value) => {
    switch (value) {
      case "ERDGESCHOSSWOHNUNG":
        return "Erdgeschosswohnung";
      case "ETAGENWOHNUNG":
        return "Etagenwohnung";
      case "DACHGESCHOSS":
        return "Dachgeschoss";
      case "MAISONETTE":
        return "Maisonette";
      case "HOCHPARTERRE":
        return "Hochparterre";
      case "PENTHOUSE":
        return "Penthouse";
      case "LOFT":
        return "Loft";
      case "SOUTERRAIN":
        return "Souterrain";
      case "REIHENMITTELHAUS":
        return "Reihenmittelhaus";
      case "REIHENECKHAUS":
        return "Reiheneckhaus";
      case "MEHRFAMILIENHAUS":
        return "Mehrfamilienhaus";
      case "EINFAMILIENHAUS":
        return "Einfamilienhaus";
      case "DOPPELHAUSHÄLFTE":
        return "Doppelhaushälfte";
      case "BUNGALOW":
        return "Bungalow";
    
      default:
        return "Keine Angabe";
    }
  }


  getParkingTypeText = (value) => {
    switch (value) {
      case "NO_PARKING":
        return "Kein Parkplatz";
      case "GARAGE":
        return "Garage";
      case "PARKING_SPACE":
        return "Stellplatz";
      case "UNDERGROUND":
        return "Tiefgarage";
      case "DUPLEX":
        return "Duplex";
      case "PARKING_DECK":
        return "Parkhaus";
      case "CARPORT":
        return "Carport";
      case "OUTSIDE_SPACE":
        return "Außenstellplatz";

      default:
        return "Keine Angabe";
    }
  }


  getQualityLevelText = (value) => {
    switch (value) {
      case 1:
        return "Einfach";
      case 2:
        return "Standard";
      case 3:
        return "Gehoben";
      case 4:
        return "Luxus";
    
      default:
        return "Keine Angabe";
    }
  }


  getEnergyCertificateTypeText = (value) => {
    switch (value) {
      case "VERBRAUCHSAUSWEIS":
        return "Verbrauchsausweis";
      case "BEDARFSAUSWEIS":
        return "Bedarfsausweis";

      default:
        return "Keine Angabe";
    }
  }


  getHeatingTypeText = (value) => {
    switch (value) {
      case "FERNWÄRME":
        return "Fernwärme";
      case "ETAGENHEIZUNG":
        return "Etagenheizung";
      case "GAS":
        return "Gas";
      case "ZENTRALHEIZUNG":
        return "Zentralheizung";
      case "ELEKTRO":
        return "Elektro";
      case "FUSSBODENHEIZUNG":
        return "Fussbodenheizung";
      case "BLOCKHEIZKRAFTWERK":
        return "Blockheizkraftwerk";
      case "WÄRMEPUMPE":
        return "Wärmepumpe";
      case "PELLETHEIZUNG":
        return "Pelletheizung";
      case "ÖL":
        return "Öl";
      case "OFENHEIZUNG":
        return "Ofenheizung";
      case "NACHTSPEICHERÖFEN":
        return "Nachtspeicheröfen";
      case "SOLAR":
        return "Solar";

      default:
        return "Keine Angabe";
    }
  }


  getObjectStateText = (value) => {
    switch (value) {
      case "ERSTBEZUG":
        return "Erstbezug";
      case "NEUWERTIG":
        return "Neuwertig";
      case "MODERNISIERT":
        return "Modernisiert";
      case "RENOVIERT":
        return "Renoviert";
      case "SANIERT":
        return "Saniert";
      case "GEPFLEGT":
        return "Gepflegt";
      case "RENOVIERUNGSBEDÜRFTIG":
        return "Benötigt Renovierung";
      case "ABBRUCHREIF":
        return "Abbruchreif";
    
      default:
        return "Keine Angabe";
    }
  }


  getBinaryCategoryRow = (name, text, notAvailableText) => {
    let textCol = <div className="col s4 insights-summary-col-sub">{notAvailableText}</div>;
    let impactCol = <div className="col s2 insights-summary-col-impact"></div>;

    if (this.props.errors[name] !== true) {
      // Text
      if (this.props.values[name] === 1) {
        textCol = <div className="col s4 insights-summary-col-sub">ja</div>
      }
      else if (this.props.values[name] === 0) {
        textCol = <div className="col s4 insights-summary-col-sub">nein</div>
      }
      // Impact
      impactCol = <div className="col s2 insights-summary-col-impact"><FeatureImpact impact={this.props.analysis.purchase_analysis[name].impact} showIconOnly={true} /></div>;
    }

    return (
      <div>
        <div className="row insights-summary-row">
          <div className="col s6 insights-summary-col-main">{text}</div>
          { textCol }
          { impactCol }
        </div>
      </div>
    );
  }


  summaryRows = () => {
    const { objectType, postalCode, livingSpace, nRooms, propertySize, constructionYear } = this.props.values;
    const notAvailableText = 'keine Angaben';

    return (
      <div>

        <ul className="collapsible">



          <li className="">
            <div className="collapsible-header">
              <span style={{"fontSize":"1.2rem"}}>Grundangaben</span>
            </div>
            <div className="collapsible-body">

              <div className="row insights-summary-row">
                <div className="col s6 insights-summary-col-main">Objektart</div>
                <div className="col s4 insights-summary-col-sub">{objectType === 'WK' ? 'Wohnung' : 'Haus'}</div>
                <div className="col s2 insights-summary-col-impact"></div>
              </div>
              <div className="divider"></div>

              <div className="row insights-summary-row">
                <div className="col s6 insights-summary-col-main">Objekttyp</div>
                <div className="col s4 insights-summary-col-sub">{this.props.errors.objectSubType ? notAvailableText : this.getObjectSubTypeText(this.props.values.objectSubType)}</div>
                <div className="col s2 insights-summary-col-impact"><FeatureImpact impact={this.props.analysis.purchase_analysis.object_sub_type.impact} showIconOnly={true} /></div>
              </div>
              <div className="divider"></div>

              <div className="row insights-summary-row">
                <div className="col s6 insights-summary-col-main">Postleitzahl</div>
                <div className="col s4 insights-summary-col-sub">{postalCode}</div>
                <div className="col s2 insights-summary-col-impact"></div>
              </div>
              <div className="divider"></div>

              <div className="row insights-summary-row">
                <div className="col s6 insights-summary-col-main">Wohnfläche</div>
                <div className="col s4 insights-summary-col-sub">{this.props.errors.livingSpace ? notAvailableText : floatFormat.to(parseFloat(livingSpace)) + ' m²'}</div>
                <div className="col s2 insights-summary-col-impact"><FeatureImpact impact={this.props.analysis.purchase_analysis.living_space.impact} showIconOnly={true} /></div>
              </div>
              <div className="divider"></div>

              {
                objectType === 'HK' ?
                (
                  <div>
                    <div className="row insights-summary-row">
                      <div className="col s6 insights-summary-col-main">Grundstück</div>
                      <div className="col s4 insights-summary-col-sub">{this.props.errors.propertySize ? notAvailableText : floatFormat.to(parseFloat(propertySize)) + ' m²'}</div>
                      <div className="col s2 insights-summary-col-impact"><FeatureImpact impact={this.props.analysis.purchase_analysis.property_size.impact} showIconOnly={true} /></div>
                    </div>
                    <div className="divider"></div>
                  </div>
                ) : (
                  null
                )
              }

              <div className="row insights-summary-row">
                <div className="col s6 insights-summary-col-main">Zimmer</div>
                <div className="col s4 insights-summary-col-sub">{this.props.errors.nRooms ? notAvailableText : floatFormat.to(parseFloat(nRooms))}</div>
                <div className="col s2 insights-summary-col-impact"><FeatureImpact impact={this.props.analysis.purchase_analysis.n_rooms.impact} showIconOnly={true} /></div>
              </div>
              <div className="divider"></div>

              <div className="row insights-summary-row">
                <div className="col s6 insights-summary-col-main">Baujahr</div>
                <div className="col s4 insights-summary-col-sub">{this.props.errors.constructionYear ? notAvailableText : constructionYear}</div>
                <div className="col s2 insights-summary-col-impact"><FeatureImpact impact={this.props.analysis.purchase_analysis.construction_year.impact} showIconOnly={true} /></div>
              </div>

            </div>
          </li>



          <li className="">
            <div className="collapsible-header">
              <span style={{"fontSize":"1.2rem"}}>Erweiterte Angaben</span>
            </div>
            <div className="collapsible-body">

              { this.props.values.objectType === "WK" ? (
                <div>
                  <div className="row insights-summary-row">
                    <div className="col s6 insights-summary-col-main">Stockwerk</div>
                    <div className="col s4 insights-summary-col-sub">{this.props.errors.floor ? notAvailableText : this.props.values.floor}</div>
                    <div className="col s2 insights-summary-col-impact"><FeatureImpact impact={this.props.analysis.purchase_analysis.floor.impact} showIconOnly={true} /></div>
                  </div>
                  <div className="divider"></div>
                </div>
              ) : (
                null
              )}
              <div className="row insights-summary-row">
                <div className="col s6 insights-summary-col-main">Anzahl Stockwerke</div>
                <div className="col s4 insights-summary-col-sub">{this.props.errors.floor_count ? notAvailableText : this.props.values.floor_count}</div>
                <div className="col s2 insights-summary-col-impact"><FeatureImpact impact={this.props.analysis.purchase_analysis.floor_count.impact} showIconOnly={true} /></div>
              </div>
              <div className="divider"></div>
              { this.props.values.objectType === "WK" ? (
                <div>
                  { this.getBinaryCategoryRow('elevator', 'Aufzug', notAvailableText) }
                  <div className="divider"></div>
                </div>
              ) : (
                null
              )}

              <div className="row insights-summary-row">
                <div className="col s6 insights-summary-col-main">Parken</div>
                <div className="col s4 insights-summary-col-sub">{this.props.errors.parkingType ? notAvailableText : this.getParkingTypeText(this.props.values.parkingType)}</div>
                <div className="col s2 insights-summary-col-impact"><FeatureImpact impact={this.props.analysis.purchase_analysis.parking_type.impact} showIconOnly={true} /></div>
              </div>
              <div className="divider"></div>

              <div className="row insights-summary-row">
                <div className="col s6 insights-summary-col-main">Qualität der Ausstattung</div>
                <div className="col s4 insights-summary-col-sub">{this.props.errors.qualityLevel ? notAvailableText : this.getQualityLevelText(this.props.values.qualityLevel)}</div>
                <div className="col s2 insights-summary-col-impact"><FeatureImpact impact={this.props.analysis.purchase_analysis.quality_level.impact} showIconOnly={true} /></div>
              </div>
              <div className="divider"></div>

              <div className="row insights-summary-row">
                <div className="col s6 insights-summary-col-main">Zustand</div>
                <div className="col s4 insights-summary-col-sub">{this.props.errors.objectState ? notAvailableText : this.getObjectStateText(this.props.values.objectState)}</div>
                <div className="col s2 insights-summary-col-impact"><FeatureImpact impact={this.props.analysis.purchase_analysis.object_state.impact} showIconOnly={true} /></div>
              </div>
              <div className="divider"></div>

              <div className="row insights-summary-row">
                <div className="col s6 insights-summary-col-main">Energieeffizienzklasse</div>
                <div className="col s4 insights-summary-col-sub">{this.props.errors.energy_efficiency_class ? notAvailableText : this.props.values.energy_efficiency_class}</div>
                <div className="col s2 insights-summary-col-impact"><FeatureImpact impact={this.props.analysis.purchase_analysis.energy_efficiency_class.impact} showIconOnly={true} /></div>
              </div>
              <div className="divider"></div>

              <div className="row insights-summary-row">
                <div className="col s6 insights-summary-col-main">Energieausweis</div>
                <div className="col s4 insights-summary-col-sub">{this.props.errors.energy_certificate_type ? notAvailableText : this.getEnergyCertificateTypeText(this.props.values.energy_certificate_type)}</div>
                <div className="col s2 insights-summary-col-impact"><FeatureImpact impact={this.props.analysis.purchase_analysis.energy_certificate_type.impact} showIconOnly={true} /></div>
              </div>
              <div className="divider"></div>

              <div className="row insights-summary-row">
                <div className="col s6 insights-summary-col-main">Heizungsart</div>
                <div className="col s4 insights-summary-col-sub">{this.props.errors.heating_type ? notAvailableText : this.getHeatingTypeText(this.props.values.heating_type)}</div>
                <div className="col s2 insights-summary-col-impact"><FeatureImpact impact={this.props.analysis.purchase_analysis.heating_type.impact} showIconOnly={true} /></div>
              </div>

            </div>
          </li>



          <li className="">
            <div className="collapsible-header">
              <span style={{"fontSize":"1.2rem"}}>Weitere Merkmale</span>
            </div>
            <div className="collapsible-body">
              { this.getBinaryCategoryRow('kitchen', 'Küche', notAvailableText) }
              <div className="divider"></div>
              { this.getBinaryCategoryRow('guest_toilet', 'Gäste WC', notAvailableText) }
              <div className="divider"></div>
              { this.getBinaryCategoryRow('garden', 'Garten', notAvailableText) }
              <div className="divider"></div>
              { this.getBinaryCategoryRow('balcony_or_terrace', 'Balkon / Terasse', notAvailableText) }
              <div className="divider"></div>
              { this.getBinaryCategoryRow('cellar', 'Keller', notAvailableText) }
              <div className="divider"></div>
              { this.getBinaryCategoryRow('stepless_access', 'Barrierefrei', notAvailableText) }
              <div className="divider"></div>
              { this.getBinaryCategoryRow('heritage_listed_object', 'Erbpachtrecht', notAvailableText) }
              <div className="divider"></div>
              { this.getBinaryCategoryRow('granny_flat', 'Einliegerwohnung', notAvailableText) }

            </div>
          </li>


        </ul>

      </div>
    )
  }



  render() {
    const analysis = this.props.analysis;

    return (
      <div>

        <InsightsEvalFormHeader />

        <div className="container">

          <div className="row">
            <div className="col s12">
              <h5>Zusammenfassung</h5>
            </div>
          </div>

          <div className="insights-eval-form-content">

            <div className="row">
              <div className="col s12">
                {this.summaryRows()}
              </div>
            </div>

          </div>


          <div className="row">
            <div className="col s12">
              <InsightsEvalFormNavigationButtons
                previousStep={this.props.previousStep}
                handleSubmit={this.handleSubmit}
                hideSubmitButton={true}
                analysis={analysis}
                />
            </div>
          </div>

        </div>

        <div className="divider" />
        <div className="row">
          <div className="col s12">
            <InsightsEvalFormFooter
              previousStep={this.props.previousStep}
              handleSubmit={this.handleSubmit}
              hideSubmitButton={true}
              analysis={analysis}
              showNewsletterButton={true}
              />
          </div>
        </div>

      </div>
    )
  }
}

export default InsightsSummaryForm;
