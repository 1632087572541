import React, { useEffect, useRef } from 'react';
import M from 'materialize-css';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import wNumb from 'wnumb';
import FeatureImpact from './FeatureImpact';

const ConstructionYearSlider = (props) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    const constructionYearSlider = sliderRef.current;
    if (constructionYearSlider) {
      const initialValue = props.values.constructionYear ? props.values.constructionYear : 1849;
      
      noUiSlider.create(constructionYearSlider, {
        start: [initialValue],
        connect: [true, false],
        tooltips: [true],
        range: {
          min: 1849,
          max: 2024,
        },
        step: 1,
        format: wNumb({
          decimals: 0,
          edit: (value) => {
            if (value < 1850) return '-';
            return String(value);
          },
        }),
      });

      constructionYearSlider.noUiSlider.on('change', (value) => {
        let changedValue = value[0];
        if (changedValue === '-') changedValue = ''; // reset state
        props.handleChange({
          target: {
            id: 'constructionYear',
            value: changedValue,
          },
        });
        M.updateTextFields();
      });

      M.updateTextFields();

      // Cleanup on unmount
      return () => {
        if (constructionYearSlider.noUiSlider) {
          constructionYearSlider.noUiSlider.destroy();
        }
      };
    }
  }, [props]);

  useEffect(() => {
    if (sliderRef.current && sliderRef.current.noUiSlider) {
      const newValue = props.values.constructionYear ? props.values.constructionYear : 1849;
      sliderRef.current.noUiSlider.set(newValue);
    }
  }, [props.values.constructionYear]);

  const handleConstructionYearChange = (e) => {
    let newValue = e.target.value;
    if (newValue === '') {
      // Do nothing
    } else if (newValue < 1850) {
      // Do nothing. Otherwise, it will reset whenever someone types in the field.
    } else if (newValue > 2024) {
      newValue = 2024;
    }
    e.target.value = newValue;

    if (sliderRef.current) {
      sliderRef.current.noUiSlider.set(newValue);
    }

    props.handleChange(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(e);
  };

  const fullSliderLayout = () => (
    <div>
      <div ref={sliderRef} className="insights-slider"></div>
      <form action="" onSubmit={handleSubmit}>
        <div className="input-field">
          <input
            type="number"
            step="1"
            id="constructionYear"
            value={props.values.constructionYear}
            onChange={handleConstructionYearChange}
            style={{ textAlign: 'center', paddingRight: '0px' }}
            className="no-arrows-input"
          />
        </div>
      </form>
      <FeatureImpact impact={props.analysis.purchase_analysis.construction_year.impact} />
    </div>
  );

  const minifiedSliderLayout = () => (
    <div>
      <div ref={sliderRef} className="insights-slider"></div>
      <form action="" onSubmit={handleSubmit}>
        <input
          type="number"
          step="1"
          id="constructionYear"
          value={props.values.constructionYear}
          onChange={handleConstructionYearChange}
          style={{ textAlign: 'center', paddingRight: '0px' }}
          className="no-arrows-input"
        />
      </form>
    </div>
  );

  return props.minifiedLayout ? minifiedSliderLayout() : fullSliderLayout();
};

export default ConstructionYearSlider;
