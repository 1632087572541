import React, { useState, useEffect } from 'react';
import M from 'materialize-css';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_ROOT, AXIOS_CONFIG } from '../../config/config';
import withUser from './withUser';

const Login = ({ user, loginUser, passwordResetLink }) => {
  const [submitClicked, setSubmitClicked] = useState(false);
  const [callFailed, setCallFailed] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({
    count: null,
    email: null,
    password: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    M.updateTextFields();

    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitClicked(true);
    setCallFailed(false);

    const newErrors = validateInputs({ email, password });
    setErrors(newErrors);

    if (newErrors.count === 0) {
      try {
        const res = await axios.post(`${API_ROOT}/user/login`, { email, password }, AXIOS_CONFIG);
        if (res) {
          loginUser(res.data.user);
          navigate('/');
        }
      } catch (err) {
        setCallFailed(true);
      }
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === 'email') {
      setEmail(value.trim());
    } else if (id === 'password') {
      setPassword(value);
    }

    const newErrors = validateInputs({ email, password });
    setErrors(newErrors);
  };

  const validateInputs = ({ email, password }) => {
    const errors = { count: 0, email: null, password: null };

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      errors.email = true;
      errors.count += 1;
    }

    if (password.length === 0) {
      errors.password = true;
      errors.count += 1;
    }

    return errors;
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="input-field">
          <label htmlFor="email">E-Mail</label>
          <input id="email" type="text" value={email} onChange={handleChange} />
          {submitClicked && errors.email && (
            <span className="helper-text" style={{ color: 'red' }}>Bitte überprüfen Sie Ihre E-Mail Adresse.</span>
          )}
        </div>

        <div className="input-field">
          <label htmlFor="password">Passwort</label>
          <input id="password" type="password" value={password} onChange={handleChange} />
          {submitClicked && errors.password && (
            <span className="helper-text" style={{ color: 'red' }}>Bitte überprüfen Sie Ihr Passwort.</span>
          )}
        </div>

        <div className="center" style={{ marginTop: '20px' }}></div>

        <div className="center">
          {submitClicked && callFailed && (
            <div style={{ paddingBottom: '20px' }}>
              <span className="helper-text" style={{ color: 'red' }}>Es gab einen Fehler bei der Anmeldung. Bitte versuchen Sie es noch einmal.</span>
              {passwordResetLink && (
                <div style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                  <Link to={passwordResetLink} className="red-text text-darken-1 center" style={{ fontWeight: 'bold' }}>
                    Oder Passwort vergessen?
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="center">
          <button className="btn-large blue darken-1">Anmelden</button>
        </div>
      </form>
    </div>
  );
};

export default withUser(Login);
