import React, { Component } from 'react'
import M from 'materialize-css'

export default class PostalCodeInput extends Component {

  componentDidMount() {
    // Ensure that label does not overlap with input when prefilled
    M.updateTextFields();
  }


  handleChange = (e) => {
    if (e?.target?.value === undefined || e?.target?.value === null || typeof(e?.target?.value) !== 'string') return;

    // Input can only be numbers. Ensure now that it is not negative and that the length fits for a postal code.
    e.target.value = e.target.value.replace('-', '');
    if (e.target.value.length > 5) e.target.value = e.target.value.slice(0,5);

    return this.props.handleChange(e);
  }


  render() {
    const { postalCode } = this.props.values;

    return (
      <div>
        <form action="" onSubmit={this.props.handleSubmit}>
          <div className="input-field">
            <i className="material-icons prefix">location_on</i>
            <input type="number" id="postalCode" value={postalCode} onChange={this.handleChange} className="no-arrows-input" />
            <label htmlFor="postalCode">Postleitzahl</label>
          </div>
        </form>
      </div>
    )
  }
}
