import React, { Component } from 'react'

import InsightsEvalFormHeader from './InsightsEvalFormHeader';
import InsightsEvalFormFooter from './InsightsEvalFormFooter';
import InsightsEvalFormNavigationButtons from './InsightsEvalFormNavigationButtons';

import AdditionalCostsInput from './UiElements/AdditionalCostsInput';


class InsightsAdditionalCostsForm extends Component {
  state = {
    submitButtonClicked: false,
  }


  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      submitButtonClicked: true
    })

    // Allow the next step if there are either no errors or the (optional) field is empty
    if (this.props.errors.purchasePrice !== true || this.props.values.purchasePrice === "" || (typeof(this.props.values.purchasePrice) === 'number' && isNaN(this.props.values.purchasePrice))) {
      this.props.nextStep();
    }
  }


  handleEstateAgentPctChange = (value) => {
    const errors = this.props.validateInput.validateEstateAgentPct(value);

    if (Object.keys(errors).length === 0) {
      const newEvent = {
        target: {
          id: 'estate_agent_pct',
          value: value
        }
      }
      return this.props.handleChange(newEvent);
    }
  }

  handleNotaryPctChange = (value) => {
    const errors = this.props.validateInput.validateNotaryPct(value);
    if (Object.keys(errors).length === 0) {
      const newEvent = {
        target: {
          id: 'notary_pct',
          value: value
        }
      }
      return this.props.handleChange(newEvent);
    }
  }

  handleRealEstateTransferTaxPctChange = (value) => {
    const errors = this.props.validateInput.validateRealEstateTransferTaxPct(value);
    if (Object.keys(errors).length === 0) {
      const newEvent = {
        target: {
          id: 'real_estate_transfer_tax_pct',
          value: value
        }
      }
      return this.props.handleChange(newEvent);
    }
  }

  handleLandRegisterPctChange = (value) => {
    const errors = this.props.validateInput.validateLandRegisterPct(value);
    if (Object.keys(errors).length === 0) {
      const newEvent = {
        target: {
          id: 'land_register_pct',
          value: value
        }
      }
      return this.props.handleChange(newEvent);
    }
  }


  render() {
    const analysis = this.props.analysis;
    const purchasePriceError = this.props.errors.purchasePrice;

    return (
      <div>

        <InsightsEvalFormHeader />

        <div className="container">

          <div className="row">
            <div className="col s12">
              <h5>Kaufnebenkosten</h5>
            </div>
          </div>

          <div className="insights-eval-form-content">

            <div className="row">
              <div className="col s6">
                <AdditionalCostsInput
                  handleChange={this.handleEstateAgentPctChange}
                  handleSubmit={this.handleSubmit}
                  value={this.props.values.estate_agent_pct}
                  text={"Maklerprovision"}
                />
              </div>
              <div className="col s6">
                <AdditionalCostsInput
                  handleChange={this.handleNotaryPctChange}
                  handleSubmit={this.handleSubmit}
                  value={this.props.values.notary_pct}
                  text={"Notarkosten"}
                />
              </div>
            </div>

            <div className="row">
              <div className="col s6">
                <AdditionalCostsInput
                  handleChange={this.handleRealEstateTransferTaxPctChange}
                  handleSubmit={this.handleSubmit}
                  value={this.props.values.real_estate_transfer_tax_pct}
                  text={"Grunderwerbsteuer"}
                />
              </div>
              <div className="col s6">
                <AdditionalCostsInput
                  handleChange={this.handleLandRegisterPctChange}
                  handleSubmit={this.handleSubmit}
                  value={this.props.values.land_register_pct}
                  text={"Grundbucheintrag"}
                />
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col s12">
              <InsightsEvalFormNavigationButtons
                previousStep={this.props.previousStep}
                skipToEnd={this.props.skipToEnd}
                handleSubmit={this.handleSubmit}
                deactivateSubmitButton={this.state.submitButtonClicked && (typeof(this.props.values.purchasePrice) === 'number' && isNaN(this.props.values.purchasePrice) === false) && purchasePriceError}
                analysis={analysis}
                />
            </div>
          </div>

        </div>

        <div className="divider" />
        <div className="row">
          <div className="col s12">
            <InsightsEvalFormFooter
              previousStep={this.props.previousStep}
              skipToEnd={this.props.skipToEnd}
              handleSubmit={this.handleSubmit}
              analysis={analysis}
              />
          </div>
        </div>

      </div>
    )
  }
}

export default InsightsAdditionalCostsForm;
