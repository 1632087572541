import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

class Privacy extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="container">
        <Helmet>
          <title>Allgemeine Geschäftsbedingungen | Immovestics</title>
          <meta name="description" content="Unsere AGB" />
        </Helmet>

        <div className="row">
          <div className="col s12">


            <h1>Allgemeine Geschäftsbedingungen</h1>

            <h4>1.	Geltungsbereich und Begriffsbestimmungen</h4>
            <p>1.1	Für die Geschäftsbeziehung zwischen Immovestics (Anbieter: Relatively Unique GmbH mit ihrem Sitz in der Danziger Straße 4/1, 70825 Korntal-Münchingen, Registergericht: Amtsgericht Stuttgart, HRB 760374 im Folgenden kurz "<b>Anbieter</b>" genannt) und dem Kunden (im Folgenden kurz "<b>Kunde</b>" genannt, zusammen hier auch als „<b>die Parteien</b>“ bezeichnet), zur Durchführung von Leistungen im Bereich der Immobilien Investment Analyse (nachfolgend kurz „<b>Leistungen</b>“ genannt), insbesondere aber nicht abschließend bei Verträgen, welche über die Webseiten  https://immovestics.de bzw.  https://immovestics.com (nachfolgend kurz „<b>Webseiten</b>“ genannt) abgeschlossen wurden, sowie hinsichtlich der Nutzung der Webseiten und/oder hinsichtlich der Nutzung der vom Anbieter zur Verfügung gestellten Softwarelösungen (z.B. Browserplugins, mobile Applikationen, REST APIs) gelten diese Allgemeinen Geschäftsbedingungen.</p>
            <p>1.2	Kunden können sowohl Verbraucher (§ 13 BGB) als auch Unternehmer (§ 14 BGB) sein. Handelt der Kunde als Unternehmer (§ 14 BGB) bzw. Gewerbetreibender, hat er dies dem Anbieter im Voraus zu bestätigen.</p>
            <p>1.3	Widersprechende, abweichende oder ergänzende allgemeine Geschäftsbedingungen des Kunden werden nicht Vertragsbestandteil, es sei denn der Anbieter stimmt deren Geltung ausdrücklich zu. Diese Allgemeinen Geschäftsbedingungen gelten auch dann, wenn der Anbieter in Kenntnis entgegenstehender oder von diesen Allgemeinen Geschäftsbedingungen abweichenden Bedingungen des Kunden Leistungen vorbehaltlos ausführt.</p>
            <p>1.4	Diese AGB gelten auch für alle zukünftigen Leistungsbeziehungen zwischen dem Anbieter und dem Kunden (in Zusammenhang mit dem angebotenen Leistungsgegenstand), ohne dass es einer ausdrücklichen Einbeziehung bedarf.</p>
            <p>1.5	Diese AGB gelten bereits mit der ersten Inanspruchnahme der angebotenen Leistungen, unabhängig davon, ob das ausgewählte Angebot für den Kunden kostenpflichtig ist.</p> 
            <p>1.6	Sofern in den nachfolgenden Bestimmungen das generische Maskulinum verwendet wird, gilt dies einzig und allein aus Gründen der Einfachheit, ohne dass damit irgendeine Wertung verbunden ist.</p>

            <h4>2.	Leistungsgegenstand</h4>
            <p>2.1	Der Anbieter bietet u.a. Analyseservices für Immobilien an. Dies umfasst insbesondere die Analyse von erzielbaren An- und Verkaufspreisen, sowie die Schätzung von zu erwartenden Renditen (Immobilien Investment Analyse). Zusätzlich können Immobilienobjekte auch langfristig in der Datenbank gespeichert und bewertet werden.</p>
            <p>2.2	Die einzelnen, konkret geschuldeten Leistungen ergeben sich aus dem Preis- und Leistungsangebot des Anbieters.</p>
            <p>2.3	Die Analyse erhebt keinen Anspruch auf Vollständigkeit.</p>
            <p>2.4	Über die Analyse hinaus wird dem Kunden ausdrücklich kein qualitativer und/oder quantitativer Kauf- oder Verkaufserfolg geschuldet. Im Hinblick auf die natürlichen Schwankungen des Immobilienmarktes kann dem Kunden insbesondere auch kein, wie auch immer beschaffener, finanzieller Erfolg eines vom Kunden beabsichtigten Investments versprochen werden.</p>
            <p>2.5	Die Beratungsleistungen des Anbieters ersetzen keine fachkundige Finanz- und/oder Immobilienberatung. Die Beratungsleistung kann zudem keine rechtliche oder steuerrechtliche Beratung ersetzen.</p>
            <p>2.6	Der Kunde wird durch den Anbieter in keinem Fall zu einer konkreten Handlung (wie etwa einer konkreten Kaufempfehlung bzgl. eines bestimmten Immobilienobjekts) angewiesen und/oder dazu angeraten.</p>
            <p>2.7	Sämtliche bereitgestellte Informationen des Anbieters stellen zudem weder eine Aufforderung noch ein Angebot zum An- oder Verkauf einer bestimmten Immobilie dar.</p>
            <p>2.8	Der Anbieter ist berechtigt, sich zur Erfüllung einzelner oder aller vertraglichen Pflichten Dritter als Hilfspersonen zu bedienen.</p>
            <p>2.9	Der Kunde verpflichtet sich, die zur vertraglichen Leistung notwendigen Mitwirkungspflichten zu erbringen (wie z.B. die Installation des Browserplugins) und dem Anbieter Zugriff auf alle angeforderten Informationen oder Unterlagen zu verschaffen, die zur Erfüllung der Vertragsleistungen erforderlich sind. Bei einem Verstoß des Kunden gegen diese Pflicht ist der Anbieter von seiner Leistungspflicht befreit.</p>

            <h4>3.	Nutzungsvertrag; Registrierung; kostenpflichtige Angebote; Kündigung</h4>
            <p>3.1	Im Rahmen des durch die Nutzung der Webseiten bestehenden Vertrages über die Nutzung der Inhalte ist es dem Kunden gestattet, die kostenlos bereit gestellten Inhalte und Funktionen zu eigenen Zwecken im Rahmen dieser Nutzungsbedingungen kostenlos zu nutzen.</p>
            <p>3.2	Die Nutzung von bestimmten (insbesondere kostenpflichtigen) Inhalten oder Funktionen kann speziellen Bedingungen unterliegen. Der Anbieter behält sich vor, die Nutzungsbedingungen jederzeit zu ändern und die weitere Nutzung der Webseiten, insbesondere der registrierungs- und kostenpflichtigen Inhalte, von der Zustimmung zu den geänderten Nutzungsbedingungen abhängig zu machen.</p>
            <p>3.3	Die Präsentation der Leistungen auf der Webseite oder in Werbeanzeigen stellt kein bindendes Angebot des Anbieters auf Abschluss eines (kostenpflichtigen) Vertrags dar. Der Kunde wird hierdurch lediglich aufgefordert, selbst ein Angebot abzugeben, indem er sich auf der Website des Anbieters für ein entsprechendes Angebot anmeldet bzw. registriert.</p>
            <p>3.4	Der Kunde wählt im Rahmen der Registrierung das vom Anbieter vorgegebene Angebot aus.</p>
            <p>3.5	Alle kostenpflichtigen Leistungen erbringt der Anbieter grundsätzlich erst ab dem Zeitpunkt der entsprechenden Registrierung.</p>
            <p>3.6	Durch seine Registrierung bestätigt der Kunde volljährig und voll geschäftsfähig zu sein.</p>
            <p>3.7	Entscheidet sich der Kunde für ein kostenpflichtiges Angebot, so kann er dieses ohne Angabe eines wichtigen Grundes zum Ende der vereinbarten Laufzeit kündigen. Mit Wirksamkeit der Kündigung, erlischt das geschuldete Leitungsangebot vollständig.</p>

            <h4>4.	Pflichten des Kunden</h4>
            <p>4.1	Der Kunde verpflichtet sich die vom Anbieter zur Verfügung gestellten Funktionen nur in dem vertraglich vereinbarten Umfang zu nutzen.</p>
            <p>4.2	Der Kunde ist verpflichtet, seine hinterlegten Kundeninformationen, bzw. Zugangsdaten (insbesondere seine E-Mail-Adresse) auf dem aktuellen Stand zu halten.</p>
            <p>4.3	Der Kunde ist selbstständig dafür verantwortlich, die technischen Voraussetzungen bereitzuhalten, um das Angebot vollständig nutzen zu können. Bei Vorliegen von technischen Problemen des bereitgestellten gestellten Angebots ist der Kunde zudem verpflichtet, an der Problemlösung bestmöglich mitzuwirken.</p>
            <p>4.4	Der Kunde ist verpflichtet seine Zugangsdaten, insbesondere das von ihm gewählte Passwort jederzeit geheim zu halten und jeglichen unberechtigten Zugang auf sein Nutzerkonto durch Dritte mittels geeigneter Maßnahmen zu verhindern. Der Kunde ist verpflichtet, den Anbieter umgehend zu informieren, wenn Anhaltspunkte dafür ersichtlich sind, dass die Zugangsdaten unberechtigt verwendet werden könnten. Der Kunde haftet für einen etwaigen Missbrauch des Nutzerkontos und/oder seiner Daten.</p>
            <p>4.5	Der Kunde ist verpflichtet, dass die von ihm eingegebenen Informationen und Inhalte keine Rechte Dritter verletzen, sowie nicht gegen gesetzliche Vorschriften verstoßen. Der Anbieter behält sich bei einem Verstoß gegen diese Bestimmung zivil- und/oder strafrechtliche Sanktionen ausdrücklich vor. Der Kunde stellt den Anbieter bereits jetzt von möglichen Regressansprüchen frei, die aus der Verletzung dieser Bestimmung etwaig resultieren.</p>
            <p>Der Kunde erkennt an, dass er bei Verstoß gegen die hier aufgestellten Bedingungen jederzeit durch den Anbieter vorübergehend oder dauerhaft vom Angebot ausgeschlossen („deaktiviert“) werden kann.</p>

            <h4>5.	Vergütung</h4>
            <p>5.1	Die Nutzung der Webseite – jenseits der kostenpflichtigen Inhalte – ist kostenfrei.</p>
            <p>5.2	Für kostenpflichtige Dienste fällt die jeweilige Vergütung an; hiervon ausgenommen ist eine kostenfreie Testphase, sofern dies dem Kunden entsprechend angeboten wird. Die Vergütung richtet sich grundsätzlich nach der vom Kunden gewählten Funktion sowie der jeweiligen Vertragslaufzeit. Sofern der Kunde eine kostenpflichtige Funktion in Anspruch nehmen möchte, hat der Kunde als Vergütung das für die jeweilige Vertragslaufzeit geltende Nutzungsentgelt zu leisten. Die Preise ergeben sich aus der jeweils gültigen Preisliste des Anbieters.</p>
            <p>5.3	Die Vergütungspflicht des Kunden bleibt auch in den Fällen bestehen, in denen die Leistung aus einem durch den Kunden verschuldeten Grund nicht durchgeführt werden kann. In diesem Fall muss sich der Anbieter aber dasjenige anrechnen lassen, was er an Aufwendungen erspart oder zu erwerben unterlässt.</p>
            <p>5.4	Alle durch den Kunden zu leistenden Zahlungen sind grundsätzlich sofort nach Vertragsschluss im Voraus fällig. Zahlungsanbieter sind Vorkasse, Paypal oder Stripe. Der Kunde erklärt sein Einverständnis, die Rechnung auf elektronischem Wege übermittelt zu bekommen.</p>
            <p>5.5	Der Kunde kann nur gegen gerichtlich festgestellte, unbestrittene oder vom Anbieter als bestehend anerkannte Forderungen sein Aufrechnungsrecht oder ein Zurückbehaltungsrecht ausüben bzw. geltend machen.</p>

            <h4>6.	Haftung auf Schadensersatz</h4>
            <p>6.1	Der Anbieter haftet, gleich aus welchem Rechtsgrund, im Rahmen der gesetzlichen Bestimmungen nur nach Maßgabe der folgenden Ziffern.</p>
            <p>6.2	Der Anbieter haftet lediglich für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit sowie für Schäden, die auf Vorsatz oder grober Fahrlässigkeit des Anbieters oder eines seiner gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen. Daneben haftet der Anbieter unter Begrenzung auf Ersatz des vertragstypischen vorhersehbaren Schadens für solche Schäden, die auf einer leicht fahrlässigen Verletzung wesentlicher Vertragspflichten durch ihn oder einen seiner gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen. Vertragswesentliche Pflichten sind Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglichen und auf deren Einhaltung der Kunde regelmäßig vertrauen darf.</p>
            <p>6.3	Der Anbieter übernimmt keine Haftung für die Richtigkeit oder Vollständigkeit der zur Verfügung gestellten Daten sowie darüber hinaus für sämtliche Ereignisse, auf deren Ausgang er keinen Einfluss ausüben kann, insbesondere, aber nicht abschließend, umfasst dies einen Fortgang der mitgeteilten Marktsituation, einen möglichen finanziellen Erfolg im Zusammenhang mit den analysierten Immobilien, einen entgangenen Gewinn bei Verkauf mit einem zu niedrigen Preis oder sonstigen finanziellen Nachteilen beim Immobilienerwerb.</p>
            <p>6.4	Der Anbieter übernimmt keine Haftung für unvorhergesehene Software-Störungen aus der Sphäre Dritter, bzw. technische „Bugs“ oder Datenverluste, auf die der Anbieter selbst keinen Einfluss ausüben kann. Der Anbieter ist bestrebt jedwede Server-Störungen schnellstmöglich zu beheben und Wartungsarbeiten schonend durchzuführen. Überdies kann der Anbieter dem Kunden keine Garantie für eine ständige Erreichbarkeit der Webseite aussprechen.</p>
            <p>6.5	Der Kunde stellt den Anbieter von allen Ansprüchen Dritter frei, die von Dritten, wegen der Verletzung dieser AGB durch den Nutzer, gegenüber dem Anbieter geltend gemacht werden. Der Kunde erstattet dem Anbieter in diesem Fall auch alle zur Rechtsverfolgung und Verteidigung anfallenden Kosten.</p>

            <h4>7.	Datenschutz, Geheimhaltung</h4>
            <p>7.1	Die Verarbeitung personenbezogener Daten zur Erfüllung der vertraglich vereinbarten Leistungen erfolgt gemäß den nationalen wie europäischen Datenschutzgesetzen. Die Verarbeitung der personenbezogenen Daten die zur Erfüllung des vertraglichen Angebots erforderlich sind, erfolgt auf Grundlage von Art. 6 lit. b DS-GVO. Jenseits dessen erfolgt eine weitere Verarbeitung von Daten oder eine Weitergabe an Dritte nur in solchen Fällen, in denen der Kunde zuvor ausdrücklich seine Einwilligung erteilt hat oder die Verarbeitung/Weitergabe zur Vertragsdurchführung erforderlich ist und dies den Interessen des Kunden entspricht. Im Übrigen verweist der Anbieter auf die Datenschutzbestimmungen, verfügbar unter <Link to={"/datenschutz"}><span className="blue-text text-darken-1">https://immovestics.com/datenschutz</span></Link>.</p>
            <p>7.2	Die Vertragsparteien verpflichten sich, die ihnen im Rahmen der Vertragsdurchführung bekannt gewordenen und nicht offenkundigen oder allgemein zugänglichen Informationen oder Unterlagen aus dem Bereich der anderen Partei vertraulich zu behandeln. Diese Geheimhaltungspflicht besteht auch nach Beendigung des Vertragsverhältnisses fort.</p>

            <h4>8.	Nutzungsrechte & Rechteübertragungen, Referenznutzung</h4>
            <p>8.1	Das komplette Angebot des Anbieters unterliegt den jeweiligen gewerblichen Schutzrechten (wie z.B. dem Urhebergesetz) und ist vom Anbieter und/oder dessen Lizenzgebern rechtlich geschützt. Dies gilt insbesondere auch für die gesamte Daten- und Datenbankstruktur sowie für das äußere Erscheinungsbild des Internetauftritts.</p>
            <p>8.2	Alle rechtlich geschützten Inhalte des Anbieters, sowie die gesamte Daten- und Datenbankstruktur dürfen nicht ohne ausdrückliche, vorherige Genehmigung des Anbieters veröffentlicht, vervielfältigt, öffentlich zugänglich gemacht oder an Dritte weitergegeben werden. Dem Kunden wird ein einfaches, nicht übertragbares, zeitlich auf die Vertragsdauer beschränktes, Nutzungsrecht eingeräumt, die Analyseergebnisse für den persönlichen Gebrauch zu vervielfältigen (z.B. durch Ausdruck, Kopie). Jede kommerzielle Nutzung ist ohne die vorherige Einwilligung des Anbieters in jedem Fall unzulässig.</p>
            <p>8.3	Die automatisierte Datenabfrage durch Skripte, Bots, Crawler, o.ä., durch Umgehung der Suchmaske, durch Suchsoftware oder vergleichbare Handlungen (insbesondere Data Mining oder Data Extraction) und das Abgreifen der geschützten Daten und Datenbankinhalte auf andere Weise ist nicht zulässig. Die diesbezügliche Geltendmachung von Schadensersatzansprüchen bleibt ausdrücklich vorbehalten.</p>
            <p>8.4	Der Kunde darf die durch Abfrage gewonnenen Daten weder vollständig noch teilweise oder auszugsweise zum Aufbau einer eigenen Datenbank in jeder medialen Form und/oder für eine gewerbliche Datenverwertung oder Auskunftserteilung bzw. eine sonstige gewerbliche Verwertung verwenden, es sei denn es liegt im Einzelfall eine Einwilligung des Anbieters vor. Die Verlinkung, Integration oder sonstige Verknüpfung der Datenbank oder einzelner Elemente der Datenbank mit anderen Datenbanken oder Meta-Datenbanken ist unzulässig.</p>
            <p>8.5	Der Kunde gestattet dem Anbieter, die vertragliche Zusammenarbeit zum Zwecke der (Eigen-) Werbung, insbesondere auf der Website oder sozialen Netzwerkprofilen des Anbieters („Testimonial-Nutzung“) zu erwähnen.</p>
            <p>8.6	Der Anbieter hat die Wortmarke „Immovestics“ rechtlich schützen lassen und behält sich markenrechtliche Unterlassungs- und/oder Schadensersatzansprüche bei einem rechtswidrigen Eingriff in das Markenrecht des Anbieters ausdrücklich vor.</p>

            <h4>9.	Verwendung objektbezogener Daten</h4>
            <p>9.1	Die von dem Kunden eingegebenen objektbezogenen Daten dürfen ohne Einschränkung gespeichert und weiterverarbeitet   werden und insbesondere für zukünftige Analysen genutzt werden.</p>
            <p>9.2	Die Nutzung personenbezogener Daten gem. Ziff. 7. bleibt unberührt.</p>

            <h4>10.	Widerrufsrecht</h4>
            <p>Das Recht zum Widerruf erlischt nach der erfolgreichen Durchführung der angebotenen Analyse und der Zurverfügungstellung der angebotenen Inhalte, insbesondere durch Download (§ 356 Abs. 4, 5 BGB), wenn und soweit der Kunde ausdrücklich zustimmt, dass er sein Widerrufsrecht bei vollständiger Vertragserfüllung durch den Unternehmer bzw. Download der Analyseergebnisse verliert und dies gegenüber dem Anbieter bestätigt: „Ich stimme der Ausführung des Vertrages vor Ablauf der Widerrufsfrist ausdrücklich zu. Ich habe zur Kenntnis genommen, dass das Widerrufsrecht mit Durchführung der geschuldeten Leistung erlischt.“.</p>

            <h4>11.	Allgemeine Bestimmungen</h4>
            <p>11.1	Soweit gesetzlich zulässig ist der Erfüllungsort Stuttgart. Ausschließlicher Gerichtsstand für Streitigkeiten mit Kaufleuten, juristischen Personen des öffentlichen Rechts oder öffentlich-rechtlichen Sondervermögen aus Verträgen ist München und das Landgericht Stuttgart.</p>
            <p>11.2	Im Falle von unvorhergesehenen und unvermeidbaren Ereignissen höherer Gewalt (insbesondere im Zusammenhang mit den Auswirkungen von COVID-19, z.B. Krankheit oder Quarantäne) sind die Vertragspartner für die Dauer und den Umfang dieses Ereignisses von den hier aufgestellten Leistungspflichten, mit Ausnahme der vereinbarten Vergütung befreit. Der Anbieter muss sich in diesem Fall jedoch dasjenige anrechnen lassen, dass er infolge der Befreiung von der Leistungspflicht erspart oder durch andere mögliche Verwendung seiner Arbeitskraft böswillig zu erwerben unterlässt.  Die Vertragspartner sind verpflichtet sich in diesem Zusammenhang schnellstmöglich die erforderlichen Informationen zu erteilen und ihre Verpflichtungen den jeweiligen Bedingungen unter dem Gebot von Treu und Glauben anzupassen.</p>
            <p>11.3	Nebenabreden zu diesen AGB bestehen nicht und bedürfen zu ihrer Wirksamkeit in jedem Fall der Schriftform. Das gilt auch für die Änderung dieser Bestimmung selbst.</p>
            <p>11.4	Die etwaige Nichtigkeit bzw. Unwirksamkeit einer oder mehrerer Bestimmungen dieser AGB berührt nicht die Wirksamkeit der übrigen Bestimmungen. Die Parteien verpflichten sich, die ungültige Bestimmung durch eine sinnentsprechende wirksame Bestimmung zu ersetzen, die der angestrebten Regelung wirtschaftlich und juristisch am nächsten kommt.</p>
            <p>11.5	Der Anbieter behält sich das Recht vor, diese Allgemeinen Geschäftsbedingungen aus wichtigen Gründen jederzeit zu ändern, es sei denn die Änderung ist für den Kunden nicht zumutbar. Wichtige Gründe sind insbesondere Gesetzesänderungen, geänderte Rechtsprechung oder erhebliche betriebliche und/oder wirtschaftliche Veränderungen des Anbieters. Der Anbieter wird den Kunden in diesem Fall rechtzeitig benachrichtigen. Widerspricht der Kunde den neuen Allgemeinen Geschäftsbedingungen nicht innerhalb einer Frist von zwei Wochen nach Benachrichtigung, gelten die geänderten Allgemeinen Geschäftsbedingungen als vom Kunden angenommen.</p>
            <p>11.6	Auf alle Streitigkeiten in Verbindung mit der Nutzung der Webseite findet, unabhängig vom rechtlichen Grund, ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss aller Bestimmungen des Kollisionsrechts, die in eine andere Rechtsordnung verweisen, Anwendung.</p>

            <h5>Stand: Februar 2021</h5>

          </div>
        </div>



      </div>

    )
  }
}

export default Privacy;
