import React, { useEffect } from 'react';
import M from 'materialize-css';
import { Link, useNavigate } from 'react-router-dom';
import withUser from './User/withUser';
import Logo from '../assets/images/logo/immovestics.svg';

const Navbar = ({ user, logoutUser }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Initialize the sidenav
    const elems = document.querySelectorAll('.sidenav');
    M.Sidenav.init(elems, { edge: 'right' });
  }, []);

  const handleLogoutClick = () => {
    logoutUser();
    navigate('/');
  };

  // Get user roles
  const isAuthenticated = user !== null;
  const isBetaUser = user && user.roles.indexOf("BETA") !== -1;

  return (
    <div>
      <nav className="white">
        <div className="nav-wrapper container">
          <Link to="/">
            <div className="brand-logo left">
              <img src={Logo} alt="Logo" />
              <span>Immovestics</span>
            </div>
          </Link>
          <Link to="#" data-target="mobile-demo" className="sidenav-trigger right">
            <i className="material-icons">menu</i>
          </Link>
          <ul className="right hide-on-med-and-down">
            {isBetaUser && <li><Link to="/mietspiegel">Mietspiegel</Link></li>}
            {isBetaUser && <li><Link to="/realestateindex">Immobilienpreise</Link></li>}
            {isBetaUser && <li><Link to="/bodenrichtwert">BRW</Link></li>}
            {isBetaUser && <li><Link to="/sachwert">Sachwert</Link></li>}
            {isBetaUser && <li><Link to="/rentalindex">Mietspiegel (offz.)</Link></li>}
            {/*<li><Link to="/blog">Blog</Link></li>*/}
            {!isAuthenticated && <li><Link to="/login">Anmelden</Link></li>}
            {!isAuthenticated && <li><Link to="/register">Registrieren</Link></li>}
            {isAuthenticated && <li><Link to="#" onClick={handleLogoutClick}>Logout</Link></li>}
          </ul>
        </div>
      </nav>
      <ul className="left sidenav sidenav-close" id="mobile-demo">
        <li className="sidenav-heading">Menu</li>
        {isBetaUser && <li><Link to="/mietspiegel">Mietspiegel</Link></li>}
        {isBetaUser && <li><Link to="/realestateindex">Immobilienpreise</Link></li>}
        {isBetaUser && <li><Link to="/bodenrichtwert">BRW</Link></li>}
        {isBetaUser && <li><Link to="/sachwert">Sachwert</Link></li>}
        {isBetaUser && <li><Link to="/rentalindex">Mietspiegel (offz.)</Link></li>}
        {/*<li><Link to="/blog">Blog</Link></li>*/}
        {!isAuthenticated && <li><Link to="/login">Anmelden</Link></li>}
        {!isAuthenticated && <li><Link to="/register">Registrieren</Link></li>}
        {isAuthenticated && <li><Link to="#" onClick={handleLogoutClick}>Logout</Link></li>}
      </ul>
    </div>
  );
};

export default withUser(Navbar);
