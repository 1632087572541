import React, { Component } from 'react'

import InsightsEvalFormHeader from './InsightsEvalFormHeader';
import InsightsEvalFormFooter from './InsightsEvalFormFooter';
import InsightsEvalFormNavigationButtons from './InsightsEvalFormNavigationButtons';

import PropertySizeSlider from './UiElements/PropertySizeSlider';


class InsightsPropertySizeForm extends Component {
  state = {
    submitButtonClicked: false,
  }


  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      submitButtonClicked: true
    })

    // Allow the next step if there are either no errors or the (optional) field is empty
    if (this.props.errors.propertySize !== true || this.props.values.propertySize === '') {
      this.props.nextStep();
    }
  }

  render() {
    //const { } = this.props.values;
    const analysis = this.props.analysis;
    const propertySizeError = this.props.errors.propertySize;

    return (
      <div>

        <InsightsEvalFormHeader />

        <div className="container">

          <div className="row">
            <div className="col s12">
              <h5>Grundstück</h5>
            </div>
          </div>


          <div className="insights-eval-form-content">

            <div className="row">
              <div className="col s10 offset-s1">
                <PropertySizeSlider
                  handleChange={this.props.handleChange}
                  handleSubmit={this.handleSubmit}
                  values={this.props.values}
                  analysis={this.props.analysis}
                />
              </div>
              { this.state.submitButtonClicked && propertySizeError ? <div className="col s12"><span className="helper-text" style={{color: "red"}}>Bitte geben Sie eine gültige Grundstücksgröße ein.</span></div> : null }
            </div>

          </div>

          <div className="row">
            <div className="col s12">
              <InsightsEvalFormNavigationButtons
                previousStep={this.props.previousStep}
                skipToEnd={this.props.skipToEnd}
                handleSubmit={this.handleSubmit}
                analysis={analysis}
                />
            </div>
          </div>

        </div>

        <div className="divider" />
        <div className="row">
          <div className="col s12">
            <InsightsEvalFormFooter
              previousStep={this.props.previousStep}
              skipToEnd={this.props.skipToEnd}
              handleSubmit={this.handleSubmit}
              analysis={analysis}
              />
          </div>
        </div>

      </div>
    )
  }
}

export default InsightsPropertySizeForm;
