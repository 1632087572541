import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Privacy from './components/Privacy';
import AGB from './components/AGB';
import LoginPage from './pages/User/LoginPage';
import LoginErrorPage from './pages/User/LoginErrorPage';
import LoginVerificationPage from './pages/User/LoginVerificationPage';
import RegisterPage from './pages/User/RegisterPage';
import PasswordResetRequestPage from './pages/User/PasswordResetRequestPage';
import PasswordResetPage from './pages/User/PasswordResetPage';
// import TestPage from './pages/User/TestPage';
import ContactPage from './pages/ContactPage';
import NewsletterSignupPage from './pages/Newsletter/NewsletterSignupPage';
import NewsletterThankYouPage from './pages/Newsletter/NewsletterThankYouPage';
import PartnerContactPage from './pages/PartnerContactPage';
import InsightsEvalPage from './pages/Insights/InsightsEvalPage';
import BodenrichtwertPage from './pages/Bodenrichtwert/BodenrichtwertPage';
import SachwertPage from './pages/Sachwert/SachwertPage';
import MietspiegelPage from './pages/Mietspiegel/MietspiegelPage';
import RealEstateIndexPage from './pages/RealEstateIndex/RealEstateIndexPage';
import RentalIndexPage from './pages/RentalIndex/RentalIndexPage';

import Browser from './pages/Browser/Browser';
//import Blog from './components/Blog/Blog';
//import Blog20210418 from './components/Blog/2021/2021-04-18';
//import Blog20210505 from './components/Blog/2021/2021-05-05';
//import Blog20210518 from './components/Blog/2021/2021-05-18';
//import Blog20210601 from './components/Blog/2021/2021-06-01';
import ProtectedRoute from './components/User/ProtectedRoute';

function App() {
  // Parse the query params
  const { search } = useLocation();
  const {
    pro,
    purchase_price, purchase_price_per_sqm,
    object_type, postal_code,
    n_rooms, living_space, property_size,
    construction_year,
    floor, floor_count, elevator, balcony_or_terrace,
    parking_count, parking_type, parking_price, parking_rent,
    guest_toilet, kitchen,
    garden, cellar, stepless_access, heritage_listed_object, rented, holiday_home, holiday_house, granny_flat, flat_sharing,
    quality_level, object_state,
    modernisation_year,
    energy_efficiency_class, energy_certificate_type, heating_type,
    pets,
    object_sub_type,
  } = queryString.parse(search);
  const insightsParams = {
    purchase_price, purchase_price_per_sqm,
    object_type, postal_code,
    n_rooms, living_space, property_size,
    construction_year,
    floor, floor_count, elevator, balcony_or_terrace,
    parking_count, parking_type, parking_price, parking_rent,
    guest_toilet, kitchen,
    garden, cellar, stepless_access, heritage_listed_object, rented, holiday_home, holiday_house, granny_flat, flat_sharing,
    quality_level, object_state,
    modernisation_year,
    energy_efficiency_class, energy_certificate_type, heating_type,
    pets,
    object_sub_type,
  };

  return (
    <div className="immovestics-app">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/impressum" element={<About />} />
        <Route path="/datenschutz" element={<Privacy />} />
        <Route path="/agb" element={<AGB />} />

        <Route path="/login" element={<LoginPage />} />
        <Route path="/login/error" element={<LoginErrorPage />} />
        <Route path="/login/verification" element={<LoginVerificationPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/passwordresetrequest" element={<PasswordResetRequestPage />} />
        <Route path="/passwordreset/:userId/:resetKey" element={<PasswordResetPage />} />
        <Route path="/browser" element={<Browser />} />

        {/*
        <Route path="/test" element={<TestPage />} />
        */}

        <Route path="/partner/kontakt" element={<PartnerContactPage />} />
        <Route path="/kontakt" element={<ContactPage />} />
        <Route path="/newsletter/anmelden" element={<NewsletterSignupPage />} />
        <Route path="/newsletter/willkommen" element={<NewsletterThankYouPage />} />

        <Route
          path="/insights/browser"
          element={
            <Navigate
              to="/insights"
              state={{ isPro: pro !== undefined, browser: insightsParams }}
              replace
            />
          }
        />

        <Route
          path="/insights"
          element={<InsightsEvalPage isPro={pro !== undefined} />}
        />

        <Route path="/instagram" element={<Navigate to="/blog" replace />} />

        {/*
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/jetzt-immobilien-bewerten-2021-04-18" element={<Blog20210418 />} />
        <Route path="/blog/lohnt-sich-diese-immobilie-2021-05-05" element={<Blog20210505 />} />
        <Route path="/blog/drei-merkmale-von-angebotspreisen-2021-05-18" element={<Blog20210518 />} />
        <Route path="/blog/welchen-einfluss-hat-das-baujahr-auf-angebotspreis-und-rendite-2021-06-01" element={<Blog20210601 />} />
        */}

        <Route
          path="/bodenrichtwert"
          element={<ProtectedRoute roles={['BETA']} component={BodenrichtwertPage} />}
        />
        <Route
          path="/sachwert"
          element={<ProtectedRoute roles={['BETA']} component={SachwertPage} />}
        />
        <Route
          path="/mietspiegel"
          element={<ProtectedRoute roles={['BETA']} component={MietspiegelPage} />}
        />
        <Route
          path="/realestateindex"
          element={<ProtectedRoute roles={['BETA']} component={RealEstateIndexPage} />}
        />
        <Route
          path="/rentalindex"
          element={<ProtectedRoute roles={['BETA']} component={RentalIndexPage} />}
        />

        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
