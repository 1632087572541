import React, { Component } from 'react'
import M from 'materialize-css'

import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_ROOT, AXIOS_CONFIG } from '../../config/config';
import withUser from './withUser';


class PasswordReset extends Component {
  state = {
    submitClicked: false, // submit button clicked at least once?
    callFailed: false,
    callSucceeded: false, // true if the API call was successful

    email: '',

    errors: {
      count: null,
      email: null,
    }
  };

  componentDidMount = () => {
    // Ensure that label does not overlap with input when prefilled
    M.updateTextFields();

    // Redirect away from login page if user is already logged in
    if (this.props.user) {
      this.props.history.push('/');
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      callFailed: false,
      submitClicked: true
    }, async () => {

      // If there are no errors, call the api
      if (this.state.errors.count === 0) {
        try {
          const res = await axios.post(API_ROOT + '/user/password_reset_request', {
            email: this.state.email
          }, AXIOS_CONFIG);
          if (res) {
            // Trigger the message that the password reset email has been sent
            this.setState({
              callSucceeded: true
            })
          }
        }
        catch (err) {
          this.setState({
            callFailed: true
          })
        };

      }

    });
  }

  logout = () => {
    axios.post(API_ROOT + '/user/logout', {}, AXIOS_CONFIG)
    .then((res) => {
      console.log(res)
      localStorage.removeItem('user');
    })
    .catch((err) => {
      console.log("Error:", err)
    });
  }

  handleChange = (e) => {
    const newState = {};
    newState[e.target.id] = e.target.value

    if (e.target.id === "email") {
      newState[e.target.id] = e.target.value.trim();
    }

    const newErrors = this.validateInputs({...this.state, ...newState});
    newState.errors = newErrors;

    this.setState(newState, ()=>{
      //console.log(this.state)
    });
  }

  validateInputs = (state) => {
    const errors = {};
    errors.count = 0;

    if ("email" in state) {
      var emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!emailPattern.test(state.email)) {
        errors.email = true;
        ++errors.count;
      }
    }

    return errors;
  };


  showForm = () => {
    return(
      <div>
        <form action="" onSubmit={this.handleSubmit} >

          <div className="input-field">
            <label htmlFor="email">E-Mail</label>
            <input id="email" name="email" type="text" value={this.state.email} onChange={this.handleChange} />
            { this.state.submitClicked && this.state?.errors?.email ? <span className="helper-text" style={{color: "red"}}>Bitte überprüfen Sie Ihre E-Mail Adresse.</span> : null }
          </div>

          <div className="center" style={{"marginTop":"20px"}}>
          </div>

          <div className="center">
            { this.state.submitClicked && this.state.callFailed ? (
              <div style={{"paddingBottom":"20px"}}>
                <span className="helper-text" style={{color: "red"}}>Es gab leider einen Fehler. Bitte versuchen Sie es noch einmal.</span>
              </div>
            ) : null }
          </div>

          <div className="center">
            <button className="btn-large blue darken-1">Passwort zurücksetzen</button>
          </div>

        </form>

      </div>
    )
  }


  showSuccessMessage = () => {
    return(
      <div>
        <p className="flex-text green-text" style={{"fontWeight":"bold"}}>Eine E-Mail mit Informationen zur Änderung des Passworts ist unterwegs zu Ihnen.</p>
        <div className="center">
          <Link to="/" className="btn-large blue darken-1">Zur Startseite</Link>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.callSucceeded) {
      return (
        this.showSuccessMessage()
      )
    }
    else {
      return (
        this.showForm()
      )
    }
  }
};

export default withUser(PasswordReset);
