import React from 'react'
import { Link } from 'react-router-dom';

//import { GoogleLoginButton, FacebookLoginButton } from "react-social-login-buttons";

import Register from '../../components/User/Register';
import Spacer from '../../components/Spacer';

//import { API_ROOT } from '../../config/config';

//const loginWithGoogle = () => {
  //window.open(API_ROOT + "/user/login/google", "_self");
//}

//const loginWithFacebook = () => {
  //window.open(API_ROOT + "/user/login/facebook", "_self");
//}

//<div>
  //<GoogleLoginButton onClick={loginWithGoogle}>Registrieren mit Google</GoogleLoginButton>
//</div>
//<Spacer height="25px" />
//<div>
  //<FacebookLoginButton onClick={loginWithFacebook}>Registrieren mit Facebook</FacebookLoginButton>
//</div>
//<Spacer height="50px" />
//<div className="divider"></div>
//<Spacer height="50px" />
//<div className="center">
  //<span className="" style={{"fontWeight":"normal", "fontSize":"1.5rem"}}>Oder mit E-Mail und Passwort</span>
//</div>

const RegisterPage = (props) => {
  return (
    <div>
      <div className="container">
        <Spacer height="50px" />
      </div>

      <div className="container">
        <div className="row">
          <div className="col s12 l6 offset-l3 white">
            <div className="immovestics-container">
              <h4>Registrieren</h4>
              <div style={{"marginTop":"30px"}}></div>


              <Register />
              <div className="center" style={{"marginTop":"20px"}}>
                <Link to={'/login'} className="blue-text text-darken-1 center">Zur Anmeldung</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <Spacer height="50px" />
      </div>
    </div>
  )
};

export default RegisterPage;