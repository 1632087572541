import React from 'react'

export default function FeatureImpact(props) {
  const { deviation } = props.impact;
  const showIconOnly = props.showIconOnly;
  //console.log(value, lower, upper)

  const getImpactText = () => {
    const getHtml = (item, showIconOnly) => {
      if (showIconOnly === true) {
        return item;
      }
      else {
        const preText = (<span className="" style={{"fontWeight":"normal"}}>Preiseffekt: </span>);
        return (
          <div>
            {preText}
            {item}
          </div>
        )
      }
    }

    const tooltipUnknown = "Keine Angaben";
    const tooltipGrey = "Kein deutlicher Effekt auf den Preis feststellbar";
    const tooltipLightGreen = "Führt zu einem leicht höheren Preis";
    const tooltipStrongGreen = "Führt zu einem deutlich höheren Preis";
    const tooltipLightRed = "Führt zu einem leicht niedrigeren Preis";
    const tooltipStrongRed = "Führt zu einem deutlich niedrigeren Preis";

    const maxThreshold = 0.10;
    const middleThreshold = 0.025;
    if (deviation === null) {
      return getHtml(
        <i className="material-icons grey-text text-lighten-2 tooltipped" data-position="bottom" data-tooltip={tooltipUnknown} style={{"fontWeight":"normal", "fontSize":"2rem", "verticalAlign":"middle"}}>help_outline</i>, showIconOnly
      )
    }
    else if (deviation === 0.0) {
      return getHtml(
        <i className="material-icons grey-text text-lighten-1 rotate90 tooltipped" data-position="bottom" data-tooltip={tooltipGrey} style={{"fontWeight":"bold", "fontSize":"2rem", "verticalAlign":"middle"}}>arrow_upward</i>, showIconOnly
      )
    }
    else if (deviation < 0.0) {
      if (deviation < -1.0 * maxThreshold) { // (-inf, -max)
        return getHtml(
          <i className="material-icons red-text rotate180 tooltipped" data-position="bottom" data-tooltip={tooltipStrongRed} style={{"fontWeight":"bold", "fontSize":"2rem", "verticalAlign":"middle"}}>arrow_upward</i>, showIconOnly
        )
      }
      else if (deviation < -1.0 * middleThreshold) { // [-max, -middle)
        return getHtml(
          <i className="material-icons red-text text-lighten-3 rotate135 tooltipped" data-position="bottom" data-tooltip={tooltipLightRed} style={{"fontWeight":"bold", "fontSize":"2rem", "verticalAlign":"middle"}}>arrow_upward</i>, showIconOnly
        )
      }
      else { // deviation in [middle, 0)
        return getHtml(
          <i className="material-icons grey-text text-lighten-1 rotate90 tooltipped" data-position="bottom" data-tooltip={tooltipGrey} style={{"fontWeight":"bold", "fontSize":"2rem", "verticalAlign":"middle"}}>arrow_upward</i>, showIconOnly
        )
      }
    }
    else if (deviation > 0.0) {
      if (deviation > maxThreshold) { // (max, inf)
        return getHtml(
          <i className="material-icons green-text tooltipped" data-position="bottom" data-tooltip={tooltipStrongGreen} style={{"fontWeight":"bold", "fontSize":"2rem", "verticalAlign":"middle"}}>arrow_upward</i>, showIconOnly
        )
      }
      else if (deviation > middleThreshold) { // (middle, max]
        return getHtml(
          <i className="material-icons green-text text-lighten-3 rotate45 tooltipped" data-position="bottom" data-tooltip={tooltipLightGreen} style={{"fontWeight":"bold", "fontSize":"2rem", "verticalAlign":"middle"}}>arrow_upward</i>, showIconOnly
        )
      }
      else { // (0, middle]
        return getHtml(
          <i className="material-icons green-text text-lighten-3 rotate45 tooltipped" data-position="bottom" data-tooltip={tooltipLightGreen} style={{"fontWeight":"bold", "fontSize":"2rem", "verticalAlign":"middle"}}>arrow_upward</i>, showIconOnly
        )
      }
    }
    else if (deviation === 0.0) {
      return getHtml(
        <i className="material-icons grey-text rotate90 tooltipped" data-position="bottom" data-tooltip={tooltipGrey} style={{"fontWeight":"bold", "fontSize":"2rem", "verticalAlign":"middle"}}>arrow_upward</i>, showIconOnly
      )
    }
  }

  //if (lower !== null && upper !== null) {
    //const text = <span className="flex-text black-text">{euroIntFormat.to(lower)} - {euroIntFormat.to(upper)} €</span>;
    //return text;
    ////return this.evaluationTableRow(rowKeyText, text);
  //}

  return getImpactText();

}
