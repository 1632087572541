import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { API_ROOT } from '../../config/config';

//import Textfit from '@namhong2001/react-textfit';
//import Textfit from "react-textfit/lib/Textfit";
import axios from 'axios';

import Spacer from '../../components/Spacer';

import FloorCountSlider from '../../components/Insights/UiElements/FloorCountSlider';
import ConstructionYearSlider from '../../components/Insights/UiElements/ConstructionYearSlider';
import LivingSpaceSlider from '../../components/Insights/UiElements/LivingSpaceSlider';
import PropertySizeSlider from '../../components/Insights/UiElements/PropertySizeSlider';

//import StandardLevelSlider from '../../components/Sachwert/UiElements/StandardLevelSlider';


class SachwertPage extends Component {

  state = {
    object_sub_type: "EFH",
    floor_count: 1,
    cellar: true,
    attic_converted: true,
    roof_type: 'OTHER',
    construction_year: 1990,
    constructionYear: 1990, // dummy for slider functionality
    standard_level: null, // will be derived
    quality_level: 3,
    living_space: 120,
    livingSpace: 120, // dummy for slider functionality
    property_size: 200,
    propertySize: 200, // dummy for slider functionality
    land_value_per_sqm: null,

    address: "",
    landvalue_analysis: null,
    selected_landvalue_id: null,

    eval: null,

    analysis: { // only dummy values for the UiElements
      purchase_analysis: {
        floor_count: {
          impact: 0,
        }
      }
    },

    errors: {
      email: null,
      message: null,
      consent: null
    }
  }


  componentDidMount() {
    this.update_sachwert(()=>{}, ()=>{});
  }



  handleChange = (e) => {
    const newState = {};

    //console.log(e.target);

    if (e.target.name === 'object_sub_type') {
      newState[e.target.name] = e.target.value;
    }
    else if (e.target.id === 'floor_count') {
      const value = parseInt(e.target.value);
      if (!isNaN(value)) {
        if (value < 1) {
          newState[e.target.id] = 1;
        }
        else {
          newState[e.target.id] = parseInt(e.target.value);
        }
      }
      else {
        e.target.value = 1;
      }
    }
    else if (e.target.name === 'cellar') {
      newState[e.target.name] = JSON.parse(e.target.value);
    }
    else if (e.target.name === 'attic_converted') {
      const value = JSON.parse(e.target.value);
      newState[e.target.name] = JSON.parse(e.target.value);
      if (value === true && this.state.roof_type === "FLACHDACH") {
        newState.roof_type = "OTHER";
      }
    }
    else if (e.target.name === 'roof_type') {
      newState[e.target.name] = e.target.value;
      if (e.target.value === "FLACHDACH") {
        newState.attic_converted = false;
      }
    }
    else if (e.target.id === 'constructionYear') { // different casing
      newState[e.target.id] = e.target.value;
      const value = parseInt(e.target.value);
      if (!isNaN(value)) {
        newState['construction_year'] = value;
      }
    }
    else if (e.target.id === 'livingSpace') { // different casing
      newState[e.target.id] = e.target.value;
      const value = parseInt(e.target.value);
      if (!isNaN(value)) {
        newState['living_space'] = value;
      }
    }
    else if (e.target.name === 'quality_level') {
      newState[e.target.name] = JSON.parse(e.target.value);
    }
    else if (e.target.id === 'standard_level') {
      newState[e.target.id] = JSON.parse(e.target.value);
    }
    else if (e.target.id === 'propertySize') { // different casing
      newState[e.target.id] = e.target.value;
      const value = parseInt(e.target.value);
      if (!isNaN(value)) {
        newState['property_size'] = value;
      }
    }

    this.setState(newState, () => {
      this.update_sachwert(()=>{}, ()=>{
      });
    });

  }

  handleLandValueInputChange = (e) => {
    const newState = {};

    //console.log(e.target);

    if (e.target.id === 'address_input') {
      newState['address'] = e.target.value;
    }

    this.setState(newState, () => {
    });
  }


  onLandValueTableClick = (row) => {
    //console.log(row);
    const newState = {};
    newState["selected_landvalue_id"] = row;
    newState["land_value_per_sqm"] = this.state.landvalue_analysis[row].brw;
    this.setState(newState, () => {
      this.update_sachwert()
    });
  }


  update_sachwert = () => {
    const current_date = new Date();
    const year = String(current_date.getFullYear());
    const month = String(current_date.getMonth() + 1).padStart(2, "0");
    const day = String(current_date.getDate()).padStart(2, "0");
    const date_str = year + "-" + month + "-" + day;

    const object_data = {
      object_type: 'HK',
      evaluation_date: date_str,
      object_sub_type: this.state.object_sub_type,
      floor_count: this.state.floor_count,
      cellar: this.state.cellar,
      attic_converted: this.state.attic_converted,
      roof_type: this.state.roof_type,
      construction_year: this.state.construction_year,
      standard_level: this.state.standard_level,
      quality_level: this.state.quality_level,
      living_space: this.state.living_space,
      property_size: this.state.property_size,
      land_value_per_sqm: this.state.land_value_per_sqm
    }

    const newState = {};

    axios.post(API_ROOT + '/report/sachwert', object_data)
    .then((res) => {
      //console.log(res.data);
      newState.eval = res.data;
      this.setState(newState);
    })
    .catch((err) => {
      console.log("Error:", err)
    });

  }
  

  handleSubmit = (e) => {
    e.preventDefault();
    //console.log('submit')

    const object_data = {
      address: this.state.address,
    }

    const newState = {};
    axios.post(API_ROOT + '/report/bodenrichtwert', object_data)
    .then((res) => {
      //console.log(res.data);
      if (res.data.landvalues.length > 0) {
        newState["landvalue_analysis"] = res.data.landvalues;
        newState["selected_landvalue_id"] = 0;
        newState["land_value_per_sqm"] = res.data.landvalues[0].brw;
      }
      else {
        newState["landvalue_analysis"] = res.data.landvalues;
        newState["selected_landvalue_id"] = null;
        newState["land_value_per_sqm"] = null;
      }
      this.setState(newState, () => {
        //this.update_sachwert()
        this.update_sachwert(()=>{}, ()=>{});
      })
      //this.setState({landvalue_analysis: res.data.landvalues}, () => {})
    })
    .catch((err) => {
      console.log("Error:", err)
    });

  }
  //handleSubmit = (e) => {
    //e.preventDefault();
    ////console.log('submit')

    //const object_data = {
      //object_type: 'HK',
      //object_sub_type: 'EFH',
      //floor_count: 1,
      //cellar: true,
      //attic_converted: true,
      //roof_type: 'OTHER',
      //construction_year: 1990,
      //quality_level: 3,
      //living_space: 100
    //}

    //axios.post(API_ROOT + '/report/sachwert', object_data)
    //.then((res) => {
      //console.log(res.data);
    //})
    //.catch((err) => {
      //console.log("Error:", err)
    //});

    ////const newErrors = this.validateInputs(this.state);
    ////if (newErrors.count === 0) {
      ////// Call backend api
      //////axios.post(API_ROOT + '/contact/submit', {
        //////email: this.state.email,
        //////subject: this.props.subject ? this.props.subject : "",
        //////message: this.state.message,
        //////consent: this.state.consent
      //////})
      //////.then((res) => {
        //////// Reset form
        //////this.setState({
          //////submitClicked: false, // reset state because the form is resetted to a fresh state
          //////formValid: true,
          //////submitSucceeded: true,

          //////email: "",
          //////message: "",
          //////consent: false
        //////})
      //////})
      //////.catch((err) => {
        //////console.log("Error:", err)
        //////this.setState({
          //////submitClicked: true,
          //////formValid: true,
          //////submitSucceeded: false,
        //////})
      //////});
    ////}


  //}

  dummyHandleSubmit = (e) => {
  }

  renderObjectSubTypeCard = (value, text, column_type='s6') => {
    return (
        <div className={"col " + column_type}>
          <label>
            <input type="radio" name="object_sub_type" id={"object_sub_type_" + value} value={value} checked={this.state.object_sub_type === value} className="insights-card-input-element" onClick={this.handleChange} onChange={() => {}}/>
            <div className="card insights-card-input">
              <div className="card-content insights-card-content">
                <p max={19} className="insights-card-text">{text}</p>
              </div>
            </div>
          </label>
        </div>
    )
  }
  renderObjectSubTypes = () => {
    return (
      <div>
        <div className="row insights-card-row">
          { this.renderObjectSubTypeCard("EFH", "EFH", "s3") }
          { this.renderObjectSubTypeCard("DHH", "DHH", "s3") }
          { this.renderObjectSubTypeCard("REH", "REH", "s3") }
          { this.renderObjectSubTypeCard("RMH", "RMH", "s3") }
        </div>
        <div className="row insights-card-row">
          { this.renderObjectSubTypeCard("2FH", "2FH", "s3") }
          { this.renderObjectSubTypeCard("2FH-ANGEBAUT", "2FH-ANGEBAUT", "s9") }
        </div>
      </div>
    );
  }


  renderCellarCard = (value, text, column_type='s6') => {
    return (
        <div className={"col " + column_type}>
          <label>
            <input type="radio" name="cellar" id={"cellar_" + value} value={value} checked={this.state.cellar === value} className="insights-card-input-element" onClick={this.handleChange} onChange={() => {}}/>
            <div className="card insights-card-input">
              <div className="card-content insights-card-content">
                <p max={19} className="insights-card-text">{text}</p>
              </div>
            </div>
          </label>
        </div>
    )
  }
  renderCellar = () => {
    return (
      <div>
        <div className="row insights-card-row">
          { this.renderCellarCard(true, "Keller", "s3") }
          { this.renderCellarCard(false, "Kein Keller", "s6") }
        </div>
      </div>
    );
  }


  renderAtticConvertedCard = (value, text, column_type='s6') => {
    return (
        <div className={"col " + column_type}>
          <label>
            <input type="radio" name="attic_converted" id={"attic_converted_" + value} value={value} checked={this.state.attic_converted === value} className="insights-card-input-element" onClick={this.handleChange} onChange={() => {}}/>
            <div className="card insights-card-input">
              <div className="card-content insights-card-content">
                <p max={19} className="insights-card-text">{text}</p>
              </div>
            </div>
          </label>
        </div>
    )
  }
  renderAtticConverted = () => {
    return (
      <div>
        <div className="row insights-card-row">
          { this.renderAtticConvertedCard(true, "Ausgebaut", "s6") }
          { this.renderAtticConvertedCard(false, "Nicht ausgebaut", "s6") }
        </div>
      </div>
    );
  }


  renderRoofTypeCard = (value, text, column_type='s6') => {
    return (
        <div className={"col " + column_type}>
          <label>
            <input type="radio" name="roof_type" id={"roof_type_" + value} value={value} checked={this.state.roof_type === value} className="insights-card-input-element" onClick={this.handleChange} onChange={() => {}}/>
            <div className="card insights-card-input">
              <div className="card-content insights-card-content">
                <p max={19} className="insights-card-text">{text}</p>
              </div>
            </div>
          </label>
        </div>
    )
  }
  renderRoofType = () => {
    return (
      <div>
        <div className="row insights-card-row">
          { this.renderRoofTypeCard("FLACHDACH", "Flachdach", "s6") }
          { this.renderRoofTypeCard("OTHER", "Sonstiges", "s6") }
        </div>
      </div>
    );
  }


  renderQualityLevelCard = (value, text, column_type='s6') => {
    return (
        <div className={"col " + column_type}>
          <label>
            <input type="radio" name="quality_level" id={"quality_level_" + value} value={value} checked={this.state.quality_level === value} className="insights-card-input-element" onClick={this.handleChange} onChange={() => {}}/>
            <div className="card insights-card-input">
              <div className="card-content insights-card-content">
                <p max={19} className="insights-card-text">{text}</p>
              </div>
            </div>
          </label>
        </div>
    )
  }
  renderQualityLevel = () => {
    return (
      <div>
        <div className="row insights-card-row">
          { this.renderQualityLevelCard(1, "1", "s2") }
          { this.renderQualityLevelCard(2, "2", "s2") }
          { this.renderQualityLevelCard(3, "3", "s2") }
          { this.renderQualityLevelCard(4, "4", "s2") }
          { this.renderQualityLevelCard(5, "5", "s2") }
        </div>
      </div>
    );
  }


  renderBodenrichtwert = () => {
    // In case there are no results, display a message
    if (this.state.landvalue_analysis.length === 0) {
      return (
        <div>Für diese Adresse wurde leider kein Ergebnis gefunden.</div>
      );
    }

    // Otherwise, display the results as a table
    return (
      <div>
        <table className="highlight fixed insights-summary-table" style={{"zIndex":-1}}>
          <thead>
            <tr>
              <th>Adresse</th>
              <th>BRW</th>
              <th>Lon, Lat</th>
            </tr>
          </thead>

          <tbody>
            {this.state.landvalue_analysis.map((landvalue, index) => (
              <tr key={'landvalue_analysis_' + index.toString()} onClick={() => {this.onLandValueTableClick(index)}} style={this.state.selected_landvalue_id === index ? { backgroundColor: '#eaf2f8' } : {}}>
                <td>
                  {landvalue.address}
                </td>
                <td>
                  {landvalue.brw} €
                </td>
                <td>
                  {landvalue.lon}, {landvalue.lat}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

//<div className="row">
  //<div className="col s12 m8 offset-m2 l8 offset-l2 xl4 offset-xl4">
    //<div className="col s6">
      //<h5 style={{textAlign:"left"}}>Stockwerke</h5>
      //<form action="">
        //<div className="input-field">
          //<input type="number" step="1" min="1" id="floor_count" value={this.state.floor_count} onChange={this.handleChange} style={{"textAlign":"center", "paddingRight": "0px"}} />
        //</div>
      //</form>
    //</div>
  //</div>
//</div>



  render() {

    return (
      <div>
        <Helmet>
          <title>Sachwert Testseite | Immovestics</title>
          <meta name="description" content="Bewerten Sie Immobilien interaktiv und in wenigen Sekunden für Ihre Immobilien Investments" />
        </Helmet>

        <div className="row">
          <div className="col s12">

            <Spacer height="20px" />

            <section>
              <div className="center">

                <div className="row">
                  <div className="col s10 offset-s1 m5 offset-m1">


                    <table className="highlight fixed insights-summary-table" style={{"zIndex":-1}}>
                      <tbody>
                        <tr>
                          <th colSpan="2"><h5>Sachwert</h5></th>
                        </tr>

                        <tr>
                          <td>
                            <b>Vorläufiger Sachwert</b>
                          </td>
                          <td>
                            <b>{this.state.eval !== null ? Math.round(this.state.eval.sachwert) + " €" : ''}</b>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            Gesamtgebäudewert
                          </td>
                          <td>
                            {this.state.eval !== null ? Math.round(this.state.eval.sachwert_details.discounted_construction_cost) + " €" : ''}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            Gesamtbodenwert
                          </td>
                          <td>
                            { this.state.selected_landvalue_id !== null ? this.state.property_size * parseFloat(this.state.landvalue_analysis[this.state.selected_landvalue_id]["brw"]) : 0 } €
                          </td>
                        </tr>

                        <tr>
                          <td>
                          </td>
                          <td>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            Normherstellungskosten
                          </td>
                          <td>
                            {this.state.eval !== null ? Math.round(this.state.eval.sachwert_details.base_construction_costs) + " €   (" + Math.round(this.state.eval.sachwert_details.nhk) + " €/m²)" : ''}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            Standard
                          </td>
                          <td>
                            {this.state.eval !== null ? this.state.eval.sachwert_details.standard_level : ''}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            Baupreisindex
                          </td>
                          <td>
                            {this.state.eval !== null ? this.state.eval.sachwert_details.baupreis_index : ''}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            Herstellungskosten (Stichtag)
                          </td>
                          <td>
                            {this.state.eval !== null ? Math.round(this.state.eval.sachwert_details.construction_cost_at_cutoff_date) + " €" : ''}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            Herstellungskosten (besondere Bauteile)
                          </td>
                          <td>
                            {this.state.eval !== null ? Math.round(this.state.eval.sachwert_details.construction_cost_of_extra_features) + " €" : ''}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            Herstellungskosten (gesamt)
                          </td>
                          <td>
                            {this.state.eval !== null ? Math.round(this.state.eval.sachwert_details.total_construction_cost) + " €" : ''}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            Alterswertminderung
                          </td>
                          <td>
                            {this.state.eval !== null ? this.state.eval.sachwert_details.age_deprication_factor + " (Faktor)" : ''}
                          </td>
                        </tr>

                        <tr>
                          <td>
                          </td>
                          <td>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <b>NHK Typ</b>
                          </td>
                          <td>
                            {this.state.eval !== null ? this.state.eval.sachwert_details.haustyp : ''}
                          </td>
                        </tr>

                      </tbody>
                    </table>

                  </div>




                  <div className="col s10 offset-s1 m4 offset-m1">
                    <div>

                      <Spacer height="30px" />

                      <div className="row">
                        <div className="col s12">
                          <h6 style={{textAlign:"left"}}>Adresse</h6>
                          <form onSubmit={this.handleSubmit}>
                            <input
                              type="text"
                              id="address_input"
                              value={this.state.address}
                              onChange={this.handleLandValueInputChange}
                              placeholder="Bitte geben Sie hier die Adresse ein"
                            />
                            <button type="submit" className="btn blue darken-1">Senden</button>
                          </form>
                        </div>

                        <Spacer height="30px" />

                      </div>

                      <div className="row">
                        <div className="col s12">
                          {this.state.landvalue_analysis !== null ? this.renderBodenrichtwert() : null}
                        </div>
                      </div>





                      <div className="row">
                        <div className="col s12">
                          <h6 style={{textAlign:"left"}}>Haustyp</h6>
                          { this.renderObjectSubTypes() }
                        </div>
                      </div>

                      <div className="row">
                        <div className="col s6">
                          <h6 style={{textAlign:"left"}}>Grundstück</h6>
                          <PropertySizeSlider
                            handleChange={this.handleChange}
                            handleSubmit={this.dummyHandleSubmit}
                            values={this.state}
                            analysis={this.state.analysis}
                            minifiedLayout={true}
                          />
                        </div>
                        <div className="col s6">
                          <h6 style={{textAlign:"left"}}>Wohnfläche</h6>
                          <LivingSpaceSlider
                            handleChange={this.handleChange}
                            handleSubmit={this.dummyHandleSubmit}
                            values={this.state}
                            analysis={this.state.analysis}
                            minifiedLayout={true}
                          />
                        </div>
                      </div>


                      <div className="row">
                        <div className="col s6">
                          <h6 style={{textAlign:"left"}}>Baujahr</h6>
                          <ConstructionYearSlider
                            handleChange={this.handleChange}
                            handleSubmit={this.dummyHandleSubmit}
                            values={this.state}
                            analysis={this.state.analysis}
                            minifiedLayout={true}
                          />
                        </div>

                        <div className="col s6">
                          <h6 style={{textAlign:"left"}}>Vollgeschosse</h6>
                          <FloorCountSlider
                            handleChange={this.handleChange}
                            handleSubmit={this.dummyHandleSubmit}
                            values={this.state}
                            analysis={this.state.analysis}
                            minifiedLayout={true}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col s12">
                          <h6 style={{textAlign:"left"}}>Keller</h6>
                          { this.renderCellar() }
                        </div>
                      </div>

                      <div className="row">
                        <div className="col s12">
                          <h6 style={{textAlign:"left"}}>Dachtyp und Dachgeschoss</h6>
                          { this.renderRoofType() }
                          { this.renderAtticConverted() }
                        </div>
                      </div>



                      {/*
                      <div className="row">
                        <div className="col s12">
                          <h6 style={{textAlign:"left"}}>Standard</h6>
                          <StandardLevelSlider
                            handleChange={this.handleChange}
                            handleSubmit={this.dummyHandleSubmit}
                            values={this.state}
                            analysis={this.state.analysis}
                            minifiedLayout={true}
                          />
                        </div>
                      </div>
                      */}


                    </div>

                  </div>
                


                </div>
              </div>

            </section>


            <section>
            </section>

          </div>
        </div>
      </div>
    )
  }

};

export default SachwertPage;
