import React, { useEffect, useRef, useState } from 'react';
import M from 'materialize-css';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import wNumb from 'wnumb';
import FeatureImpact from './FeatureImpact';

const NRoomsSlider = (props) => {
  const sliderRef = useRef(null);
  const [nRooms, setNRooms] = useState(props.values.nRooms ? props.values.nRooms : "");

  useEffect(() => {
    const nRoomsSlider = sliderRef.current;
    if (nRoomsSlider) {
      noUiSlider.create(nRoomsSlider, {
        start: [props.values.nRooms ? props.values.nRooms : 0],
        connect: [true, false],
        tooltips: [true],
        range: {
          min: 0,
          max: 11,
        },
        step: 0.5,
        format: wNumb({
          decimals: 1,
          edit: (value) => {
            if (value < 1) return '-';
            if (value > 10) return '> 10';
            return String(value);
          },
        }),
      });

      nRoomsSlider.noUiSlider.on('change', (value) => {
        let changedValue = value[0];
        if (changedValue === '-' || changedValue === '0') changedValue = ''; // reset state
        if (changedValue === '> 10') changedValue = '11';

        setNRooms(String(changedValue));
        props.handleChange({
          target: {
            id: 'nRooms',
            value: changedValue,
          },
        });
        M.updateTextFields();
      });

      M.updateTextFields();

      // Cleanup on unmount
      return () => {
        if (nRoomsSlider.noUiSlider) {
          nRoomsSlider.noUiSlider.destroy();
        }
      };
    }
  }, [props]);

  useEffect(() => {
    if (sliderRef.current && sliderRef.current.noUiSlider) {
      const newValue = nRooms === "" ? 0 : nRooms;
      sliderRef.current.noUiSlider.set(newValue);
    }
  }, [nRooms]);

  const handleNRoomsChange = (e) => {
    let textValue = e.target.value;
    textValue = textValue.replace(/[^\d.]/g, '');
    const floatingPointPos = textValue.indexOf('.');
    if (floatingPointPos !== -1) {
      const slicePos = Math.min(textValue.length, floatingPointPos + 2); // the slicing position is at most one digit after the floating point dot
      textValue = textValue.slice(0, slicePos);
    }

    let returnValue = textValue;
    if (returnValue === '') {
      // Do nothing
    } else if (returnValue === '> 10') {
      returnValue = 11;
    } else {
      try {
        returnValue = parseFloat(returnValue);
        returnValue = parseFloat(returnValue.toFixed(1));
        if (isNaN(returnValue)) returnValue = '';
        if (returnValue < 1) returnValue = 1;
        if (returnValue > 10) returnValue = 11;
      } catch {
        returnValue = '';
      }
    }

    setNRooms(String(returnValue));
    if (sliderRef.current) {
      sliderRef.current.noUiSlider.set(returnValue === "" ? 0 : returnValue);
    }
    e.target.value = returnValue;
    props.handleChange(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(e);
  };

  const getTextBoxValue = (value) => {
    if (value > 10) return '> 10';
    return String(value);
  };

  const fullSliderLayout = () => (
    <div>
      <div ref={sliderRef} className="insights-slider"></div>
      <form action="" onSubmit={handleSubmit}>
        <div className="input-field">
          <input
            type="text"
            id="nRooms"
            value={getTextBoxValue(nRooms)}
            onChange={handleNRoomsChange}
            style={{ textAlign: 'center', paddingRight: '0px' }}
            className="no-arrows-input"
          />
        </div>
      </form>
      <FeatureImpact impact={props.analysis.purchase_analysis.n_rooms.impact} />
    </div>
  );

  const minifiedSliderLayout = () => (
    <div>
      <div ref={sliderRef} className="insights-slider"></div>
      <form action="" onSubmit={handleSubmit}>
        <input
          type="text"
          id="nRooms"
          value={getTextBoxValue(nRooms)}
          onChange={handleNRoomsChange}
          style={{ textAlign: 'center', paddingRight: '0px' }}
          className="no-arrows-input"
        />
      </form>
    </div>
  );

  return props.minifiedLayout ? minifiedSliderLayout() : fullSliderLayout();
};

export default NRoomsSlider;
