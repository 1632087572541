import React, { Component } from 'react';
import M from 'materialize-css'

import { Helmet } from "react-helmet";
import { API_ROOT, API_BACKUP_ROOT } from '../../config/config';

//import TestGraph from '../../components/Mietspiegel/UiElements/TestGraph';
import CategoryGraph from '../../components/Mietspiegel/UiElements/CategoryGraph';
import HorizontalBarChart from '../../components/Mietspiegel/UiElements/HorizontalBarChart';
import TimeSeriesGraph from '../../components/Mietspiegel/UiElements/TimeSeriesGraph';

import axios from 'axios';

import Spacer from '../../components/Spacer';


const axiosInstance = axios.create({
  headers: {
    authorization: 'a01d0f1d-fce7-49b7-9363-ecf33fd3e8a0'
  }
});


class MietspiegelPage extends Component {

  state = {

    city_dropdown_options: {},
    district_dropdown_options: {},
    mietspiegel: {},

    city_id_data: {},
    district_id_data: {},

    data : [
      { name: "Category 1", value: 20 },
      { name: "Category 2", value: 50 },
      { name: "Category 3", value: -35 },
      { name: "Category 4", value: 15 },
      { name: "Category 5", value: -10 },
      // Add more data as needed
    ],

    price_attr: 'median', // Default to 'median'
    price_attr_options: {
      'median': 'Median',
      'mean': 'Mittelwert'
    },

    granularity: 'monthly', // Default to 'monthly'
    granularity_options: {
      'monthly': 'Monatlich',
      'quarterly': 'Vierteljährlich'
    },

  }


  componentDidMount() {
    // City dropdown
    var elem = document.querySelector('#autocomplete-city-list');
    const options = {
      data: this.state.city_dropdown_options,
      minLength: 0,
      sortFunction: this.sortFunction,
      onAutocomplete: this.handle_cities_dropdown_select
    };
    M.Autocomplete.init(elem, options);

    // District dropdown
    var elem_district = document.querySelector('#autocomplete-district-list');
    const options_district = {
      data: this.state.district_dropdown_options,
      minLength: 0,
      sortFunction: this.sortFunction,
      onAutocomplete: this.handle_districts_dropdown_select,
    };
    M.Autocomplete.init(elem_district, options_district);

    // Initialize price_attr dropdown
    var elem_price_attr = document.querySelector('#price-attr-dropdown');
    const options_price_attr = {
      data: this.state.price_attr_options,
      onSelect: this.handlePriceAttrSelect
    };
    M.FormSelect.init(elem_price_attr, options_price_attr);

    // Initialize granularity dropdown
    var elem_granularity = document.querySelector('#granularity-dropdown');
    const options_granularity = {
      data: this.state.granularity_options,
      onSelect: this.handleGranularitySelect
    };
    M.FormSelect.init(elem_granularity, options_granularity);

    this.update_cities_dropdown();

    // Dummy quick select
    this.dummy_quick_select();
  }

  sortFunction = (a, b, inputString) => {
    // Ensure that this entry is always on top
    // Note: the strings seem to be converted to lower case interally.
    // Note: our string can appear either for a or b
    if (a === 'alle stadtteile') {
      return -1;
    }
    if (b === 'alle stadtteile') {
      return 1;
    }

    if (inputString) {
      if (a.startsWith(inputString) && !b.startsWith(inputString)) {
        return -1;
      }
      if (!a.startsWith(inputString) && b.startsWith(inputString)) {
        return 1;
      }
    }

    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }



  handleChange = (e) => {
    //const newState = {};
  }
  

  update_cities_dropdown = () => {

    const newState = {};

    axiosInstance.get(API_BACKUP_ROOT + '/mietspiegel/cities')
    .then((res) => {
      //console.log("Cities data received:", res.data.length);

      // Build the city list for the dropdown
      const city_dropdown_list = res.data.reduce((acc, item) => {
        acc[item.city] = null;
        return acc;
      }, {});
      newState.city_dropdown_options = city_dropdown_list;
      // Then build the city list with the id lookup
      const city_name_id_list = res.data.reduce((acc, item) => {
        acc[item.city] = item.immoportal_id;
        return acc;
      }, {});
      newState.city_id_data = city_name_id_list;

      this.setState(newState, () => {
        var elem = document.querySelector('#autocomplete-city-list');
        var instance = M.Autocomplete.getInstance(elem);
        instance.updateData(this.state.city_dropdown_options);
      })

    })
    .catch((err) => {
      console.log("Error:", err)
    });
  }

  handleGranularitySelect = (selectedOption) => {
    console.log("Selected granularity:", selectedOption);
    this.setState({ granularity: selectedOption.value });
  }

  handle_cities_dropdown_select = (val) => {
    // Get name and id of the city
    //const city_name = val;
    const city_id = this.state.city_id_data[val];
    //console.log('City selected', city_name, city_id);

    // Load districts and populate dropdown
    const newState = {};
    axiosInstance.get(API_BACKUP_ROOT + '/mietspiegel/cities/' + city_id + '/districts')
    .then((res) => {
      //console.log(res.data);

      // Build the district list for the dropdown
      const district_dropdown_list = res.data.reduce((acc, item) => {
        acc[item.district] = null;
        return acc;
      }, {});
      const full_district_dropdown_list = {
        'ALLE STADTTEILE': null
      }
      for (const key in district_dropdown_list) {
        full_district_dropdown_list[key] = district_dropdown_list[key]
      }
      //console.log(full_district_dropdown_list);
      newState.district_dropdown_options = full_district_dropdown_list;
      // Then build the district list with the id lookup
      const district_name_id_list = res.data.reduce((acc, item) => {
        acc[item.district] = item.immoportal_id;
        return acc;
      }, {});
      const full_district_dict = {
        'ALLE STADTTEILE': city_id
      }
      for (const key in district_name_id_list) {
        full_district_dict[key] = district_name_id_list[key]
      }
      newState.district_id_data = full_district_dict;

      this.setState(newState, () => {
        var elem = document.querySelector('#autocomplete-district-list');
        var instance = M.Autocomplete.getInstance(elem);
        instance.updateData(this.state.district_dropdown_options);
        this.handle_districts_dropdown_select("ALLE STADTTEILE");

        // TODO automatically set district if only one option is available
        if (Object.keys(this.state.district_dropdown_options).length === 1) {
        }
      })

    })
    .catch((err) => {
      console.log("Error:", err)
    });
  }


  handle_districts_dropdown_select = (val) => {
    // Get name and id of the city
    //const district_name = val;
    const city_id = this.state.district_id_data[val];
    //console.log('City and district selected', district_name, city_id);

    // Load the detailed analysis
    const newState = {};
    axiosInstance.get(API_BACKUP_ROOT + '/mietspiegel/' + city_id)
    .then((res) => {
      //console.log(res.data);

      newState.mietspiegel = res.data;
      this.setState(newState, () => {
      })

    })
    .catch((err) => {
      console.log("Error:", err)
    });

  }


  dummy_quick_select = () => {
    const city_id = 0;

    // Load the detailed analysis
    const newState = {};
    axiosInstance.get(API_BACKUP_ROOT + '/mietspiegel/' + city_id)
    .then((res) => {
      //console.log(res.data);

      newState.mietspiegel = res.data;
      this.setState(newState, () => {
      })

    })
    .catch((err) => {
      console.log("Error:", err)
    });

  }
  

  handleSubmit = (e) => {
    e.preventDefault();
    //console.log('submit')
  }


  displayIntroText = () => {
    const immoportal_id = "immoportal_id" in this.state.mietspiegel ? this.state.mietspiegel.immoportal_id : null;
    const city_name = "city" in this.state.mietspiegel ? this.state.mietspiegel.city : null;
    const district_name = "district" in this.state.mietspiegel ? this.state.mietspiegel.district : null;

    let count_2022 = null;
    let count_2023 = null;
    let count_2024 = null;
    let count_hm_2022 = null;
    let count_hm_2023 = null;
    let count_hm_2024 = null;
    if ("data" in this.state.mietspiegel) {
      count_2022 = "count" in this.state.mietspiegel.data.WM.general["2022"].stats ? this.state.mietspiegel.data.WM.general["2022"].stats.count : null;
      count_2023 = "count" in this.state.mietspiegel.data.WM.general["2023"].stats ? this.state.mietspiegel.data.WM.general["2023"].stats.count : null;
      count_2024 = "count" in this.state.mietspiegel.data.WM.general["2024"].stats ? this.state.mietspiegel.data.WM.general["2024"].stats.count : null;

      count_hm_2022 = "count" in this.state.mietspiegel.data.HM.general["2022"].stats ? this.state.mietspiegel.data.HM.general["2022"].stats.count : null;
      count_hm_2023 = "count" in this.state.mietspiegel.data.HM.general["2023"].stats ? this.state.mietspiegel.data.HM.general["2023"].stats.count : null;
      count_hm_2024 = "count" in this.state.mietspiegel.data.HM.general["2024"].stats ? this.state.mietspiegel.data.HM.general["2024"].stats.count : null;
    }

    if (immoportal_id === null) {
      return(<div></div>);
    }

    const rent_2022 = (Math.round(this.state.mietspiegel.data.WM.general["2022"].stats[this.state.price_attr] * 100) / 100).toFixed(2);
    const rent_2023 = (Math.round(this.state.mietspiegel.data.WM.general["2023"].stats[this.state.price_attr] * 100) / 100).toFixed(2);
    const rent_2024 = (Math.round(this.state.mietspiegel.data.WM.general["2024"].stats[this.state.price_attr] * 100) / 100).toFixed(2);

    const rent_hm_2022 = (Math.round(this.state.mietspiegel.data.HM.general["2022"].stats[this.state.price_attr] * 100) / 100).toFixed(2);
    const rent_hm_2023 = (Math.round(this.state.mietspiegel.data.HM.general["2023"].stats[this.state.price_attr] * 100) / 100).toFixed(2);
    const rent_hm_2024 = (Math.round(this.state.mietspiegel.data.HM.general["2024"].stats[this.state.price_attr] * 100) / 100).toFixed(2);

    return (
      <div className="row">
        <div className="col s12 m4 offset-m2">
          <strong>Mietspiegel für {city_name} {district_name !== null ? " und Stadtteil " + district_name : ""} (id: {immoportal_id})</strong>
          <p>
            <strong>2024:</strong> {rent_2024} €/m² ({count_2024} Objekte)
          </p>
          <p>
            <strong>2023:</strong> {rent_2023} €/m² ({count_2023} Objekte)
          </p>
          <p>
            <strong>2022:</strong> {rent_2022} €/m² ({count_2022} Objekte)
          </p>
        </div>
        <div className="col s12 m4">
          <strong>Für Häuser:</strong>
          <p>
            <strong>2024:</strong> {rent_hm_2024} €/m² ({count_hm_2024} Objekte)
          </p>
          <p>
            <strong>2023:</strong> {rent_hm_2023} €/m² ({count_hm_2023} Objekte)
          </p>
          <p>
            <strong>2022:</strong> {rent_hm_2022} €/m² ({count_hm_2022} Objekte)
          </p>
        </div>
      </div>
    )
  }


  resetCityDropwdown = () => {
    var elem = document.querySelector('#autocomplete-city-list');
    elem.value = "";
    var instance = M.Autocomplete.getInstance(elem);
    //instance.close()
    instance.open()
  }

  resetDistrictDropwdown = () => {
    var elem = document.querySelector('#autocomplete-district-list');
    elem.value = "";
    var instance = M.Autocomplete.getInstance(elem);
    //instance.close()
    instance.open()
  }

  handlePriceAttrSelect = (selectedOption) => {
    console.log("Selected option:", selectedOption);
    this.setState({ price_attr: selectedOption.value });
  }


  render() {

    return (
      <div>
        <Helmet>
          <title>Mietspiegel Testseite | Immovestics</title>
          <meta name="description" content="Bewerten Sie Immobilien interaktiv und in wenigen Sekunden für Ihre Immobilien Investments" />
        </Helmet>

        <div className="row">
          <div className="col s12">

            <Spacer height="60px" />

            <section>
              <div className="center">

                {/* Input boxes with dropdown and autocomplete */}
                <div className="row">
                  <div className="col s5 offset-s1">
                    <div className="input-field col s12">
                      <i className="material-icons prefix">textsms</i>
                      <input type="text" id="autocomplete-city-list" className="autocomplete" onClick={this.resetCityDropwdown} />
                      <label htmlFor="autocomplete-input" style={{zIndex: -1}}>Stadt</label>
                    </div>
                  </div>
                  <div className="col s5">
                    <div className="input-field col s12">
                      <i className="material-icons prefix">textsms</i>
                      <input type="text" id="autocomplete-district-list" className="autocomplete" onClick={this.resetDistrictDropwdown} />
                      <label htmlFor="autocomplete-input" style={{zIndex: -1}}>Stadtteil</label>
                    </div>
                  </div>
                </div>

                {/* New price attribute dropdown */}
                <div className="row">
                  <div className="col s5 offset-s1 m3 offset-m3">
                    <div className="input-field col s12">
                      <select id="price-attr-dropdown" value={this.state.price_attr} onChange={(e) => this.handlePriceAttrSelect({ value: e.target.value })}>
                        {Object.entries(this.state.price_attr_options).map(([value, label]) => (
                          <option key={value} value={value}>{label}</option>
                        ))}
                      </select>
                      <label>Preisattribut</label>
                    </div>
                  </div>
                  <div className="col s5 offset-s1 m3">
                    <div className="input-field col s12">
                      <select id="granularity-dropdown" value={this.state.granularity} onChange={(e) => this.handleGranularitySelect({ value: e.target.value })}>
                        {Object.entries(this.state.granularity_options).map(([value, label]) => (
                          <option key={value} value={value}>{label}</option>
                        ))}
                      </select>
                      <label>Granularität</label>
                    </div>
                  </div>
                </div>

                {/* Input boxes with dropdown and autocomplete */}
                <div className="row" style={{textAlign:"left"}}>
                  <div className="col s10 offset-s1">
                    {this.displayIntroText()}
                  </div>
                </div>

                <div className="row">
                  <div className="col s10 offset-s1">

                    {/* Time series */}
                    <div className="row">
                      <div className="col s10 offset-s1 l4 offset-l2">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <TimeSeriesGraph title={'Miete für Wohnungen'} x_axis={'Zeitraum'} tooltip_unit={'€/m²'} rotate_x_axis={-25} data_all_sets={this.state.mietspiegel.data.WM} feature={"general"} category={"stats"} granularity={'month'} price_attr={this.state.price_attr} isMonthly={this.state.granularity === 'monthly'} /> :
                            null
                        }
                      </div>
                      <div className="col s10 offset-s1 l4">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <TimeSeriesGraph title={'Miete für Häuser'} x_axis={'Zeitraum'} tooltip_unit={'€/m²'} rotate_x_axis={-25} data_all_sets={this.state.mietspiegel.data.HM} feature={"general"} category={"stats"} granularity={'month'} price_attr={this.state.price_attr} isMonthly={this.state.granularity === 'monthly'} /> :
                            null
                        }
                      </div>
                    </div>

                    {/* Two graphs: area score and effects */}
                    <div className="row">
                      <div className="col s10 offset-s1 l4 offset-l2">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <CategoryGraph title={'Miete nach Wohnlage'} x_axis={'Wohnlage'} tooltip_unit={'€/m²'} rotate_x_axis={-25} set0={this.state.mietspiegel.data.WM.rent_vs_area_score["2022"]} set1={this.state.mietspiegel.data.WM.rent_vs_area_score["2023"]} set2={this.state.mietspiegel.data.WM.rent_vs_area_score["2024"]} price_attr={this.state.price_attr} /> :
                            null
                        }
                      </div>
                      <div className="col s10 offset-s1 l4">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <HorizontalBarChart title={'Mietpreiseffekte'} x_axis={'Effekt auf Miete in €/m²'} tooltip_unit={'€/m²'} rotate_x_axis={-25} set0={null} set1={this.state.mietspiegel.data.WM} price_attr={this.state.price_attr} /> :
                            null
                        }
                      </div>
                    </div>

                    <div className="row">
                      <div className="col s10 offset-s1 l4">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <CategoryGraph title={'Miete nach Baujahr'} x_axis={'Baujahr'} tooltip_unit={'€/m²'} rotate_x_axis={-25} set0={this.state.mietspiegel.data.WM.rent_vs_construction_year["2022"]} set1={this.state.mietspiegel.data.WM.rent_vs_construction_year["2023"]} set2={this.state.mietspiegel.data.WM.rent_vs_construction_year["2024"]} price_attr={this.state.price_attr} /> :
                            null
                        }
                      </div>
                      <div className="col s10 offset-s1 l4">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <CategoryGraph title={'Miete nach Wohnfläche'} x_axis={'Wohnfläche [m²]'} tooltip_unit={'€/m²'} rotate_x_axis={-25} set0={this.state.mietspiegel.data.WM.rent_vs_living_space["2022"]} set1={this.state.mietspiegel.data.WM.rent_vs_living_space["2023"]} set2={this.state.mietspiegel.data.WM.rent_vs_living_space["2024"]} price_attr={this.state.price_attr} /> :
                            null
                        }
                      </div>
                      <div className="col s10 offset-s1 l4">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <CategoryGraph title={'Miete nach Modernisierung'} x_axis={'Jahr der Modernisierung'} tooltip_unit={'€/m²'} rotate_x_axis={-25} set0={this.state.mietspiegel.data.WM.rent_vs_modernisation_year["2022"]} set1={this.state.mietspiegel.data.WM.rent_vs_modernisation_year["2023"]} set2={this.state.mietspiegel.data.WM.rent_vs_modernisation_year["2024"]} price_attr={this.state.price_attr} /> :
                            null
                        }
                      </div>
                    </div>

                    <div className="row">
                      <div className="col s10 offset-s1 l4">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <CategoryGraph title={'Miete nach Anzahl Zimmer'} x_axis={'Anzahl Zimmer'} tooltip_unit={'€/m²'} rotate_x_axis={-25} set0={this.state.mietspiegel.data.WM.rent_vs_n_rooms["2022"]} set1={this.state.mietspiegel.data.WM.rent_vs_n_rooms["2023"]} set2={this.state.mietspiegel.data.WM.rent_vs_n_rooms["2024"]} price_attr={this.state.price_attr} /> :
                            null
                        }
                      </div>
                      <div className="col s10 offset-s1 l4">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <CategoryGraph title={'Größe nach Anzahl Zimmer'} x_axis={'Anzahl Zimmer'} tooltip_unit={'m²'} rotate_x_axis={-25} set0={this.state.mietspiegel.data.WM.sqm_vs_n_rooms["2022"]} set1={this.state.mietspiegel.data.WM.sqm_vs_n_rooms["2023"]} set2={this.state.mietspiegel.data.WM.sqm_vs_n_rooms["2024"]} price_attr={this.state.price_attr} /> :
                            null
                        }
                      </div>
                      <div className="col s10 offset-s1 l4">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <CategoryGraph title={'Miete nach Anzahl Zimmer (absolut)'} x_axis={'Anzahl Zimmer'} tooltip_unit={'€'} rotate_x_axis={-25} set0={this.state.mietspiegel.data.WM.rent_vs_n_rooms_absolute["2022"]} set1={this.state.mietspiegel.data.WM.rent_vs_n_rooms_absolute["2023"]} set2={this.state.mietspiegel.data.WM.rent_vs_n_rooms_absolute["2024"]} price_attr={this.state.price_attr} /> :
                            null
                        }
                      </div>
                    </div>

                    <div className="row">
                      <div className="col s10 offset-s1 l4">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <CategoryGraph title={'Miete nach Heizungsart'} x_axis={'Heizungsart'} tooltip_unit={'€/m²'} rotate_x_axis={-25} set0={this.state.mietspiegel.data.WM.rent_vs_heating_type["2022"]} set1={this.state.mietspiegel.data.WM.rent_vs_heating_type["2023"]} set2={this.state.mietspiegel.data.WM.rent_vs_heating_type["2024"]} price_attr={this.state.price_attr} /> :
                            null
                        }
                      </div>
                      <div className="col s10 offset-s1 l4">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <CategoryGraph title={'Miete nach Energieträger'} x_axis={'Energieträger'} tooltip_unit={'€/m²'} rotate_x_axis={-25} set0={this.state.mietspiegel.data.WM.rent_vs_energy_carrier["2022"]} set1={this.state.mietspiegel.data.WM.rent_vs_energy_carrier["2023"]} set2={this.state.mietspiegel.data.WM.rent_vs_energy_carrier["2024"]} price_attr={this.state.price_attr} /> :
                            null
                        }
                      </div>
                      <div className="col s10 offset-s1 l4">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <CategoryGraph title={'Miete nach Energieeffizienzklasse'} x_axis={'Energieeffizienzklasse'} tooltip_unit={'€/m²'} rotate_x_axis={-25} set0={this.state.mietspiegel.data.WM.rent_vs_energy_efficiency_class["2022"]} set1={this.state.mietspiegel.data.WM.rent_vs_energy_efficiency_class["2023"]} set2={this.state.mietspiegel.data.WM.rent_vs_energy_efficiency_class["2024"]} price_attr={this.state.price_attr} /> :
                            null
                        }
                      </div>
                    </div>

                    <div className="row">
                      {/*
                      <div className="col s10 offset-s1 l4">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <CategoryGraph title={'Miete nach Parkplatz'} x_axis={'Parkplatz'} tooltip_unit={'€/m²'} rotate_x_axis={-25} set0={this.state.mietspiegel.data.WM.rent_vs_parking_type["2022"]} set1={this.state.mietspiegel.data.WM.rent_vs_parking_type["2023"]} set2={this.state.mietspiegel.data.WM.rent_vs_parking_type["2024"]} price_attr={this.state.price_attr} /> :
                            null
                        }
                      </div>
                      */}
                      <div className="col s10 offset-s1 l4">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <CategoryGraph title={'Miete nach Parkplatz (absolut)'} x_axis={'Parkplatz'} tooltip_unit={'€'} rotate_x_axis={-25} set0={this.state.mietspiegel.data.WM.rent_vs_parking_type_absolute["2022"]} set1={this.state.mietspiegel.data.WM.rent_vs_parking_type_absolute["2023"]} set2={this.state.mietspiegel.data.WM.rent_vs_parking_type_absolute["2024"]} price_attr={this.state.price_attr} /> :
                            null
                        }
                      </div>
                      <div className="col s10 offset-s1 l4">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <CategoryGraph title={'Miete nach Etage'} x_axis={'Etage'} tooltip_unit={'€/m²'} rotate_x_axis={-25} set0={this.state.mietspiegel.data.WM.rent_vs_floor["2022"]} set1={this.state.mietspiegel.data.WM.rent_vs_floor["2023"]} set2={this.state.mietspiegel.data.WM.rent_vs_floor["2024"]} price_attr={this.state.price_attr} /> :
                            null
                        }
                      </div>
                      <div className="col s10 offset-s1 l4">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <CategoryGraph title={'Miete nach Wohnungstyp'} x_axis={'Wohnungstyp'} tooltip_unit={'€/m²'} rotate_x_axis={-25} set0={this.state.mietspiegel.data.WM.rent_vs_object_sub_type["2022"]} set1={this.state.mietspiegel.data.WM.rent_vs_object_sub_type["2023"]} set2={this.state.mietspiegel.data.WM.rent_vs_object_sub_type["2024"]} price_attr={this.state.price_attr} /> :
                            null
                        }
                      </div>
                    </div>

                    {/* Häuser */}

                    <div className="row">
                      <Spacer height="60px" />
                      <div className="col s10 offset-s1">
                        <h4>Häuser</h4>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col s10 offset-s1 l4">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <CategoryGraph title={'Miete nach Wohnlage'} x_axis={'Wohnlage'} tooltip_unit={'€/m²'} rotate_x_axis={-25} set0={this.state.mietspiegel.data.HM.rent_vs_area_score["2022"]} set1={this.state.mietspiegel.data.HM.rent_vs_area_score["2023"]} set2={this.state.mietspiegel.data.HM.rent_vs_area_score["2024"]} price_attr={this.state.price_attr} /> :
                            null
                        }
                      </div>
                      <div className="col s10 offset-s1 l4">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <CategoryGraph title={'Miete nach Baujahr'} x_axis={'Baujahr'} tooltip_unit={'€/m²'} rotate_x_axis={-25} set0={this.state.mietspiegel.data.HM.rent_vs_construction_year["2022"]} set1={this.state.mietspiegel.data.HM.rent_vs_construction_year["2023"]} set2={this.state.mietspiegel.data.HM.rent_vs_construction_year["2024"]} price_attr={this.state.price_attr} /> :
                            null
                        }
                      </div>
                      <div className="col s10 offset-s1 l4">
                        {
                          this.state.mietspiegel !== null && "data" in this.state.mietspiegel ?
                            <CategoryGraph title={'Miete nach Wohnfläche'} x_axis={'Wohnfläche [m²]'} tooltip_unit={'€/m²'} rotate_x_axis={-25} set0={this.state.mietspiegel.data.HM.rent_vs_living_space["2022"]} set1={this.state.mietspiegel.data.HM.rent_vs_living_space["2023"]} set2={this.state.mietspiegel.data.HM.rent_vs_living_space["2024"]} price_attr={this.state.price_attr} /> :
                            null
                        }
                      </div>
                    </div>


                  </div>
                </div>

              </div>

            </section>


          </div>
        </div>
      </div>
    )
  }

};

export default MietspiegelPage;
