import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_ROOT, AXIOS_CONFIG } from '../../config/config';
import withUser from './withUser';

const LoginVerification = ({ user, loginUser }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect away from login page if user is already logged in
    if (user) {
      navigate('/');
    } else {
      // Check if the user is already logged in
      checkLogin();
    }
  }, [user, navigate]);

  const checkLogin = async () => {
    try {
      const response = await axios.get(`${API_ROOT}/user/login/success`, { withCredentials: true }, AXIOS_CONFIG);
      if (response.status === 200) {
        loginUser(response.data.user);
        navigate('/');
      } else {
        // Login not successful. Return to login page.
        navigate('/login');
      }
    } catch (error) {
      // Handle error if needed, and return to login page
      navigate('/login');
    }
  };

  return <div />;
};

export default withUser(LoginVerification);
