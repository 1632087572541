import Contact from '../components/Contact/Contact';
import { Helmet } from "react-helmet";

import Spacer from '../components/Spacer';

function ContactPage(props) {
  return (
    <div>
      <Helmet>
        <title>Kontakt | Immovestics</title>
        <meta name="description" content="Wir freuen uns auf Ihre Nachricht und helfen gerne weiter." />
      </Helmet>

      <div className="container">
        <Spacer height="50px" />
      </div>

      <div className="container">
        <div className="row">
          <div className="col s12 l6 offset-l3 insights-container white" style={{"padding":"40px"}}>
            <div className="section">
              <h4>Kontakt</h4>
              <p className="flex-text">Wir freuen uns über Ihre Nachricht!</p>
              <Contact subject="message via contact form" />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <Spacer height="50px" />
      </div>
    </div>
  )
}

export default ContactPage;
