import React, { Component } from 'react'

class InsightsEvalFormHeader extends Component {

  render() {
    return (
      <div>
        <div className="row">
          <div className="col s12">
            <h5 className="grey-text center">Immovestics Insights <sup>beta</sup></h5>
          </div>
        </div>

        <div className="row">
          <div className="col s12">
            <div className="divider"></div>
          </div>
        </div>
      </div>
    )
  }
}

export default InsightsEvalFormHeader

